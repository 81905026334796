import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const DataTable = ({ type, data, componentRef }) => {
  const [budgets, setBudgets] = React.useState([]);
  const [sortByName, setSortByName] = React.useState("asc");
  const [sortByAmount, setSortByAmount] = React.useState("asc");

  React.useEffect(() => {
    if (!data) return;
    setBudgets(data?.data?.map((item) => item?.revenue_by_economic));
  }, [data]);

  return (
    <React.Fragment>
      <TableContainer component={Paper} ref={componentRef} id="data-table">
        <Table aria-label="simple table" className="border">
          <TableHead className="bg-light">
            <TableRow>
              <TableCell>
                State{" "}
                <IconButton
                  size="small"
                  onClick={() =>
                    sortByStates(sortByName === "asc" ? "desc" : "asc")
                  }
                >
                  {sortByName === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </TableCell>
              <TableCell>
                Amount(NGN)
                <IconButton
                  size="small"
                  onClick={() =>
                    sortByAmounts(sortByAmount === "asc" ? "desc" : "asc")
                  }
                >
                  {sortByAmount === "asc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgets?.map((budget, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell>{capitalizeLetter(budget?.state)}</TableCell>
                <TableCell>
                  {numberWithCommas(budget?.total_revenue?.value)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px 0",
        }}
      >
        <span style={{ color: "#888", fontWeight: 300, fontSize: 12 }}>
          <span style={{ fontStyle: "italic" }}>Source:</span> NGF Public
          Finance Database
        </span>
      </div>
    </React.Fragment>
  );

  function sortByStates(type) {
    if (!type) return;
    setSortByName(type);
    const data = budgets.sort((a, b) => {
      if (type === "asc") {
        return a?.state > b?.state ? 1 : -1;
      }
      return b?.state > a?.state ? 1 : -1;
    });
    setBudgets(data);
  }

  function sortByAmounts(type) {
    if (!type) return;
    setSortByAmount(type);
    const data = budgets.sort((a, b) => {
      if (type === "asc") {
        return a?.total_revenue?.value > b?.total_revenue?.value ? 1 : -1;
      }
      return b?.total_revenue?.value > a?.total_revenue?.value ? 1 : -1;
    });
    setBudgets(data);
  }
};

function numberWithCommas(x) {
  return Number(parseFloat(x).toFixed(2)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
}

function capitalizeLetter(str) {
  str = str.split("_");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] = str[i][0].toUpperCase() + str[i].substr(1)?.toLowerCase();
  }

  return str.join(" ");
}
export default DataTable;
