import MuiAlert from "@mui/material/Alert";
import useFetch from "../compare/useCustomHook/useFetch";
import styles from "../compare/styles/styles.module.css";
import Layout from "../siteLayout/Layout";
import Rank from "../compare/components/Rank";
import { Button, ClickAwayListener, Snackbar } from "@mui/material";
import ReactToPrint from "react-to-print";
import PiRankTable from "../compare/components/PI/piRankTable";
import RankTable from "../compare/components/RankTable";
import React, { useRef, useState } from "react";
import ExportIcon from "../../Lib/assets/images/upload.png";
import PrintIcon from "../../Lib/assets/images/print.png";
import EmptyImg from "../../Lib/assets/images/empty.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ExportFileMini } from "../utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const states = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

const RankStates = () => {
  const {
    budgets,
    setBudgets,
    fetchPiBudgets,
    fetchData,
    setRankData,
    selectedCategories,
    setSelectedCategories,
    selectBudgetType,
    setSelectBudgetType,
    selectedYear,
    setSelectedYear,
    selectedState,
    setSelectedStates,
    isLoading,
    sortBy,
    setSortBy,
    fetchBudgets,
    generalData,
    setGeneralData,
    message,
    severity,
    snakOpen,
    setSnakOpen,
    fetchBudgetsIndicators,
    popItems,
    PI,
    formatData,
    rankBudgets,
    downloadCsv,
    downloadExcel,
    downloadPiCsv,
    downloadPiExcel,
    returnRankPiSingleData,
  } = useFetch();
  const componentRefRank = useRef();
  // Export dropdown
  const [exportOpen, setExportOpen] = useState(false);
  const [exportOpenMobile, setExportMobileOpen] = useState(false);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [searchCategories, setSearchCategories] = useState("");
  const [rankCopen, setRankCopen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [searchState, setSearchState] = useState("");
  const [selectedStateType, setSelectedStatesType] = useState([]);
  const [searchStateType, setSearchStateType] = useState("");

  const filteredStatesType = states?.filter((item) =>
    item?.toLowerCase().includes(searchStateType?.toLowerCase())
  );
  const filteredStates = states?.filter((item) =>
    item?.toLowerCase().includes(searchState?.toLowerCase())
  );
  // Export handlers
  // Rank Cts
  const handleRankC = () => {
    setRankCopen((prev) => !prev);
  };

  const handleRankCClickAway = () => {
    setRankCopen(false);
  };

  const handleExportClick = () => {
    setExportOpen((prev) => !prev);
  };

  const handleExportClickAway = () => {
    setExportOpen(false);
  };
  const handleTypeClick = () => {
    setTypeOpen((prev) => !prev);
  };

  const handleTypeClickAway = () => {
    setTypeOpen(false);
  };

  const handleExportClickMobile = () => {
    setExportMobileOpen((prev) => !prev);
  };
  const handleMobileExportClickAway = () => {
    setExportMobileOpen(false);
  };

  const handleSnakClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnakOpen(false);
  };

  const vertical = "top";
  const horizontal = "right";
  return (
    <Layout>
      <main style={{ minHeight: "80vh" }}>
        <div className={styles.container}>
          <div className={styles.exportPrintWrapper}>
            <h2>Rank States</h2>
            <div className={styles.exportContent}>
              <ClickAwayListener onClickAway={handleExportClickAway}>
                <div style={{ position: "relative" }}>
                  <Button
                    onClick={handleExportClick}
                    disabled={formatData.length <= 0 && budgets?.length <= 0}
                    variant="outlined"
                    color="gray"
                    className={styles.exportPrintBtn}
                    endIcon={<img src={ExportIcon} alt="Export" />}
                  >
                    Export
                  </Button>
                  {exportOpen && (
                    <div className={styles.exportMenuMore}>
                      <ul>
                        <li
                          onClick={() => {
                            selectBudgetType === "pi"
                              ? ExportFileMini("xlsx")
                              : ExportFileMini("xlsx");
                            handleExportClick();
                          }}
                        >
                          Excel
                        </li>
                        <ReactToPrint
                          trigger={() => (
                            <li disabled={budgets?.length <= 0}>PDF</li>
                          )}
                          content={() => componentRefRank.current}
                        />

                        <li
                          onClick={() => {
                            selectBudgetType === "pi"
                              ? ExportFileMini("csv")
                              : ExportFileMini("csv");
                            handleExportClick();
                          }}
                        >
                          CSV
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
              <ReactToPrint
                trigger={() => (
                  <Button
                    disabled={formatData.length <= 0 && budgets?.length <= 0}
                    variant="outlined"
                    color="gray"
                    className={styles.exportPrintBtn}
                    endIcon={<img src={PrintIcon} alt="Print" />}
                  >
                    Print
                  </Button>
                )}
                content={() => componentRefRank.current}
              />
            </div>
          </div>

          <div className={styles.rank}>
            <Rank
              styles={styles}
              // filters
              selectBudgetType={selectBudgetType}
              setSelectBudgetType={setSelectBudgetType}
              states={states}
              selectedState={selectedState}
              setSelectedStates={setSelectedStates}
              setSearchState={setSearchState}
              searchState={searchState}
              filteredStates={filteredStates}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              // filters
              handleTypeClick={handleTypeClick}
              handleTypeClickAway={handleTypeClickAway}
              typeOpen={typeOpen}
              // click handler
              handleRankC={handleRankC}
              handleRankCClickAway={handleRankCClickAway}
              rankCopen={rankCopen}
              setRankData={setRankData}
              setGeneralData={setGeneralData}
              // ///
              // budget cat

              searchCategories={searchCategories}
              setSearchCategories={setSearchCategories}
              // filteredCategories={filteredCategories}
              sortBy={sortBy}
              setSortBy={setSortBy}
              // ///
              selectedStateType={selectedStateType}
              setSelectedStatesType={setSelectedStatesType}
              searchStateType={searchStateType}
              setSearchStateType={setSearchStateType}
              filteredStatesType={filteredStatesType}
              fetchBudgets={rankBudgets}
              fetchPiBudgets={fetchPiBudgets}
              isLoading={isLoading}
              popItems={popItems}
            />
          </div>
          <div className={styles.mobileExportWrapper}>
            <ClickAwayListener onClickAway={handleMobileExportClickAway}>
              <div style={{ position: "relative" }}>
                <Button
                  onClick={handleExportClickMobile}
                  disabled={formatData.length <= 0 && budgets?.length <= 0}
                  variant="outlined"
                  color="gray"
                  className={styles.exportPrintBtn}
                  endIcon={<img src={ExportIcon} alt="Export" />}
                >
                  Export
                </Button>
                {exportOpenMobile && (
                  <div className={styles.exportMenuMore}>
                    <ul>
                      <li
                        onClick={() => {
                          selectBudgetType === "pi"
                            ? ExportFileMini("xlsx")
                            : ExportFileMini("xlsx");
                          handleExportClick();
                        }}
                      >
                        Excel
                      </li>
                      <ReactToPrint
                        trigger={() => (
                          <li disabled={budgets?.length <= 0}>PDF</li>
                        )}
                        content={() => componentRefRank.current}
                      />
                      <li
                        onClick={() => {
                          selectBudgetType === "pi"
                            ? ExportFileMini("csv")
                            : ExportFileMini("csv");
                          handleExportClick();
                        }}
                      >
                        CSV
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </ClickAwayListener>
            <ReactToPrint
              trigger={() => (
                <Button
                  disabled={formatData.length <= 0 && budgets?.length <= 0}
                  variant="outlined"
                  color="gray"
                  className={styles.exportPrintBtn}
                  endIcon={<img src={PrintIcon} alt="Print" />}
                >
                  Print
                </Button>
              )}
              content={() => componentRefRank.current}
            />
          </div>
          <div style={{ marginTop: 20 }}></div>
          {budgets?.length <= 0 && formatData?.length <= 0 && (
            <div className={styles.empty}>
              <img src={EmptyImg} alt="No filter available" />
              <p>
                You have not made any query yet, use the filter section above to
                select the states and budget parameters you would like to
                compare
              </p>
            </div>
          )}
          {generalData[0]?.indicators ? (
            <>
              {generalData?.length > 0 && (
                <PiRankTable
                  data={returnRankPiSingleData}
                  componentRef={componentRefRank}
                />
              )}
            </>
          ) : (
            <>
              {formatData.length > 0 && (
                <RankTable
                  formatData={formatData}
                  componentRef={componentRefRank}
                />
              )}
            </>
          )}
        </div>
      </main>
      <Snackbar
        open={snakOpen}
        autoHideDuration={6000}
        onClose={handleSnakClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          style={{ fontWeight: 300 }}
          onClose={handleSnakClose}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default RankStates;
