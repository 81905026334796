const first_category = [
  { category: "Revenue by economic", name: "revenue_by_economuc" },
  { category: "Expenditure by economic", name: "exp_by_economic" },
  {
    category: "Expenditure by Administrative (Recurrent)",
    name: "exp_by_admin_recurrent",
  },
  {
    category: "Expenditure by Administrative (Capital)",
    name: "exp_by_admin_capital",
  },
  {
    category: "Expenditure by Functions (Recurrent)",
    name: "exp_by_func_recurrent",
  },
  {
    category: "Expenditure by Functions (Capital)",
    name: "exp_by_func_capital",
  },
];

const second_category = [
  {
    title: "Total Revenue",
    name: "total_revenue",
    first_category: "revenue_by_economuc",
  },
  {
    title: "Independent Revenue (Internally Generated Revenue)",
    name: "independent_revenue",
    first_category: "revenue_by_economuc",
  },
  {
    title: "Aids and Grants",
    name: "aids_grants",
    first_category: "revenue_by_economuc",
  },
  {
    title: "Capital Development Fund (CDF) Receipts",
    name: "capital_development_fund_receipts",
    first_category: "revenue_by_economuc",
  },
  //   Exp By Economic
  {
    title: "Personnel Expenditure",
    name: "personal_expenditure",
    first_category: "exp_by_economic",
  },
  {
    title: "Other Recurrent Expenditure",
    name: "other_recurrent_expenditure",
    first_category: "exp_by_economic",
  },
  {
    title: "Capital Expenditure",
    name: "capital_expenditure",
    first_category: "exp_by_economic",
  },
  //   Exp admin (rec)
  {
    title: "Administration Sector (Recurrent Expenditure)",
    name: "administration_sector__recurrent_expenditure",
    first_category: "exp_by_admin_recurrent",
  },
  {
    title: "Economic Sector (Recurrent Expenditure)",
    name: "economic_sector__recurrent_expenditure",
    first_category: "exp_by_admin_recurrent",
  },
  {
    title: "Law and Justice Sector (Recurrent Expenditure)",
    name: "law_and_justice_sector__recurrent_expenditure",
    first_category: "exp_by_admin_recurrent",
  },
  {
    title: "Regional Sector (Recurrent Expenditure)",
    name: "regional_sector__recurrent_expenditure",
    first_category: "exp_by_admin_recurrent",
  },
  {
    title: "Social Sector (Recurrent Expenditure)",
    name: "social_sector__recurrent_expenditure",
    first_category: "exp_by_admin_recurrent",
  },
  //   Exp admin (cap)
  {
    title: "Administration Sector (Capital Expenditure)",
    name: "administration_sector__capital_expenditure",
    first_category: "exp_by_admin_capital",
  },
  {
    title: "Economic Sector (Capital Expenditure)",
    name: "economic_sector__capital_expenditure",
    first_category: "exp_by_admin_capital",
  },
  {
    title: "Law and Justice Sector (Capital Expenditure)",
    name: "law_and_justice_sector__capital_expenditure",
    first_category: "exp_by_admin_capital",
  },
  {
    title: "Regional Sector (Capital Expenditure)",
    name: "regional_sector__capital_expenditure",
    first_category: "exp_by_admin_capital",
  },
  {
    title: "Social Sector (Capital Expenditure)",
    name: "social_sector__capital_expenditure",
    first_category: "exp_by_admin_capital",
  },
  // Exp by Func Recurrent
  {
    title: "Total Recurrent Expenditure",
    name: "total_recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "General Public Service (Recurrent Expenditure)",
    name: "general_public_service",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Public Order and Safety (Recurrent Expenditure)",
    name: "public_order_safety",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Economic Affairs (Recurrent Expenditure)",
    name: "economic_affairs",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Environmental Protection (Recurrent Expenditure)",
    name: "evironmental_protection",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Housing and Community Amenities (Recurrent Expenditure)",
    name: "housing_community_amenities",
    first_category: "exp_by_func_recurrent",
  },

  {
    title: "Health (Recurrent Expenditure)",
    name: "health",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Recreation, Culture and Religion (Recurrent Expenditure)",
    name: "recreation_culture_religon",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Education (Recurrent Expenditure)",
    name: "education",
    first_category: "exp_by_func_recurrent",
  },
  {
    title: "Social Protection (Recurrent Expenditure)",
    name: "social_protection",
    first_category: "exp_by_func_recurrent",
  },
  // Exp by Func Capital
  {
    title: "Total Capital Expenditure",
    name: "total_capital_expenditure",
    first_category: "exp_by_func_capital",
  },
  {
    title: "General Public Service (Capital Expenditure)",
    name: "general_public_service_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Public Order and Safety (Capital Expenditure)",
    name: "public_order_safety_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Economic Affairs (Capital Expenditure)",
    name: "economic_affairs_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Environmental Protection (Capital Expenditure)",
    name: "evironmental_protection_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Housing and Community Amenities (Capital Expenditure)",
    name: "housing_community_amenities_func",
    first_category: "exp_by_func_capital",
  },

  {
    title: "Health (Capital Expenditure)",
    name: "health_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Recreation, Culture and Religion (Capital Expenditure)",
    name: "recreation_culture_religon_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Education (Capital Expenditure)",
    name: "education_func",
    first_category: "exp_by_func_capital",
  },
  {
    title: "Social Protection (Capital Expenditure)",
    name: "social_protection_func",
    first_category: "exp_by_func_capital",
  },
];

const third_category = [
  {
    title: "Government Share of Federation Revenue (FAAC)",
    name: "government_share_of_federation_revenue_faac",
    first_category: "revenue_by_economuc",
    second_category: "total_revenue",
  },
  {
    title: "State Government Share of Statutory Allocation",
    name: "state_government_of_statutory_allocation",
    first_category: "revenue_by_economuc",
    second_category: "total_revenue",
  },

  {
    title: "Government Share of Value Added Tax (VAT)",
    name: "government_share_of_value_added_tax_vat",
    first_category: "revenue_by_economuc",
    second_category: "total_revenue",
  },
  {
    title: "Government Share of Other Federation Revenues",
    name: "government_share_of_other_federation_revenues",
    first_category: "revenue_by_economuc",
    second_category: "total_revenue",
  },

  {
    title: "Tax Revenue",
    name: "tax_revenue",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
  },
  {
    title: "Non-Tax Revenue",
    name: "non_tax_revenue",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
  },
  {
    title: "Domestic Aids",
    name: "domestic_aids",
    first_category: "revenue_by_economuc",
    second_category: "aids_grants",
  },
  {
    title: "Foreign Aids",
    name: "foreign_aids",
    first_category: "revenue_by_economuc",
    second_category: "aids_grants",
  },
  {
    title: "Domestic Grants",
    name: "domestic_grants",
    first_category: "revenue_by_economuc",
    second_category: "aids_grants",
  },
  {
    title: "Foreign Grants",
    name: "foreign_grants",
    first_category: "revenue_by_economuc",
    second_category: "aids_grants",
  },

  //   Exp by Economic
  {
    title: "Personnel Cost",
    name: "personal_cost",
    first_category: "exp_by_economic",
    second_category: "personal_expenditure",
  },
  {
    title: "Overhead Costs",
    name: "overhead_costs",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
  },
  {
    title: "Loans and Advances",
    name: "loans_and_advances",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
  },
  {
    title: "Grants and Contribution General",
    name: "grants_and_contribution_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
  },
  {
    title: "Subsidies General",
    name: "subsidies_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
  },
  {
    title: "Public Debt Charges",
    name: "public_debt_charges",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
  },
  // Exp by func Recurrent
  {
    title:
      "Executive & Legislative Organ, Financial Affairs and External Affairs (Recurrent Expenditure)",
    name: "executive_legislative_organ_financial_external",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "Foreign and Economic Aid (Recurrent Expenditure)",
    name: "foreign_economic_aid__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "General Services (Recurrent Expenditure)",
    name: "general_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "Basic Research (Recurrent Expenditure)",
    name: "basic_research__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "R&D General Public Services (Recurrent Expenditure)",
    name: "rd_general_public_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "General Public Services N.E.C (Recurrent Expenditure)",
    name: "general_public_servuces_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title: "Public Debt Transactions (Recurrent Expenditure)",
    name: "public_debt_transactions__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  {
    title:
      "Transfer of a General Character between Different Levels of Government (Recurrent Expenditure)",
    name: "transfer_general_character_between_dif_levels_of_gov",
    first_category: "exp_by_func_recurrent",
    second_category: "general_public_service",
  },
  // Second
  {
    title: "Police Services (Recurrent Expenditure)",
    name: "police_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "public_order_safety",
  },
  {
    title: "Fire Protection Services (Recurrent Expenditure)",
    name: "fire_protection_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "public_order_safety",
  },
  {
    title: "Justice & Law Courts (Recurrent Expenditure)",
    name: "justice_law_courts__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "public_order_safety",
  },
  // third
  {
    title:
      "General Economic, Commercial and Labour Affairs (Recurrent Expenditure)",
    name: "general_economic_commercial_labour_affairs",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Agriculture, Forestry, Fishing and Hunting (Recurrent Expenditure)",
    name: "agriculture_forestry_fishing_hunting__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Fuel and Energy (Recurrent Expenditure)",
    name: "fuel_energy__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Mining, Manufacturing and Construction (Recurrent Expenditure)",
    name: "mining_manufacturing_construction__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Transport (Recurrent Expenditure)",
    name: "transport__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Communication (Recurrent Expenditure)",
    name: "communication__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Other Industries (Recurrent Expenditure)",
    name: "other_inductries__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "R&D Economic Affairs (Recurrent Expenditure)",
    name: "ed_economic_affairs__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },
  {
    title: "Economic Affairs N. E. C (Recurrent Expenditure)",
    name: "economic_affairs_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "economic_affairs",
  },

  // Forth
  {
    title: "Waste Management (Recurrent Expenditure)",
    name: "waste_management__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  {
    title: "Waste Water Management (Recurrent Expenditure)",
    name: "waste_water_management__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  {
    title: "Pollution Abatement (Recurrent Expenditure)",
    name: "pollution_abatement__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  {
    title: "Protection of Biodiversity and Landscape (Recurrent Expenditure)",
    name: "protection_biodiversity_landscape__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  {
    title: "R&D Environmental Protection (Recurrent Expenditure)",
    name: "rd_environmental_protection__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  {
    title: "Environmental Protection N.E.C. (Recurrent Expenditure)",
    name: "environmental_protection_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "evironmental_protection",
  },
  // Fifth
  {
    title: "Community Development (Recurrent Expenditure)",
    name: "community_development__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "housing_community_amenities",
  },
  {
    title: "Water Supply (Recurrent Expenditure)",
    name: "water_supply__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "housing_community_amenities",
  },
  {
    title: "Street Lighting (Recurrent Expenditure)",
    name: "street_light__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "housing_community_amenities",
  },
  {
    title: "R&D Housing and Community Amenities (Recurrent Expenditure)",
    name: "rd_housing_community_amenities__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "housing_community_amenities",
  },
  {
    title: "Housing and Community Amenities N. E. C (Recurrent Expenditure)",
    name: "housing_community_amenities_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "housing_community_amenities",
  },
  // sixth
  {
    title: "Medical Products, Appliances and Equipment (Recurrent Expenditure)",
    name: "medical_products_appliances_equiptment__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  {
    title: "Outpatient Services (Recurrent Expenditure)",
    name: "outpatient_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  {
    title: "Hospital Services (Recurrent Expenditure)",
    name: "hospital_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  {
    title: "Public Health Services (Recurrent Expenditure)",
    name: "public_health_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  {
    title: "R&D Health (Recurrent Expenditure)",
    name: "rd_health__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  {
    title: "Health N. E. C (Recurrent Expenditure)",
    name: "health_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "health",
  },
  // Seventh
  {
    title: "Recreational and Sporting Services (Recurrent Expenditure)",
    name: "recreational_sporting_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  {
    title: "Cultural Services (Recurrent Expenditure)",
    name: "cultural_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  {
    title: "Broadcasting and Publishing Services (Recurrent Expenditure)",
    name: "broadcasting_publishing_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  {
    title: "Religious and Other Community Services (Recurrent Expenditure)",
    name: "religous_other_community_services__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  {
    title: "R&D Recreation, Culture and Religion (Recurrent Expenditure)",
    name: "rd_recreation_culture_religon__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  {
    title: "Recreation, Culture and Religion N. E. C (Recurrent Expenditure)",
    name: "recreation_culture_religon_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "recreation_culture_religon",
  },
  // Eight
  {
    title: "Pre-Primary and Primary Education (Recurrent Expenditure)",
    name: "preprimary_primary_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Secondary Education (Recurrent Expenditure)",
    name: "secondary_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Post-Secondary and Non Tertiary Education (Recurrent Expenditure)",
    name: "postsecondary_nontetiary_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Tertiary Education (Recurrent Expenditure)",
    name: "tetiary_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Education Not Definable by Level (Recurrent Expenditure)",
    name: "education_nondefinable_by_level__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Subsidiary Services to Education (Recurrent Expenditure)",
    name: "subsidiary_services_to_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "R&D Education (Recurrent Expenditure)",
    name: "rd_education__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  {
    title: "Education N. E. C (Recurrent Expenditure)",
    name: "education_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "education",
  },
  // ninth
  {
    title: "Sickness and Disability (Recurrent Expenditure)",
    name: "sickness_disability__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Old Age (Recurrent Expenditure)",
    name: "old_age__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Survivors (Recurrent Expenditure)",
    name: "survivors__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Family and Children (Recurrent Expenditure)",
    name: "family_and_children__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Unemployment (Recurrent Expenditure)",
    name: "unemployment__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Housing (Recurrent Expenditure)",
    name: "housing__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Social Exclusion N. E. C (Recurrent Expenditure)",
    name: "social_exclusion_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "R&D Social Protection (Recurrent Expenditure)",
    name: "rd_social_protection__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },
  {
    title: "Social Protection N. E. C (Recurrent Expenditure)",
    name: "social_protection_nec__recurrent_expenditure",
    first_category: "exp_by_func_recurrent",
    second_category: "social_protection",
  },

  // Exp by func Capital
  {
    title:
      "Executive & Legislative Organ, Financial Affairs and External Affairs (Capital Expenditure)",
    name: "executive_legislative_organ_financial_external_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "Foreign and Economic Aid (Capital Expenditure)",
    name: "foreign_economic_aid__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "General Services (Capital Expenditure)",
    name: "general_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "Basic Research (Capital Expenditure)",
    name: "basic_research__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "R&D General Public Services (Capital Expenditure)",
    name: "rd_general_public_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "General Public Services N.E.C (Capital Expenditure)",
    name: "general_public_servuces_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "Public Debt Transactions (Capital Expenditure)",
    name: "public_debt_transactions__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title:
      "Transfer of a General Character between Different Levels of Government (Capital Expenditure)",
    name: "transfer_general_character_between_dif_levels_of_gov_func",
    first_category: "exp_by_func_capital",
    second_category: "general_public_service_func",
  },
  {
    title: "Police Services (Capital Expenditure)",
    name: "police_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "public_order_safety_func",
  },
  {
    title: "Fire Protection Services (Capital Expenditure)",
    name: "fire_protection_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "public_order_safety_func",
  },
  {
    title: "Justice & Law Courts (Capital Expenditure)",
    name: "justice_law_courts__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "public_order_safety_func",
  },
  // Third
  {
    title:
      "General Economic, Commercial and Labour Affairs (Capital Expenditure)",
    name: "general_economic_commercial_labour_affairs_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Agriculture, Forestry, Fishing and Hunting (Capital Expenditure)",
    name: "agriculture_forestry_fishing_hunting__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Fuel and Energy (Capital Expenditure)",
    name: "fuel_energy__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Mining, Manufacturing and Construction (Capital Expenditure)",
    name: "mining_manufacturing_construction__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Transport (Capital Expenditure)",
    name: "transport__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Communication (Capital Expenditure)",
    name: "communication__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Other Industries (Capital Expenditure)",
    name: "other_inductries__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "R&D Economic Affairs (Capital Expenditure)",
    name: "ed_economic_affairs__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  {
    title: "Economic Affairs N. E. C (Capital Expenditure)",
    name: "economic_affairs_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "economic_affairs_func",
  },
  // Forth
  {
    title: "Waste Management (Capital Expenditure)",
    name: "waste_management__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  {
    title: "Waste Water Management (Capital Expenditure)",
    name: "waste_water_management__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  {
    title: "Pollution Abatement (Capital Expenditure)",
    name: "pollution_abatement__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  {
    title: "Protection of Biodiversity and Landscape (Capital Expenditure)",
    name: "protection_biodiversity_landscape__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  {
    title: "R&D Environmental Protection (Capital Expenditure)",
    name: "rd_environmental_protection__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  {
    title: "Environmental Protection N.E.C. (Capital Expenditure)",
    name: "environmental_protection_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "evironmental_protection_func",
  },
  // Fifth
  {
    title: "Community Development (Capital Expenditure)",
    name: "community_development__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "housing_community_amenities_func",
  },
  {
    title: "Water Supply (Capital Expenditure)",
    name: "water_supply__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "housing_community_amenities_func",
  },
  {
    title: "Street Lighting (Capital Expenditure)",
    name: "street_light__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "housing_community_amenities_func",
  },
  {
    title: "R&D Housing and Community Amenities (Capital Expenditure)",
    name: "rd_housing_community_amenities__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "housing_community_amenities_func",
  },
  {
    title: "Housing and Community Amenities N. E. C (Capital Expenditure)",
    name: "housing_community_amenities_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "housing_community_amenities_func",
  },
  // sixth
  {
    title: "Medical Products, Appliances and Equipment (Capital Expenditure)",
    name: "medical_products_appliances_equiptment__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  {
    title: "Outpatient Services (Capital Expenditure)",
    name: "outpatient_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  {
    title: "Hospital Services (Capital Expenditure)",
    name: "hospital_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  {
    title: "Public Health Services (Capital Expenditure)",
    name: "public_health_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  {
    title: "R&D Health (Capital Expenditure)",
    name: "rd_health__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  {
    title: "Health N. E. C (Capital Expenditure)",
    name: "health_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "health_func",
  },
  // Seventh
  {
    title: "Recreational and Sporting Services (Capital Expenditure)",
    name: "recreational_sporting_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  {
    title: "Cultural Services (Capital Expenditure)",
    name: "cultural_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  {
    title: "Broadcasting and Publishing Services (Capital Expenditure)",
    name: "broadcasting_publishing_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  {
    title: "Religious and Other Community Services (Capital Expenditure)",
    name: "religous_other_community_services__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  {
    title: "R&D Recreation, Culture and Religion (Capital Expenditure)",
    name: "rd_recreation_culture_religon__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  {
    title: "Recreation, Culture and Religion N. E. C (Capital Expenditure)",
    name: "recreation_culture_religon_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "recreation_culture_religon_func",
  },
  // Eight
  {
    title: "Pre-Primary and Primary Education (Capital Expenditure)",
    name: "preprimary_primary_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Secondary Education (Capital Expenditure)",
    name: "secondary_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Post-Secondary and Non Tertiary Education (Capital Expenditure)",
    name: "postsecondary_nontetiary_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Tertiary Education (Capital Expenditure)",
    name: "tetiary_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Education Not Definable by Level (Capital Expenditure)",
    name: "education_nondefinable_by_level__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Subsidiary Services to Education (Capital Expenditure)",
    name: "subsidiary_services_to_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "R&D Education (Capital Expenditure)",
    name: "rd_education__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  {
    title: "Education N. E. C (Capital Expenditure)",
    name: "education_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "education_func",
  },
  // ninth
  {
    title: "Sickness and Disability (Capital Expenditure)",
    name: "sickness_disability__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Old Age (Capital Expenditure)",
    name: "old_age__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Survivors (Capital Expenditure)",
    name: "survivors__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Family and Children (Capital Expenditure)",
    name: "family_and_children__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Unemployment (Capital Expenditure)",
    name: "unemployment__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Housing (Capital Expenditure)",
    name: "housing__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Social Exclusion N. E. C (Capital Expenditure)",
    name: "social_exclusion_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "R&D Social Protection (Capital Expenditure)",
    name: "rd_social_protection__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
  {
    title: "Social Protection N. E. C (Capital Expenditure)",
    name: "social_protection_nec__capital_expenditure_func",
    first_category: "exp_by_func_capital",
    second_category: "social_protection_func",
  },
];

const forth_category = [
  {
    title: "Personal Taxes",
    name: "personal_taxes",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "tax_revenue",
  },
  {
    title: "Other Taxes",
    name: "other_taxes",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "tax_revenue",
  },
  {
    title: "Licences General",
    name: "licences_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Fees – General",
    name: "fees_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Fines – General",
    name: "fines_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Sales – General",
    name: "sales_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Earnings – General ",
    name: "earnings_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Rent on Government Buildings – General",
    name: "rent_on_gov_buildings_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Rent on Land and Others – General",
    name: "rent_on_land_and_others_general",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Repayments",
    name: "repayments",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Investment Income ",
    name: "investment_income",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Interest Earned",
    name: "interest_earned",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Reimbursement",
    name: "reimbursement",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  {
    title: "Miscellaneous Income",
    name: "miscellaneous_income",
    first_category: "revenue_by_economuc",
    second_category: "independent_revenue",
    third_category: "non_tax_revenue",
  },
  //   Exp by Economic

  {
    title: "Salaries and Wages – General",
    name: "salaries_wages_general",
    first_category: "exp_by_economic",
    second_category: "personal_expenditure",
    third_category: "personal_cost",
  },
  {
    title: "Allowances",
    name: "allowances",
    first_category: "exp_by_economic",
    second_category: "personal_expenditure",
    third_category: "personal_cost",
  },
  {
    title: "Social Contribution",
    name: "social_contribution",
    first_category: "exp_by_economic",
    second_category: "personal_expenditure",
    third_category: "personal_cost",
  },
  {
    title: "Social Benefit",
    name: "social_benefit",
    first_category: "exp_by_economic",
    second_category: "personal_expenditure",
    third_category: "personal_cost",
  },

  {
    title: "Transport and Travelling General",
    name: "transport_and_travelling_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Utilities General",
    name: "utilities_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Materials and Supplies General",
    name: "materials_and_supplies_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Maintenance Services General",
    name: "maintainance_services_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Training – General",
    name: "training_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Other Services General",
    name: "other_services_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Consulting and Professional Services General",
    name: "consulting_and_professional_services_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Fuel and Lubricant General",
    name: "fuel_and_lubricant_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Financial General",
    name: "financial_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Miscellaneous General",
    name: "miscellaneous_general",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "overhead_costs",
  },
  {
    title: "Staff Loans and Allowances",
    name: "staff_loans_and_allowances",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "loans_and_advances",
  },
  {
    title: "Foreign Debt Interest",
    name: "foreign_debt_interest",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "public_debt_charges",
  },
  {
    title: "Domestic Debt Interest ",
    name: "domestic_debt_interest",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "public_debt_charges",
  },
  {
    title: "Foreign Debt Principal",
    name: "foreign_debt_principal",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "public_debt_charges",
  },
  {
    title: "Domestic Debt Principal",
    name: "domestic_debt_principal",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "public_debt_charges",
  },
  {
    title: "Transfers-Payments",
    name: "transfers_payments",
    first_category: "exp_by_economic",
    second_category: "other_recurrent_expenditure",
    third_category: "public_debt_charges",
  },
];

const piData = [
  // { name: "budget_performance", title: "Budget Performance (%)" },
  // {
  //   name: "total_revenue_performance_excluding_opening_balance",
  //   title: "Total Revenue Performance (exluding opening balance) (%)",
  // },
  // {
  //   name: "Recurrent Revenue Performance (%)",
  //   title: "recurrent_revenue_performance",
  // },
  // {
  //   name: "Federation Revenue Performance (%)",
  //   title: "federation_revenue_performance",
  // },
  // { name: "IGR Performance (%)", title: "igr_performance" },
  // { title: "Aids and Grants Performance (%)", name: "aids_grant_performance" },
  // {
  //   name: "capital_reciepts_cdf_performance",
  //   title: "  Capital Receipts (Capital Development Fund) Performance (%)",
  // },
  // {
  //   title: "Personnel Expenditure Performance (%)",
  //   name: "personnel_expenditure_performance",
  // },
  // {
  //   title: "Other Recurrent Expenditure Performance (%)",
  //   name: "other_recurrent_expenditure_performance",
  // },
  // {
  //   name: "capital_expenditure_performance",
  //   title: "Capital Expenditure Performance (%)",
  // },
  // {
  //   name: "total_expenditure_performance",
  //   title: "Total Expenditure Performance (%)",
  // },
  {
    name: "revenues_percantage_total_revenue",
    title: "Revenues(% of Total Revenue)",
  },
  {
    name: "revenues_percantage_total_expenditure",
    title: "Revenues (% of Total Expenditure)",
  },
  {
    name: "expenditures_percentage_total_revenue",
    title: "Expenditures(% of Total Revenue)",
  },
  {
    name: "expenditures_percentage_total_expenditure",
    title: "Expenditures(% of Total Expenditure)",
  },
  { name: "expenditure", title: "Expenditure" },
  { name: "expenditureMDA", title: "Expenditure by MDA" },
  {
    name: "expenditure_mda_percentage_total_expenditure",
    title: "Expenditure by MDA(% of Total Expenditure)",
  },
  {
    name: "expenditure_MDA_percentage_total_revenue",
    title: "Expenditure by MDA(% of Total Revenue)",
  },
  { name: "expenditure_by_sector", title: "Expenditure by Sector" },
  {
    name: "expenditure_by_sector_percentage_total_expenditure",
    title: "Expenditure by Sector(% of Total Expenditure)",
  },
  {
    name: "expenditure_by_sector_percentage_total_revenue",
    title: "Expenditure by Sector(% of Total Revenue)",
  },
  { name: "expenditure_by_function", title: "Expenditure by Function" },
  {
    name: "expenditure_by_function_percentage_total_expenditure",
    title: "Expenditure by Function(% of Total Expenditure)",
  },
  {
    name: "expenditure_by_function_percentage_total_revenue",
    title: "Expenditure by Function (% of Total Revenue)",
  },
];

const piRankData = [
  { name: "budget_performance", title: "Budget Performance (%)" },
  {
    name: "total_revenue_performance_excluding_opening_balance",
    title: "Total Revenue Performance (exluding opening balance) (%)",
  },
  {
    title: "Recurrent Revenue Performance (%)",
    name: "recurrent_revenue_performance",
  },
  {
    title: "Federation Revenue Performance (%)",
    name: "federation_revenue_performance",
  },
  { title: "IGR Performance (%)", name: "igr_performance" },
  { title: "Aids and Grants Performance (%)", name: "aids_grant_performance" },
  {
    name: "capital_reciepts_cdf_performance",
    title: "  Capital Receipts (Capital Development Fund) Performance (%)",
  },
  {
    title: "Personnel Expenditure Performance (%)",
    name: "personnel_expenditure_performance",
  },
  {
    title: "Other Recurrent Expenditure Performance (%)",
    name: "other_recurrent_expenditure_performance",
  },
  {
    name: "capital_expenditure_performance",
    title: "Capital Expenditure Performance (%)",
  },
  {
    name: "total_expenditure_performance",
    title: "Total Expenditure Performance (%)",
  },
  {
    name: "revenues_percantage_total_revenue",
    title: "Revenues(% of Total Revenue)",
  },
  {
    name: "revenues_percantage_total_expenditure",
    title: "Revenues (% of Total Expenditure)",
  },
  {
    name: "expenditures_percentage_total_revenue",
    title: "Expenditures(% of Total Revenue)",
  },
  {
    name: "expenditures_percentage_total_expenditure",
    title: "Expenditures(% of Total Expenditure)",
  },
  { name: "expenditure", title: "Expenditure" },
  { name: "expenditureMDA", title: "Expenditure by MDA" },
  {
    name: "expenditure_mda_percentage_total_expenditure",
    title: "Expenditure by MDA(% of Total Expenditure)",
  },
  {
    name: "expenditure_MDA_percentage_total_revenue",
    title: "Expenditure by MDA(% of Total Revenue)",
  },
  { name: "expenditure_by_sector", title: "Expenditure by Sector" },
  {
    name: "expenditure_by_sector_percentage_total_expenditure",
    title: "Expenditure by Sector(% of Total Expenditure)",
  },
  {
    name: "expenditure_by_sector_percentage_total_revenue",
    title: "Expenditure by Sector(% of Total Revenue)",
  },
  { name: "expenditure_by_function", title: "Expenditure by Function" },
  {
    name: "expenditure_by_function_percentage_total_expenditure",
    title: "Expenditure by Function(% of Total Expenditure)",
  },
  {
    name: "expenditure_by_function_percentage_total_revenue",
    title: "Expenditure by Function (% of Total Revenue)",
  },
];

const piRankSecondCategories = [
  {
    name: "total_expenditure",
    title: "Total Expenditure",
    category: "expenditure",
  },
  {
    name: "recurrent_expenditure",
    title: "Recurrent Expenditure",
    category: "expenditure",
  },
  {
    name: "capital_expenditure",
    title: "Capital Expenditure",
    category: "expenditure",
  },
  {
    name: "recurrent_expenditure_total_expenditure",
    title: "Recurrent Expenditure (% of Total Expenditure)",
    category: "expenditure",
  },
  {
    name: "capital_expenditure_total_expenditure",
    title: "Capital Expenditure (% of Total Expenditure)",
    category: "expenditure",
  },
  {
    name: "recurrent_expenditure_total_revenue",
    title: "Recurrent Expenditure (% of Total Revenue)",
    category: "expenditure",
  },
  {
    name: "capital_expenditure_total_revenue",
    title: "Capital Expenditure (% of Total Revenue)",
    category: "expenditure",
  },
  {
    name: "recurrent_revenue_total_expenditure",
    title: "Recurrent Revenue (% of Total Expenditure)",
    category: "expenditure",
  },
  //
  {
    name: "governors_office",
    title: "Governor’s Office (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "secretary_to_state_gov",
    title: "Secretary to the State Government (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "admin_sector",
    title: "Administration Sector (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "state_assembly",
    title: "State Assembly (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_of_info",
    title: "Ministry of Information (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_of_internal_security_home_affairs",
    title: "Ministry of Internal Security and Home Affairs (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "office_head_of_service",
    title: "Office of the Head of Service (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "office_auditor_general_state",
    title: "Office of the Auditor General State (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "office_auditor_general_lga",
    title: "Civil Service Commission (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "lga_service_commission",
    title: "Local Government Service Commission (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "state_inec",
    title: "State Independent Electoral Commission (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "economic_sector",
    title: "Economic Sector (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_of_agric_natural_resources",
    title: "Ministry of Agriculture & Natural Resources (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_finance",
    title: "Ministry of Finance (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_commerce_trade_business_industry_investment",
    title:
      "Ministry of Commerce/Trade/Business/Industry/Investment (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_labour_productivity",
    title: "Ministry of Labour and Productivity (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_science_tech",
    title: "Ministry of Science and Technology (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_transport",
    title: "Ministry of Transport (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_energy",
    title: "Ministry of Energy (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_works",
    title: "Ministry of Works (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_culture_tourism",
    title: "Ministry of Culture and Tourism (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "state_planning_commission",
    title:
      "State Planning Commission/Ministry of Budget and Economic Planning (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "fiscal_responsibility_commission",
    title: "Fiscal Responsibility Commission (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_water_resources",
    title: "Ministry of Water Resources (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_housing_urban_development",
    title: "Ministry of Housing and Urban Development (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_land_survey",
    title: "Ministry of Land and Survey (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "law_justuce_sector",
    title: "Law and Justice Sector (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "judiciary",
    title: "Judiciary (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_justice",
    title: "Ministry of Justice (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "regional_sector",
    title: "Regional Sector (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "oil_producing_communities_dev_agency",
    title:
      "Oil Producing Communities Development Agency/Commission (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "state_capital_dev_agency",
    title: "State Capital Development Agency/Ministry (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "social_sector",
    title: "Social Sector (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_youth_development",
    title: "Ministry of Youth Development (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_women_affairs",
    title: "Ministry of Women Affairs (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_education",
    title: "Ministry of Education (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_health",
    title: "Ministry of Health (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_environment",
    title: "Ministry of Environment (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "ministry_sports",
    title: "Ministry of Sport Development (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "minsitry_lga",
    title: "Ministry of Local Government (Total Expenditure)",
    category: "expenditureMDA",
  },
  {
    name: "recurrent_revenue",
    title: "Recurrent Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "federation_revenue",
    title: "Federation Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "satutory_allocation",
    title: "Statutory Allocation (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "derivation",
    title: "Derivation (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "vat",
    title: "Value Added Tax (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "other_federation_transfers",
    title: "Other Federation Transfers (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  //
  {
    name: "igr",
    title: "Internally Generated Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "tax_revenue",
    title: "Tax Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "personal_taxes",
    title: "Personal Taxes (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "personal_income_tax_paye",
    title: "Personal Income Tax (PAYE) (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "personal_income_tax_dat",
    title:
      "  Personnal Income Tax (Direct Assessment Taxes) (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "other_personal_tax_nec",
    title: "Other Personal Tax N.E.C (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "other_taxes",
    title: "Other Taxes (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "sales_taxes",
    title: "Sales Tax (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "lottery_tax",
    title: " Lottery Tax/Licence (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "property_tax",
    title: "Property Tax (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "capital_gain_tax",
    title: " Capital Gain Taxes (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "withholding_tax",
    title: "Withholding Tax (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "other_taxes_nec",
    title: "Other Taxes N.E.C (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "non_tax_revenue",
    title: "Non-Tax Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "licances_general",
    title: "Licences General (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "fees_general",
    title: " Fees – General (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "fines_general",
    title: " Fines – General (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "sales_general",
    title: "Sales – General (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "earnings_general",
    title: "  Earnings – General (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "rent_on_government_building_general",
    title: "Rent On Government Buildings – General (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "repayments",
    title: "Repayments (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "investment_income",
    title: "Investment Income (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "interest_earned",
    title: "Interest Earned (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "reimbursement",
    title: " Reimbursement (% of Total Revenue) ",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "miscellaneous_income",
    title: "Miscellaneous Income (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "aids_grants",
    title: "Aids and Grants (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_aids",
    title: "Domestic Aids (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "foreign_aids",
    title: "Foreign Aids (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_grants",
    title: "Domestic Grants (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "foreign_grants",
    title: "Foreign Grants (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "capital_development_fund",
    title: "Capital Development Fund (CDF) Receipts (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "sales_of_fixed_assets",
    title: "Sale Of Fixed Assets (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_loans_from_financial_instituitions",
    title:
      " Domestic Loans/ Borrowings From Financial Institutions (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_loans_from_other_government_entities",
    title:
      "  Domestic Loans/ Borrowings From Other Government Entities (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_loans_from_other_entities",
    title:
      "  Domestic Loans/ Borrowings From Other Entities/ Organisations (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "international_loans_from_financial_instituitions",
    title:
      "International Loans/ Borrowings From Financial Institutions (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "internaitional_loans_from_other_government_entities",
    title:
      " International Loans/ Borrowings From Other Government Entities (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "internaitional_loans_from_other_entities",
    title:
      "International Loans/ Borrowings From Other Entities/ Organisations (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "foreign_debt_forgiveness",
    title: "Foreign Debt Forgiveness (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "domestic_debt_foregiveness",
    title: "Domestic Debt Forgiveness (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "gain_on_disposal_asset_ppe",
    title: "Gain On Disposal Of Asset – PPE (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "gain_on_disposal_asset_investment_property",
    title:
      "   Gain On Disposal Of Asset - Investment Property (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "minority_interest_share_of_surplus",
    title: "Minority Interest Share Of Surplus (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "extraordinary_items",
    title: "Extraordinary Items (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },
  {
    name: "unspecified_revenue",
    title: "Unspecified Revenue (% of Total Revenue)",
    category: "revenues_percantage_total_revenue",
  },

  // Second Category
  {
    name: "recurrent_revenue_exp",
    title: "Recurrent Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "federation_revenue_exp",
    title: "Federation Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "satutory_allocation_exp",
    title: "Statutory Allocation (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "derivation_exp",
    title: "Derivation (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "vat_exp",
    title: "Value Added Tax (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "other_federation_transfers_exp",
    title: "Other Federation Transfers (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  ///////////////////////////////
  {
    name: "igr_exp",
    title: "Internally Generated Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "tax_revenue_exp",
    title: "Tax Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "personal_taxes_exp",
    title: "Personal Taxes (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "personal_income_tax_paye_exp",
    title: "Personal Income Tax (PAYE) (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "personal_income_tax_dat_exp",
    title:
      "  Personnal Income Tax (Direct Assessment Taxes) (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "other_personal_tax_nec_exp",
    title: "Other Personal Tax N.E.C (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "other_taxes_exp",
    title: "Other Taxes (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "sales_taxes_exp",
    title: "Sales Tax (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "lottery_tax_exp",
    title: " Lottery Tax/Licence (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "property_tax_exp",
    title: "Property Tax (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "capital_gain_tax_exp",
    title: " Capital Gain Taxes (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "withholding_tax_exp",
    title: "Withholding Tax (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "other_taxes_nec_exp",
    title: "Other Taxes N.E.C (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "non_tax_revenue_exp",
    title: "Non-Tax Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "licances_general_exp",
    title: "Licences General (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "fees_general_exp",
    title: " Fees – General (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "fines_general_exp",
    title: " Fines – General (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "sales_general_exp",
    title: "Sales – General (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "earnings_general_exp",
    title: "  Earnings – General (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "rent_on_government_building_general_exp",
    title: "Rent On Government Buildings – General (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "repayments_exp",
    title: "Repayments (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "investment_income_exp",
    title: "Investment Income (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "interest_earned_exp",
    title: "Interest Earned (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "reimbursement_exp",
    title: " Reimbursement (% of Total Expenditure) ",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "miscellaneous_income_exp",
    title: "Miscellaneous Income (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "aids_grants_exp",
    title: "Aids and Grants (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_aids_exp",
    title: "Domestic Aids (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "foreign_aids_exp",
    title: "Foreign Aids (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_grants_exp",
    title: "Domestic Grants (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "foreign_grants_exp",
    title: "Foreign Grants (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "capital_development_fund_exp",
    title: "Capital Development Fund (CDF) Receipts (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "sales_of_fixed_assets_exp",
    title: "Sale Of Fixed Assets (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_loans_from_financial_instituitions_exp",
    title:
      " Domestic Loans/ Borrowings From Financial Institutions (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_loans_from_other_government_entities_exp",
    title:
      "  Domestic Loans/ Borrowings From Other Government Entities (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_loans_from_other_entities_exp",
    title:
      "  Domestic Loans/ Borrowings From Other Entities/ Organisations (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure_exp",
  },
  {
    name: "international_loans_from_financial_instituitions_exp",
    title:
      "International Loans/ Borrowings From Financial Institutions (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "internaitional_loans_from_other_government_entities_exp",
    title:
      " International Loans/ Borrowings From Other Government Entities (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "internaitional_loans_from_other_entities_exp",
    title:
      "International Loans/ Borrowings From Other Entities/ Organisations (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "foreign_debt_forgiveness_exp",
    title: "Foreign Debt Forgiveness (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "domestic_debt_foregiveness_exp",
    title: "Domestic Debt Forgiveness (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "gain_on_disposal_asset_ppe_exp",
    title: "Gain On Disposal Of Asset – PPE (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "gain_on_disposal_asset_investment_property_exp",
    title:
      "   Gain On Disposal Of Asset - Investment Property (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "minority_interest_share_of_surplus_exp",
    title: "Minority Interest Share Of Surplus (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "extraordinary_items_exp",
    title: "Extraordinary Items (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  {
    name: "unspecified_revenue_exp",
    title: "Unspecified Revenue (% of Total Expenditure)",
    category: "revenues_percantage_total_expenditure",
  },
  // /
  {
    name: "personal_expenditure",
    title: "Personnel Expenditure (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "personal_cost",
    title: "Personnel Cost (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "salaries_wages",
    title: "Salaries and Wages – General (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "salaries",
    title: "Salaries (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "ovetimes_payments",
    title: "Overtime Payments (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "consolidated_revenue_charges",
    title: "Consolidated Revenue Charges (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "allowances",
    title: "Allowances (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "social_contribution",
    title: "Social Contribution (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "nhis_contribution",
    title: "NHIS Contribution (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "contributory_pension",
    title: "Contributory Pension (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "group_life_insurance",
    title: "Group Life Insurance (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "employee_comepensation_fund",
    title: "Employee Compensation Fund (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "housing_fund_contribution",
    title: "Housing Fund Contribution (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "social_benefit",
    title: "Social Benefit (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "gratuity",
    title: "Gratuity (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "pension",
    title: "Pension (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "death_benefit",
    title: "Death Benefit (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "payment_benefit_to_past_governors_deputies",
    title:
      "Payment of Benefits to past Governors/Deputies (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  {
    name: "other_recurrent_expenditure",
    title: "Other Recurrent Expenditure (% of Total Revenue)",
    category: "expenditures_percentage_total_revenue",
  },
  // ////////
  {
    name: "salaries_wages_exp",
    title: "Salaries and Wages – General (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "salaries_exp",
    title: "Salaries (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "ovetimes_payments_exp",
    title: "Overtime Payments (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "consolidated_revenue_charges_exp",
    title: "Consolidated Revenue Charges (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "allowances_exp",
    title: "Allowances (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "social_contribution_exp",
    title: "Social Contribution (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "nhis_contribution_exp",
    title: "NHIS Contribution (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "contributory_pension_exp",
    title: "Contributory Pension (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "group_life_insurance_exp",
    title: "Group Life Insurance (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "employee_comepensation_fund_exp",
    title: "Employee Compensation Fund (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "housing_fund_contribution_exp",
    title: "Housing Fund Contribution (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "social_benefit_exp",
    title: "Social Benefit (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "gratuity_exp",
    title: "Gratuity (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "pension_exp",
    title: "Pension (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "death_benefit_exp",
    title: "Death Benefit (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "payment_benefit_to_past_governors_deputies_exp",
    title:
      "Payment of Benefits to past Governors/Deputies (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "other_recurrent_expenditure_exp",
    title: "Other Recurrent Expenditure (% of Total Expenditure)",
    category: "expenditures_percentage_total_expenditure",
  },
  {
    name: "admin_sector_exp",
    title: " Administration Sector (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    title: "Governor's office (% of Total Expenditure)",
    name: "governors_office_exp",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "secretary_to_state_gov_exp",
    title: "  Secretary to the State Government (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "state_assembly_exp",
    title: "State Assembly (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_of_info_exp",
    title: "Ministry of Information (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_of_internal_security_home_affairs_exp",
    title:
      "Ministry of Internal Security and Home Affairs (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "office_head_of_service_exp",
    title: "Office of the Head of Service (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "office_auditor_general_state_exp",
    title: "Office of the Auditor General State (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "office_auditor_general_lga_exp",
    title:
      "Office of the Auditor General Local Government (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "civil_service_commission_exp",
    title: "Civil Service Commission (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "lga_service_commission_exp",
    title: "  Local Government Service Commission (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "state_inec_exp",
    title: "  State Independent Electoral Commission (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "economic_sector_exp",
    title: "  Economic Sector (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_of_agric_natural_resources_exp",
    title:
      "Ministry of Agriculture & Natural Resources (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_finance_exp",
    title: "Ministry of Finance (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_commerce_trade_business_industry_investment_exp",
    title:
      " Ministry of Commerce/Trade/Business/Industry/Investment (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_labour_productivity_exp",
    title: "Ministry of Labour and Productivity (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_science_tech_exp",
    title: " Ministry of Science and Technology (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_transport_exp",
    title: "Ministry of Transport (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_energy_exp",
    title: "Ministry of Energy (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_works_exp",
    title: "Ministry of Works (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    title: "   Ministry of Culture and Tourism (% of Total Expenditure)",
    name: "ministry_culture_tourism_exp",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "state_planning_commission_exp",
    title:
      "State Planning Commission/Ministry of Budget and Economic Planning(% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "fiscal_responsibility_commission_exp",
    title: "   Fiscal Responsibility Commission (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_water_resources_exp",
    title: "Ministry of Water Resources (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_housing_urban_development_exp",
    title:
      "   Ministry of Housing and Urban Development (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_land_survey_exp",
    title: "Ministry of Land and Survey (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "law_justuce_sector_exp",
    title: "  Law and Justice Sector (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "judiciary_exp",
    title: "Judiciary (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_justice_exp",
    title: "Ministry of Justice (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "regional_sector_exp",
    title: "     Regional Sector (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "oil_producing_communities_dev_agency_exp",
    title:
      "Oil Producing Communities Development Agency/Commission (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "state_capital_dev_agency_exp",
    title:
      "  State Capital Development Agency/Ministry (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "social_sector_exp",
    title: "Social Sector (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_youth_development_exp",
    title: "Ministry of Youth Development (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_women_affairs_exp",
    title: "Ministry of Women Affairs (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_education_exp",
    title: "Ministry of Education (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_health_exp",
    title: "Ministry of Health (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_environment_exp",
    title: "Ministry of Environment (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "ministry_sports_exp",
    title: "Ministry of Sport Development (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  {
    name: "minsitry_lga_exp",
    title: "Ministry of Local Government (% of Total Expenditure)",
    category: "expenditure_mda_percentage_total_expenditure",
  },
  // //////
  {
    name: "admin_sector_rev",
    title: " Administration Sector (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    title: "Governor's office (% of Total Revenue)",
    name: "governors_office_rev",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "secretary_to_state_gov_rev",
    title: "  Secretary to the State Government (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "state_assembly_rev",
    title: "State Assembly (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_of_info_rev",
    title: "Ministry of Information (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_of_internal_security_home_affairs_rev",
    title:
      "Ministry of Internal Security and Home Affairs (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "office_head_of_service_rev",
    title: "Office of the Head of Service (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "office_auditor_general_state_rev",
    title: "Office of the Auditor General State (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "office_auditor_general_lga_rev",
    title:
      "Office of the Auditor General Local Government (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "civil_service_commission_rev",
    title: "Civil Service Commission (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "lga_service_commission_rev",
    title: "  Local Government Service Commission (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "state_inec_rev",
    title: "  State Independent Electoral Commission (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "economic_sector_rev",
    title: "  Economic Sector (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_of_agric_natural_resources_rev",
    title: "Ministry of Agriculture & Natural Resources (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_finance_rev",
    title: "Ministry of Finance (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_commerce_trade_business_industry_investment_rev",
    title:
      " Ministry of Commerce/Trade/Business/Industry/Investment (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_labour_productivity_rev",
    title: "Ministry of Labour and Productivity (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_science_tech_rev",
    title: " Ministry of Science and Technology (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_transport_rev",
    title: "Ministry of Transport (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_energy_rev",
    title: "Ministry of Energy (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_works_rev",
    title: "Ministry of Works (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    title: "   Ministry of Culture and Tourism (% of Total Revenue)",
    name: "ministry_culture_tourism_rev",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "state_planning_commission_rev",
    title:
      "State Planning Commission/Ministry of Budget and Economic Planning(% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "fiscal_responsibility_commission_rev",
    title: "   Fiscal Responsibility Commission (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_water_resources_rev",
    title: "Ministry of Water Resources (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_housing_urban_development_rev",
    title: "   Ministry of Housing and Urban Development (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_land_survey_rev",
    title: "Ministry of Land and Survey (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "law_justuce_sector_rev",
    title: "  Law and Justice Sector (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "judiciary_rev",
    title: "Judiciary (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_justice_rev",
    title: "Ministry of Justice (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "regional_sector_rev",
    title: "     Regional Sector (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "oil_producing_communities_dev_agency_rev",
    title:
      "Oil Producing Communities Development Agency/Commission (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "state_capital_dev_agency_rev",
    title: "  State Capital Development Agency/Ministry (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "social_sector_rev",
    title: "Social Sector (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_youth_development_rev",
    title: "Ministry of Youth Development (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_women_affairs_rev",
    title: "Ministry of Women Affairs (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_education_rev",
    title: "Ministry of Education (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_health_rev",
    title: "Ministry of Health (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_environment_rev",
    title: "Ministry of Environment (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "ministry_sports_rev",
    title: "Ministry of Sport Development (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    name: "minsitry_lga_rev",
    title: "Ministry of Local Government (% of Total Revenue)",
    category: "expenditure_MDA_percentage_total_revenue",
  },
  {
    title: "Administration Sector Total Expenditure",
    name: "admin_sector_total_expenditure",
    category: "expenditure_by_sector",
  },
  {
    title: "Economic Sector Total Expenditure",
    name: "economic_sector_total_expenditure",
    category: "expenditure_by_sector",
  },
  {
    name: "law_justice_sector_total_expenditure",
    title: "Law and Justice Sector Total Expenditure",
    category: "expenditure_by_sector",
  },
  {
    name: "regional_sector_total_expenditure",
    title: "Regional Sector Total Expenditure",
    category: "expenditure_by_sector",
  },
  {
    name: "social_sector_sector_total_expenditure",
    title: "Social Sector Total Expenditure",
    category: "expenditure_by_sector",
  },
  //
  {
    name: "admin_sector_total_expenditure_exp",
    title: "Administration Sector Expenditure (% of Total Expenditure)",
    category: "expenditure_by_sector_percentage_total_expenditure",
  },
  {
    name: "economic_sector_total_expenditure_exp",
    title: "Economic Sector Expenditure (% of Total Expenditure)",
    category: "expenditure_by_sector_percentage_total_expenditure",
  },
  {
    name: "law_justice_sector_total_expenditure_exp",
    title: "Law and Justice Sector Expenditure (% of Total Expenditure)",
    category: "expenditure_by_sector_percentage_total_expenditure",
  },
  {
    name: "regional_sector_total_expenditure_exp",
    title: "Regional Sector Expenditure (% of Total Expenditure)",
    category: "expenditure_by_sector_percentage_total_expenditure",
  },
  {
    name: "social_sector_sector_total_expenditure_exp",
    title: "Social Sector Expenditure (% of Total Expenditure)",
    category: "expenditure_by_sector_percentage_total_expenditure",
  },
  //

  {
    name: "admin_sector_total_expenditure_rev",
    title: "Administration Sector Expenditure (% of Total Revenue)",
    category: "expenditure_by_sector_percentage_total_revenue",
  },
  {
    name: "economic_sector_total_expenditure_rev",
    title: "Economic Sector Expenditure (% of Total Revenue)",
    category: "expenditure_by_sector_percentage_total_revenue",
  },
  {
    name: "law_justice_sector_total_expenditure_rev",
    title: "Law and Justice Sector Expenditure (% of Total Revenue)",
    category: "expenditure_by_sector_percentage_total_revenue",
  },
  {
    name: "regional_sector_total_expenditure_rev",
    title: "Regional Sector Expenditure (% of Total Revenue)",
    category: "expenditure_by_sector_percentage_total_revenue",
  },
  {
    name: "social_sector_sector_total_expenditure_rev",
    title: "Social Sector Expenditure (% of Total Revenue)",
    category: "expenditure_by_sector_percentage_total_revenue",
  },
  {
    name: "general_public_service_total_expenditure",
    title: "General Public Service Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "public_order_safety_total_expenditure",
    title: "Public Order and Safety Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "economic_affairs_total_expenditure",
    title: "Economic Affairs Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "environment_protection_total_expenditure",
    title: "Environmental Protection Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "housing_community_affairs_total_expenditure",
    title: "Housing and Community Affairs Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "health_total_expenditure",
    title: "Health Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "recreation_total_expenditure",
    title: "Recreation and Culture Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "education_total_expenditure",
    title: "Education Total Expenditure",
    category: "expenditure_by_function",
  },
  {
    name: "social_protection_total_expenditure",
    title: "Social Protection Total Expenditure",
    category: "expenditure_by_function",
  },
  // ////
  {
    name: "general_public_service_total_expenditure_exp",
    title: "General Public Service Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "public_order_safety_total_expenditure_exp",
    title: "Public Order and Safety Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "economic_affairs_total_expenditure_exp",
    title: "Economic Affairs Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "environment_protection_total_expenditure_exp",
    title: "Environmental Protection Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "housing_community_affairs_total_expenditure_exp",
    title: "Housing and Community Affairs Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "health_total_expenditure_exp",
    title: "Health Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "recreation_total_expenditure_exp",
    title: "Recreation and Culture Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "education_total_expenditure_exp",
    title: "Education Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },
  {
    name: "social_protection_total_expenditure_exp",
    title: "Social Protection Expenditure (% of Total Expenditure)",
    category: "expenditure_by_function_percentage_total_expenditure",
  },

  // ///
  {
    name: "general_public_service_total_expenditure_rev",
    title: "General Public Service Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "public_order_safety_total_expenditure_rev",
    title: "Public Order and Safety Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "economic_affairs_total_expenditure_rev",
    title: "Economic Affairs Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "environment_protection_total_expenditure_rev",
    title: "Environmental Protection Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "housing_community_affairs_total_expenditure_rev",
    title: "Housing and Community Affairs Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "health_total_expenditure_rev",
    title: "Health Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "recreation_total_expenditure_rev",
    title: "Recreation and Culture Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "education_total_expenditure_rev",
    title: "Education Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
  {
    name: "social_protection_total_expenditure_rev",
    title: "Social Protection Expenditure (% of Total Revenue)",
    category: "expenditure_by_function_percentage_total_revenue",
  },
];

// const another = [];

// const newTitles = another?.map((item) => ({
//   [item?.name]: item?.title,
// }));

const get_second_category = (category) => {
  return second_category?.filter((item) => item?.first_category === category);
};

const get_third_category = (category1, category2) => {
  return third_category?.filter(
    (item) =>
      item?.second_category === category1 && item?.first_category === category2
  );
};
const get_forth_category = (category) => {
  return forth_category?.filter((item) => item?.third_category === category);
};

const select_all_cateogries = (category) => {
  const all_categories = [
    ...first_category,
    ...second_category,
    ...third_category,
    ...forth_category,
  ]
    .filter(
      (item) => item?.name === category || item?.first_category === category
    )
    .map((item) => item?.name);
  return all_categories;
};

const select_from_second_categoies = (category) => {
  const all_categories = [
    ...second_category,
    ...third_category,
    ...forth_category,
  ]
    .filter(
      (item) => item?.name === category || item?.second_category === category
    )
    .map((item) => item?.name);
  return all_categories;
};

const select_from_third_categoies = (category) => {
  const all_categories = [...third_category, ...forth_category]
    .filter(
      (item) => item?.name === category || item?.third_category === category
    )
    .map((item) => item?.name);
  return all_categories;
};

const select_all = () => {
  const all_categories = [
    ...first_category,
    ...second_category,
    ...third_category,
    ...forth_category,
  ]?.map((item) => item?.name);
  return all_categories;
};

const query = (q) => {
  const all_categories = [
    ...first_category,
    ...second_category,
    ...third_category,
    ...forth_category,
  ]
    ?.filter((item) =>
      item?.title?.trim().toLowerCase()?.includes(q?.trim()?.toLowerCase())
    )
    .slice(0, 10);
  return all_categories;
};

const selectAllPiData = () => {
  return piData?.map((item) => item?.name);
};

const queryPiData = (q) => {
  piData?.filter((item) =>
    item?.title?.trim().toLowerCase()?.includes(q?.trim()?.toLowerCase())
  );
};

const get_pi_category = (category) => {
  return piRankSecondCategories?.filter((item) => item?.category === category);
};

export {
  first_category,
  second_category,
  third_category,
  forth_category,
  get_second_category,
  get_third_category,
  get_forth_category,
  select_all_cateogries,
  select_from_second_categoies,
  select_from_third_categoies,
  select_all,
  query,
  piData,
  piRankData,
  selectAllPiData,
  queryPiData,
  get_pi_category,
};
