import React, { useEffect } from "react";
import Footer from "./footer/Footer";
import Topbar from "./header/Topbar";

const Layout = ({ children }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      {/* <Topbar /> */}
      {children}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
