import React from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../styles/styles.module.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

function capital_letter(str) {
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] =
      str[i].length > 1 ? str[i][0].toUpperCase() + str[i].substr(1) : str[i];
  }

  return str.join(" ");
}

function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e12
    ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
    : Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

const categoriesTitles = {
  revenues_percantage_total_revenue: "Revenues(% of Total Revenue)",
  revenues_percantage_total_expenditure: "Revenues (% of Total Expenditure)",
  expenditures_percentage_total_revenue: "Expenditures(% of Total Revenue)",
  expenditures_percentage_total_expenditure:
    "Expenditures(% of Total Expenditure)",
  expenditure: "Expenditure",
  expenditureMDA: "Expenditure by MDA",
  expenditure_mda_percentage_total_expenditure:
    "Expenditure by MDA(% of Total Expenditure)",
  expenditure_MDA_percentage_total_revenue:
    "Expenditure by MDA(% of Total Revenue)",
  expenditure_by_sector: "Expenditure by Sector",
  expenditure_by_sector_percentage_total_expenditure:
    "Expenditure by Sector(% of Total Expenditure)",
  expenditure_by_sector_percentage_total_revenue:
    "Expenditure by Sector(% of Total Revenue)",
  expenditure_by_function_percentage_total_expenditure:
    "Expenditure by Function(% of Total Expenditure)",
  expenditure_by_function_percentage_total_revenue:
    "Expenditure by Function (% of Total Revenue)",
  budget_performance: "Budget Performance (%)",
  total_revenue_performance_excluding_opening_balance:
    "Total Revenue Performance (exluding opening balance) (%)",
  recurrent_revenue_performance: "Recurrent Revenue Performance (%)",
  federation_revenue_performance: "Federation Revenue Performance (%)",
  igr_performance: "IGR Performance (%)",
  aids_grant_performance: "Aids and Grants Performance (%)",
  capital_reciepts_cdf_performance:
    "  Capital Receipts (Capital Development Fund) Performance (%)",
  personnel_expenditure_performance: "Personnel Expenditure Performance (%)",
  other_recurrent_expenditure_performance:
    "Other Recurrent Expenditure Performance (%)",
  capital_expenditure_performance: "Capital Expenditure Performance (%)",
  total_expenditure_performance: "Total Expenditure Performance (%)",
  // First Two
  aids_grants: "Aids and Grants (% of Total Revenue)",
  aids_grants_exp: "Aids and Grants (% of Total Expenditure)",
  allowances: "Allowances (% of Total Revenue)",
  allowances_exp: "Allowances (% of Total Expenditure)",
  capital_development_fund:
    "Capital Development Fund (CDF) Receipts (% of Total Revenue)",
  capital_development_fund_exp:
    "Capital Development Fund (CDF) Receipts (% of Total Expenditure)",
  capital_gain_tax: " Capital Gain Taxes (% of Total Revenue) ",
  capital_gain_tax_exp: " Capital Gain Taxes (% of Total Expenditure) ",
  consolidated_revenue_charges:
    "Consolidated Revenue Charges (% of Total Revenue)",
  consolidated_revenue_charges_exp:
    "Consolidated Revenue Charges (% of Total Expenditure)",
  contributory_pension: "Contributory Pension (% of Total Revenue)",
  contributory_pension_exp: "Contributory Pension (% of Total Expenditure)",
  death_benefit: "Death Benefit (% of Total Revenue)",
  death_benefit_exp: "Death Benefit (% of Total Expenditure)",
  derivation: "Derivation (% of Total Revenue)",
  derivation_exp: "Derivation (% of Total Expenditure)",
  domestic_aids: "Domestic Aids (% of Total Revenue)",
  domestic_aids_exp: "Domestic Aids (% of Total Expenditure)",
  domestic_debt_foregiveness: "Domestic Debt Forgiveness (% of Total Revenue)",
  domestic_debt_foregiveness_exp:
    "Domestic Debt Forgiveness (% of Total Expenditure)",
  domestic_grants: "Domestic Grants (% of Total Revenue)",
  domestic_grants_exp: "Domestic Grants (% of Total Expenditure)",
  domestic_loans_from_financial_instituitions:
    " Domestic Loans/ Borrowings From Financial Institutions (% of Total Revenue)",
  domestic_loans_from_financial_instituitions_exp:
    " Domestic Loans/ Borrowings From Financial Institutions (% of Total Expenditure)",
  domestic_loans_from_other_entities:
    "  Domestic Loans/ Borrowings From Other Entities/ Organisations (% of Total Revenue)",
  domestic_loans_from_other_entities_exp:
    "  Domestic Loans/ Borrowings From Other Entities/ Organisations (% of Total Expenditure)",
  domestic_loans_from_other_government_entities:
    "  Domestic Loans/ Borrowings From Other Government Entities (% of Total Revenue)",
  domestic_loans_from_other_government_entities_exp:
    "  Domestic Loans/ Borrowings From Other Government Entities (% of Total Expenditure)",
  earnings_general: "  Earnings – General (% of Total Revenue) ",
  earnings_general_exp: "  Earnings – General (% of Total Expenditure) ",
  employee_comepensation_fund:
    "Employee Compensation Fund (% of Total Revenue)",
  employee_comepensation_fund_exp:
    "Employee Compensation Fund (% of Total Expenditure)",
  extraordinary_items: "Extraordinary Items (% of Total Revenue)",
  extraordinary_items_exp: "Extraordinary Items (% of Total Expenditure)",
  federation_revenue: "Federation Revenue (% of Total Revenue)",
  federation_revenue_exp: "Federation Revenue (% of Total Expenditure)",
  fees_general: " Fees – General (% of Total Revenue) ",
  fees_general_exp: " Fees – General (% of Total Expenditure) ",
  fines_general: " Fines – General (% of Total Revenue) ",
  fines_general_exp: " Fines – General (% of Total Expenditure) ",
  foreign_aids: "Foreign Aids (% of Total Revenue)",
  foreign_aids_exp: "Foreign Aids (% of Total Expenditure)",
  foreign_debt_forgiveness: "Foreign Debt Forgiveness (% of Total Revenue)",
  foreign_debt_forgiveness_exp:
    "Foreign Debt Forgiveness (% of Total Expenditure)",
  foreign_grants: "Foreign Grants (% of Total Revenue)",
  foreign_grants_exp: "Foreign Grants (% of Total Expenditure)",
  gain_on_disposal_asset_investment_property:
    "   Gain On Disposal Of Asset - Investment Property (% of Total Revenue)",
  gain_on_disposal_asset_investment_property_exp:
    "   Gain On Disposal Of Asset - Investment Property (% of Total Expenditure)",
  gain_on_disposal_asset_ppe:
    "Gain On Disposal Of Asset – PPE (% of Total Revenue)",
  gain_on_disposal_asset_ppe_exp:
    "Gain On Disposal Of Asset – PPE (% of Total Expenditure)",
  gratuity: "Gratuity (% of Total Revenue)",
  gratuity_exp: "Gratuity (% of Total Expenditure)",
  group_life_insurance: "Group Life Insurance (% of Total Revenue)",
  group_life_insurance_exp: "Group Life Insurance (% of Total Expenditure)",
  housing_fund_contribution: "Housing Fund Contribution (% of Total Revenue)",
  housing_fund_contribution_exp:
    "Housing Fund Contribution (% of Total Expenditure)",
  igr: "Internally Generated Revenue (% of Total Revenue)",
  igr_exp: "Internally Generated Revenue (% of Total Expenditure)",
  interest_earned: "Interest Earned (% of Total Revenue)",
  interest_earned_exp: "Interest Earned (% of Total Expenditure)",
  internaitional_loans_from_other_entities:
    "International Loans/ Borrowings From Other Entities/ Organisations (% of Total Revenue)",
  internaitional_loans_from_other_entities_exp:
    "International Loans/ Borrowings From Other Entities/ Organisations (% of Total Expenditure)",
  internaitional_loans_from_other_government_entities:
    " International Loans/ Borrowings From Other Government Entities (% of Total Revenue)",
  internaitional_loans_from_other_government_entities_exp:
    " International Loans/ Borrowings From Other Government Entities (% of Total Expenditure)",
  international_loans_from_financial_instituitions:
    "International Loans/ Borrowings From Financial Institutions (% of Total Revenue)",
  international_loans_from_financial_instituitions_exp:
    "International Loans/ Borrowings From Financial Institutions (% of Total Expenditure)",
  investment_income: "Investment Income (% of Total Revenue) ",
  investment_income_exp: "Investment Income (% of Total Expenditure) ",
  licances_general: "Licences General (% of Total Revenue) ",
  licances_general_exp: "Licences General (% of Total Expenditure) ",
  lottery_tax: " Lottery Tax/Licence (% of Total Revenue)",
  lottery_tax_exp: " Lottery Tax/Licence (% of Total Expenditure)",
  minority_interest_share_of_surplus:
    "Minority Interest Share Of Surplus (% of Total Revenue)",
  minority_interest_share_of_surplus_exp:
    "Minority Interest Share Of Surplus (% of Total Expenditure)",
  miscellaneous_income: "Miscellaneous Income (% of Total Revenue)",
  miscellaneous_income_exp: "Miscellaneous Income (% of Total Expenditure)",
  nhis_contribution: "NHIS Contribution (% of Total Revenue)",
  nhis_contribution_exp: "NHIS Contribution (% of Total Expenditure)",
  non_tax_revenue: "Non-Tax Revenue (% of Total Revenue)",
  non_tax_revenue_exp: "Non-Tax Revenue (% of Total Expenditure)",
  other_federation_transfers: "Other Federation Transfers (% of Total Revenue)",
  other_federation_transfers_exp:
    "Other Federation Transfers (% of Total Expenditure)",
  other_personal_tax_nec: "Other Personal Tax N.E.C (% of Total Revenue)",
  other_personal_tax_nec_exp:
    "Other Personal Tax N.E.C (% of Total Expenditure)",
  other_recurrent_expenditure:
    "Other Recurrent Expenditure (% of Total Revenue)",
  other_recurrent_expenditure_exp:
    "Other Recurrent Expenditure (% of Total Expenditure)",
  other_taxes: "Other Taxes (% of Total Revenue)",
  other_taxes_exp: "Other Taxes (% of Total Expenditure)",
  other_taxes_nec: "Other Taxes N.E.C (% of Total Revenue)",
  other_taxes_nec_exp: "Other Taxes N.E.C (% of Total Expenditure)",
  ovetimes_payments: "Overtime Payments (% of Total Revenue)",
  ovetimes_payments_exp: "Overtime Payments (% of Total Expenditure)",
  payment_benefit_to_past_governors_deputies:
    "Payment of Benefits to past Governors/Deputies (% of Total Revenue)",
  payment_benefit_to_past_governors_deputies_exp:
    "Payment of Benefits to past Governors/Deputies (% of Total Expenditure)",
  pension: "Pension (% of Total Revenue)",
  pension_exp: "Pension (% of Total Expenditure)",
  personal_cost: "Personnel Cost (% of Total Revenue)",
  personal_expenditure: "Personnel Expenditure (% of Total Revenue)",
  personal_income_tax_dat:
    "  Personnal Income Tax (Direct Assessment Taxes) (% of Total Revenue)",
  personal_income_tax_dat_exp:
    "  Personnal Income Tax (Direct Assessment Taxes) (% of Total Expenditure)",
  personal_income_tax_paye: "Personal Income Tax (PAYE) (% of Total Revenue)",
  personal_income_tax_paye_exp:
    "Personal Income Tax (PAYE) (% of Total Expenditure)",
  personal_taxes: "Personal Taxes (% of Total Revenue)",
  personal_taxes_exp: "Personal Taxes (% of Total Expenditure)",
  property_tax: "Property Tax (% of Total Revenue) ",
  property_tax_exp: "Property Tax (% of Total Expenditure) ",
  recurrent_revenue: "Recurrent Revenue (% of Total Revenue)",
  recurrent_revenue_exp: "Recurrent Revenue (% of Total Expenditure)",
  reimbursement: " Reimbursement (% of Total Revenue) ",
  reimbursement_exp: " Reimbursement (% of Total Expenditure) ",
  rent_on_government_building_general:
    "Rent On Government Buildings – General (% of Total Revenue)",
  rent_on_government_building_general_exp:
    "Rent On Government Buildings – General (% of Total Expenditure)",
  repayments: "Repayments (% of Total Revenue) ",
  repayments_exp: "Repayments (% of Total Expenditure) ",
  salaries: "Salaries (% of Total Revenue)",
  salaries_exp: "Salaries (% of Total Expenditure)",
  salaries_wages: "Salaries and Wages – General (% of Total Revenue)",
  salaries_wages_exp: "Salaries and Wages – General (% of Total Expenditure)",
  sales_general: "Sales – General (% of Total Revenue)",
  sales_general_exp: "Sales – General (% of Total Expenditure)",
  sales_of_fixed_assets: "Sale Of Fixed Assets (% of Total Revenue)",
  sales_of_fixed_assets_exp: "Sale Of Fixed Assets (% of Total Expenditure)",
  sales_taxes: "Sales Tax (% of Total Revenue)",
  sales_taxes_exp: "Sales Tax (% of Total Expenditure)",
  satutory_allocation: "Statutory Allocation (% of Total Revenue)",
  satutory_allocation_exp: "Statutory Allocation (% of Total Expenditure)",
  social_benefit: "Social Benefit (% of Total Revenue)",
  social_benefit_exp: "Social Benefit (% of Total Expenditure)",
  social_contribution: "Social Contribution (% of Total Revenue)",
  social_contribution_exp: "Social Contribution (% of Total Expenditure)",
  tax_revenue: "Tax Revenue (% of Total Revenue)",
  tax_revenue_exp: "Tax Revenue (% of Total Expenditure)",
  unspecified_revenue: "Unspecified Revenue (% of Total Revenue)",
  unspecified_revenue_exp: "Unspecified Revenue (% of Total Expenditure)",
  vat: "Value Added Tax (% of Total Revenue)",
  vat_exp: "Value Added Tax (% of Total Expenditure)",
  withholding_tax: "Withholding Tax (% of Total Revenue)",
  withholding_tax_exp: "Withholding Tax (% of Total Expenditure)",

  // Three / four

  admin_sector: "Administration Sector (Total Expenditure)",
  capital_expenditure: "Capital Expenditure",
  capital_expenditure_total_expenditure:
    "Capital Expenditure (% of Total Expenditure)",
  capital_expenditure_total_revenue: "Capital Expenditure (% of Total Revenue)",
  economic_sector: "Economic Sector (Total Expenditure)",
  fiscal_responsibility_commission:
    "Fiscal Responsibility Commission (Total Expenditure)",
  governors_office: "Governor’s Office (Total Expenditure)",
  judiciary: "Judiciary (Total Expenditure)",
  law_justuce_sector: "Law and Justice Sector (Total Expenditure)",
  lga_service_commission:
    "Local Government Service Commission (Total Expenditure)",
  ministry_commerce_trade_business_industry_investment:
    "Ministry of Commerce/Trade/Business/Industry/Investment (Total Expenditure)",
  ministry_culture_tourism:
    "Ministry of Culture and Tourism (Total Expenditure)",
  ministry_education: "Ministry of Education (Total Expenditure)",
  ministry_energy: "Ministry of Energy (Total Expenditure)",
  ministry_environment: "Ministry of Environment (Total Expenditure)",
  ministry_finance: "Ministry of Finance (Total Expenditure)",
  ministry_health: "Ministry of Health (Total Expenditure)",
  ministry_housing_urban_development:
    "Ministry of Housing and Urban Development (Total Expenditure)",
  ministry_justice: "Ministry of Justice (Total Expenditure)",
  ministry_labour_productivity:
    "Ministry of Labour and Productivity (Total Expenditure)",
  ministry_land_survey: "Ministry of Land and Survey (Total Expenditure)",
  ministry_of_agric_natural_resources:
    "Ministry of Agriculture & Natural Resources (Total Expenditure)",
  ministry_of_info: "Ministry of Information (Total Expenditure)",
  ministry_of_internal_security_home_affairs:
    "Ministry of Internal Security and Home Affairs (Total Expenditure)",
  ministry_science_tech:
    "Ministry of Science and Technology (Total Expenditure)",
  ministry_sports: "Ministry of Sport Development (Total Expenditure)",
  ministry_transport: "Ministry of Transport (Total Expenditure)",
  ministry_water_resources: "Ministry of Water Resources (Total Expenditure)",
  ministry_women_affairs: "Ministry of Women Affairs (Total Expenditure)",
  ministry_works: "Ministry of Works (Total Expenditure)",
  ministry_youth_development:
    "Ministry of Youth Development (Total Expenditure)",
  minsitry_lga: "Ministry of Local Government (Total Expenditure)",
  office_auditor_general_lga: "Civil Service Commission (Total Expenditure)",
  office_auditor_general_state:
    "Office of the Auditor General State (Total Expenditure)",
  office_head_of_service: "Office of the Head of Service (Total Expenditure)",
  oil_producing_communities_dev_agency:
    "Oil Producing Communities Development Agency/Commission (Total Expenditure)",
  recurrent_expenditure: "Recurrent Expenditure",
  recurrent_expenditure_total_expenditure:
    "Recurrent Expenditure (% of Total Expenditure)",
  recurrent_expenditure_total_revenue:
    "Recurrent Expenditure (% of Total Revenue)",
  recurrent_revenue_total_expenditure:
    "Recurrent Revenue (% of Total Expenditure)",
  regional_sector: "Regional Sector (Total Expenditure)",
  secretary_to_state_gov:
    "Secretary to the State Government (Total Expenditure)",
  social_sector: "Social Sector (Total Expenditure)",
  state_assembly: "State Assembly (Total Expenditure)",
  state_capital_dev_agency:
    "State Capital Development Agency/Ministry (Total Expenditure)",
  state_inec: "State Independent Electoral Commission (Total Expenditure)",
  state_planning_commission:
    "State Planning Commission/Ministry of Budget and Economic Planning (Total Expenditure)",
  total_expenditure: "Total Expenditure",
  // //////
  admin_sector_exp: " Administration Sector (% of Total Expenditure)",
  admin_sector_rev: " Administration Sector (% of Total Revenue)",
  civil_service_commission_exp:
    "Civil Service Commission (% of Total Expenditure)",
  civil_service_commission_rev: "Civil Service Commission (% of Total Revenue)",
  economic_sector_exp: "  Economic Sector (% of Total Expenditure)",
  economic_sector_rev: "  Economic Sector (% of Total Revenue)",
  fiscal_responsibility_commission_exp:
    "   Fiscal Responsibility Commission (% of Total Expenditure)",
  fiscal_responsibility_commission_rev:
    "   Fiscal Responsibility Commission (% of Total Revenue)",
  governors_office_exp: "Governor's office (% of Total Expenditure)",
  governors_office_rev: "Governor's office (% of Total Revenue)",
  judiciary_exp: "Judiciary (% of Total Expenditure)",
  judiciary_rev: "Judiciary (% of Total Revenue)",
  law_justuce_sector_exp: "  Law and Justice Sector (% of Total Expenditure)",
  law_justuce_sector_rev: "  Law and Justice Sector (% of Total Revenue)",
  lga_service_commission_exp:
    "  Local Government Service Commission (% of Total Expenditure)",
  lga_service_commission_rev:
    "  Local Government Service Commission (% of Total Revenue)",
  ministry_commerce_trade_business_industry_investment_exp:
    " Ministry of Commerce/Trade/Business/Industry/Investment (% of Total Expenditure)",
  ministry_commerce_trade_business_industry_investment_rev:
    " Ministry of Commerce/Trade/Business/Industry/Investment (% of Total Revenue)",
  ministry_culture_tourism_exp:
    "   Ministry of Culture and Tourism (% of Total Expenditure)",
  ministry_culture_tourism_rev:
    "   Ministry of Culture and Tourism (% of Total Revenue)",
  ministry_education_exp: "Ministry of Education (% of Total Expenditure)",
  ministry_education_rev: "Ministry of Education (% of Total Revenue)",
  ministry_energy_exp: "Ministry of Energy (% of Total Expenditure)",
  ministry_energy_rev: "Ministry of Energy (% of Total Revenue)",
  ministry_environment_exp: "Ministry of Environment (% of Total Expenditure)",
  ministry_environment_rev: "Ministry of Environment (% of Total Revenue)",
  ministry_finance_exp: "Ministry of Finance (% of Total Expenditure)",
  ministry_finance_rev: "Ministry of Finance (% of Total Revenue)",
  ministry_health_exp: "Ministry of Health (% of Total Expenditure)",
  ministry_health_rev: "Ministry of Health (% of Total Revenue)",
  ministry_housing_urban_development_exp:
    "   Ministry of Housing and Urban Development (% of Total Expenditure)",
  ministry_housing_urban_development_rev:
    "   Ministry of Housing and Urban Development (% of Total Revenue)",
  ministry_justice_exp: "Ministry of Justice (% of Total Expenditure)",
  ministry_justice_rev: "Ministry of Justice (% of Total Revenue)",
  ministry_labour_productivity_exp:
    "Ministry of Labour and Productivity (% of Total Expenditure)",
  ministry_labour_productivity_rev:
    "Ministry of Labour and Productivity (% of Total Revenue)",
  ministry_land_survey_exp:
    "Ministry of Land and Survey (% of Total Expenditure)",
  ministry_land_survey_rev: "Ministry of Land and Survey (% of Total Revenue)",
  ministry_of_agric_natural_resources_exp:
    "Ministry of Agriculture & Natural Resources (% of Total Expenditure)",
  ministry_of_agric_natural_resources_rev:
    "Ministry of Agriculture & Natural Resources (% of Total Revenue)",
  ministry_of_info_exp: "Ministry of Information (% of Total Expenditure)",
  ministry_of_info_rev: "Ministry of Information (% of Total Revenue)",
  ministry_of_internal_security_home_affairs_exp:
    "Ministry of Internal Security and Home Affairs (% of Total Expenditure)",
  ministry_of_internal_security_home_affairs_rev:
    "Ministry of Internal Security and Home Affairs (% of Total Revenue)",
  ministry_science_tech_exp:
    " Ministry of Science and Technology (% of Total Expenditure)",
  ministry_science_tech_rev:
    " Ministry of Science and Technology (% of Total Revenue)",
  ministry_sports_exp: "Ministry of Sport Development (% of Total Expenditure)",
  ministry_sports_rev: "Ministry of Sport Development (% of Total Revenue)",
  ministry_transport_exp: "Ministry of Transport (% of Total Expenditure)",
  ministry_transport_rev: "Ministry of Transport (% of Total Revenue)",
  ministry_water_resources_exp:
    "Ministry of Water Resources (% of Total Expenditure)",
  ministry_water_resources_rev:
    "Ministry of Water Resources (% of Total Revenue)",
  ministry_women_affairs_exp:
    "Ministry of Women Affairs (% of Total Expenditure)",
  ministry_women_affairs_rev: "Ministry of Women Affairs (% of Total Revenue)",
  ministry_works_exp: "Ministry of Works (% of Total Expenditure)",
  ministry_works_rev: "Ministry of Works (% of Total Revenue)",
  ministry_youth_development_exp:
    "Ministry of Youth Development (% of Total Expenditure)",
  ministry_youth_development_rev:
    "Ministry of Youth Development (% of Total Revenue)",
  minsitry_lga_exp: "Ministry of Local Government (% of Total Expenditure)",
  minsitry_lga_rev: "Ministry of Local Government (% of Total Revenue)",
  office_auditor_general_lga_exp:
    "Office of the Auditor General Local Government (% of Total Expenditure)",
  office_auditor_general_lga_rev:
    "Office of the Auditor General Local Government (% of Total Revenue)",
  office_auditor_general_state_exp:
    "Office of the Auditor General State (% of Total Expenditure)",
  office_auditor_general_state_rev:
    "Office of the Auditor General State (% of Total Revenue)",
  office_head_of_service_exp:
    "Office of the Head of Service (% of Total Expenditure)",
  office_head_of_service_rev:
    "Office of the Head of Service (% of Total Revenue)",
  oil_producing_communities_dev_agency_exp:
    "Oil Producing Communities Development Agency/Commission (% of Total Expenditure)",
  oil_producing_communities_dev_agency_rev:
    "Oil Producing Communities Development Agency/Commission (% of Total Revenue)",
  regional_sector_exp: "     Regional Sector (% of Total Expenditure)",
  regional_sector_rev: "     Regional Sector (% of Total Revenue)",
  secretary_to_state_gov_exp:
    "  Secretary to the State Government (% of Total Expenditure)",
  secretary_to_state_gov_rev:
    "  Secretary to the State Government (% of Total Revenue)",
  social_sector_exp: "Social Sector (% of Total Expenditure)",
  social_sector_rev: "Social Sector (% of Total Revenue)",
  state_assembly_exp: "State Assembly (% of Total Expenditure)",
  state_assembly_rev: "State Assembly (% of Total Revenue)",
  state_capital_dev_agency_exp:
    "  State Capital Development Agency/Ministry (% of Total Expenditure)",
  state_capital_dev_agency_rev:
    "  State Capital Development Agency/Ministry (% of Total Revenue)",
  state_inec_exp:
    "  State Independent Electoral Commission (% of Total Expenditure)",
  state_inec_rev:
    "  State Independent Electoral Commission (% of Total Revenue)",
  state_planning_commission_exp:
    "State Planning Commission/Ministry of Budget and Economic Planning(% of Total Expenditure)",
  state_planning_commission_rev:
    "State Planning Commission/Ministry of Budget and Economic Planning(% of Total Revenue)",
  // //////
  admin_sector_total_expenditure: "Administration Sector Total Expenditure",
  admin_sector_total_expenditure_exp:
    "Administration Sector Expenditure (% of Total Expenditure)",
  admin_sector_total_expenditure_rev:
    "Administration Sector Expenditure (% of Total Revenue)",
  economic_sector_total_expenditure: "Economic Sector Total Expenditure",
  economic_sector_total_expenditure_exp:
    "Economic Sector Expenditure (% of Total Expenditure)",
  economic_sector_total_expenditure_rev:
    "Economic Sector Expenditure (% of Total Revenue)",
  law_justice_sector_total_expenditure:
    "Law and Justice Sector Total Expenditure",
  law_justice_sector_total_expenditure_exp:
    "Law and Justice Sector Expenditure (% of Total Expenditure)",
  law_justice_sector_total_expenditure_rev:
    "Law and Justice Sector Expenditure (% of Total Revenue)",
  regional_sector_total_expenditure: "Regional Sector Total Expenditure",
  regional_sector_total_expenditure_exp:
    "Regional Sector Expenditure (% of Total Expenditure)",
  regional_sector_total_expenditure_rev:
    "Regional Sector Expenditure (% of Total Revenue)",
  social_sector_sector_total_expenditure: "Social Sector Total Expenditure",
  social_sector_sector_total_expenditure_exp:
    "Social Sector Expenditure (% of Total Expenditure)",
  social_sector_sector_total_expenditure_rev:
    "Social Sector Expenditure (% of Total Revenue)",

  economic_affairs_total_expenditure: "Economic Affairs Total Expenditure",
  economic_affairs_total_expenditure_exp:
    "Economic Affairs Expenditure (% of Total Expenditure)",
  economic_affairs_total_expenditure_rev:
    "Economic Affairs Expenditure (% of Total Revenue)",
  education_total_expenditure: "Education Total Expenditure",
  education_total_expenditure_exp:
    "Education Expenditure (% of Total Expenditure)",
  education_total_expenditure_rev: "Education Expenditure (% of Total Revenue)",
  environment_protection_total_expenditure:
    "Environmental Protection Total Expenditure",
  environment_protection_total_expenditure_exp:
    "Environmental Protection Expenditure (% of Total Expenditure)",
  environment_protection_total_expenditure_rev:
    "Environmental Protection Expenditure (% of Total Revenue)",
  general_public_service_total_expenditure:
    "General Public Service Total Expenditure",
  general_public_service_total_expenditure_exp:
    "General Public Service Expenditure (% of Total Expenditure)",
  general_public_service_total_expenditure_rev:
    "General Public Service Expenditure (% of Total Revenue)",
  health_total_expenditure: "Health Total Expenditure",
  health_total_expenditure_exp: "Health Expenditure (% of Total Expenditure)",
  health_total_expenditure_rev: "Health Expenditure (% of Total Revenue)",
  housing_community_affairs_total_expenditure:
    "Housing and Community Affairs Total Expenditure",
  housing_community_affairs_total_expenditure_exp:
    "Housing and Community Affairs Expenditure (% of Total Expenditure)",
  housing_community_affairs_total_expenditure_rev:
    "Housing and Community Affairs Expenditure (% of Total Revenue)",
  public_order_safety_total_expenditure:
    "Public Order and Safety Total Expenditure",
  public_order_safety_total_expenditure_exp:
    "Public Order and Safety Expenditure (% of Total Expenditure)",
  public_order_safety_total_expenditure_rev:
    "Public Order and Safety Expenditure (% of Total Revenue)",
  recreation_total_expenditure: "Recreation and Culture Total Expenditure",
  recreation_total_expenditure_exp:
    "Recreation and Culture Expenditure (% of Total Expenditure)",
  recreation_total_expenditure_rev:
    "Recreation and Culture Expenditure (% of Total Revenue)",
  social_protection_total_expenditure: "Social Protection Total Expenditure",
  social_protection_total_expenditure_exp:
    "Social Protection Expenditure (% of Total Expenditure)",
  social_protection_total_expenditure_rev:
    "Social Protection Expenditure (% of Total Revenue)",
};
const PiRankTable = ({ componentRef, data }) => {
  const formatData = data;
  const chartData = data?.map((item) => {
    return {
      name: capital_letter(`${item?.state}`),
      amount: item?.amount,
    };
  });

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div className={styles.custom_tooltip}>
          <p className="label">{`${label} : ${payload[0]?.payload?.amount?.toLocaleString()}%`}</p>
        </div>
      );
    }

    return null;
  }

  return (
    <div className={styles.RankTable} ref={componentRef}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <h3 style={{ fontWeight: 500, fontSize: 16, marginBottom: 10 }}>
            {categoriesTitles[`${formatData[0]?.category}`]}
          </h3>
          <TableContainer component={Paper}>
            <Table
              id="data-table-mini"
              className={styles.RankTableWrapper}
              aria-label="simple table"
            >
              <TableHead className={styles.thead}>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell align="center">Amount(NGN)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formatData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        fontWeight: 500,
                        color:
                          (index === 0 || index === 1 || index === 2) &&
                          "#007bff",
                      }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ fontWeight: 500 }}>
                      {capital_letter(`${row?.state}`)}
                    </TableCell>
                    <TableCell align="center">
                      {parseInt(row?.amount) > 1000
                        ? `${row?.amount?.toLocaleString()}`
                        : `${row?.amount?.toLocaleString()}%`}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px 0",
            }}
          >
            <span style={{ color: "#888", fontWeight: 300, fontSize: 12 }}>
              <span style={{ fontStyle: "italic" }}>Source:</span> NGF Public
              Finance Database
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <div
            className={styles.indicators}
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              transform: "translate",
              marginTop: 33,
            }}
          >
            <BarChart
              width={600}
              height={1100}
              data={chartData}
              layout="vertical"
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                interval={0}
                type="number"
                dataKey={"amount"}
                tickFormatter={convertToInternationalCurrencySystem}
              />
              <YAxis
                type="category"
                dataKey={"name"}
                interval={0}
                scaleToFit="true"
                verticalAnchor="start"
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="amount" barSize={35} fill="#009688" />
            </BarChart>
            <div style={{ textAlign: "center", fontSize: 14 }}>
              NGN(Billion)
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PiRankTable;
