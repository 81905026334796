import React from "react";
import EmptyImg from "../Lib/assets/images/empty.png";
import { CircularProgress } from "@mui/material";

const Preloader = ({ isLoading, data = { data: [] } }) => {
  if (isLoading && !data?.data?.length)
    return (
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{ color: "#059669", width: "50px", height: "50px" }}
        />
      </div>
    );
  if (!isLoading && !data?.data?.length)
    return (
      <div
        style={{
          minHeight: "70vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={EmptyImg} alt="No filter available" />
        <p className="text-center mx-auto" style={{ maxWidth: 600 }}>
          You have not made any query yet, use the filter section above to
          select the states and budget parameters you would like to compare
        </p>
      </div>
    );

  return null;
};

export default Preloader;
