import React from "react";
import styles from "../styles/styles.module.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <div className={styles.footerWrapper}>
          <div>
            © <span>{currentYear}</span> Nigeria Governors' Forum. All rights
            reserved
          </div>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/NGFSecretariat"
                rel="noreferrer"
                target="_blank"
              >
                <FacebookRoundedIcon style={{ fontSize: 20 }} />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/NGFSecretariat"
                rel="noreferrer"
                target="_blank"
              >
                <TwitterIcon style={{ fontSize: 20 }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/ngfsecretariat/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon style={{ fontSize: 20 }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nigeria-governors-forum/"
                rel="noreferrer"
                target="_blank"
              >
                <LinkedInIcon style={{ fontSize: 20 }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCSVqmkD-2gJhCx64EnUcHJQ"
                rel="noreferrer"
                target="_blank"
              >
                <YouTubeIcon />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
