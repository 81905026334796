import React from "react";
import budgetService from "../../service/budgetService";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { YEARS } from "../../static/years";
import { STATES } from "../../static/states";
import { useNavigate } from "react-router-dom";
import { Print, Save } from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import CategorySelect from "../group-explorer/components/category-select";
import DataTable from "../group-explorer/components/data-table";
import {
  BUDGET_CATEGORIES,
  BUDGET_CATEGORIES__PI,
} from "../../static/budget-categories";
import Preloader from "../../components/preloader";
import httpService from "../../service/httpService";
import { toast } from "react-toastify";

const initialValues = {
  data: [],
  states: [],
};
const defaultStates = "ABIA,ADAMAWA,AKWA_IBOM,ANAMBRA,BAUCHI";
const BudgetComparison = () => {
  const navigate = useNavigate();
  const printRef = React.useRef();
  const searchParams = new URLSearchParams(window.location.search);
  const year = searchParams.get("year") || "2018";
  const type = searchParams.get("type") || "original";
  const states = searchParams.get("states") || defaultStates;
  const category = type === "pi" ? BUDGET_CATEGORIES__PI : BUDGET_CATEGORIES;
  const [budgets, setBudgets] = React.useState(initialValues);
  const [isLoading, setIsLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([
    ...getAllCategories(category),
  ]);
  const [isMenu, setIsMenu] = React.useState(null);

  const queries = { year, type, states };

  React.useEffect(() => {
    if (!type) return;
    setCategories(getAllCategories(category));
    getBudgets();

    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Box my={10} />
      <Container>
        <Box display="flex" gap={3} mb={5}>
          <Box flex={1}>
            <Typography fontSize={32}>
              {type === "original"
                ? "Original Budget"
                : type === "actual"
                ? "Actual"
                : "Indicators"}
            </Typography>
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <Button
              endIcon={<Save />}
              variant="outlined"
              onClick={(e) => setIsMenu(e.currentTarget)}
            >
              Export
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button variant="outlined" endIcon={<Print />}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
            />
            <Menu
              open={!!isMenu}
              anchorEl={isMenu}
              onClose={() => setIsMenu(null)}
            >
              <MenuItem disabled>Export as</MenuItem>
              <MenuItem
                onClick={() => budgetService.download("data-table", "xlsx")}
              >
                Microsoft Excel (.xlsx)
              </MenuItem>
              <MenuItem
                onClick={() => budgetService.download("data-table", "csv")}
              >
                Comma Separated Values (.csv)
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="state_select">States</InputLabel>
              <Select
                size="small"
                fullWidth
                multiple
                label="States"
                labelId="state_select"
                variant="outlined"
                onChange={handleSelectState}
                value={states ? states?.split(",") : []}
                renderValue={(selected) =>
                  selected.map((s) => s.split("_").join(" ")).join(", ")
                }
              >
                <MenuItem value="" disabled>
                  States
                </MenuItem>
                <MenuItem value="">
                  <Checkbox
                    size="small"
                    onChange={handleSelectAllStates}
                    checked={states.split(",").length === STATES.length}
                    indeterminate={
                      states && states.split(",").length !== STATES.length
                        ? true
                        : false
                    }
                  />
                  Select All
                </MenuItem>
                {STATES.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    <Checkbox
                      size="small"
                      checked={states.split(",").indexOf(item.value) > -1}
                    />
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <CategorySelect
              type={type}
              value={categories}
              onChange={(value) => setCategories(value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="year_select">Year</InputLabel>
              <Select
                fullWidth
                size="small"
                value={year}
                label="Year"
                labelId="year_select"
                variant="outlined"
                onChange={(e) => handleChange(e, "year")}
              >
                <MenuItem value="" disabled>
                  Select Year
                </MenuItem>
                {type === "revised" ? (
                  <MenuItem value={"2020"}>2020</MenuItem>
                ) : (
                  YEARS.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={getBudgets}
              disabled={isLoading}
              endIcon={
                isLoading && (
                  <CircularProgress
                    style={{ color: "#fff", width: "20px", height: "20px" }}
                  />
                )
              }
            >
              Filter
            </Button>
          </Grid>
        </Grid>
        <DataTable
          type={type}
          data={budgets}
          componentRef={printRef}
          categories={categories}
        />
        <Preloader data={budgets} isLoading={isLoading} />
      </Container>
      <Box my={10} />
    </React.Fragment>
  );

  async function getBudgets() {
    try {
      inputValidator();
      setIsLoading(true);
      const { data } = await budgetService.get(queries);
      setBudgets(budgetService.formatData(data, type));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      httpService.showFeedback(error);
    }
  }

  function inputValidator() {
    if (!type) {
      toast.error(`"Budget Type" is invalid`);
      throw new Error(`"Budget Type" is invalid`);
    }
    if (states.split(",").length < 2) {
      toast.error(`"States" cannot be less than 2`);
      throw new Error(`States" cannot be less than 2`);
    }
    if (!categories.length) {
      toast.error(`"Budget Categories" is not allowed to be empty`);
      throw new Error(`"Budget Categories" is not allowed to be empty`);
    }
    if (!year) {
      toast.error(`"Year" is not allowed to be empty`);
      throw new Error(`"Year" is not allowed to be empty`);
    }
  }

  function handleChange(e, name) {
    const value = e.target.value;
    searchParams.set(name, value);
    if (name === "type" && type === "pi") {
      setCategories([]);
      setBudgets({ data: [], states: [] });
    }
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }

  function getAllCategories(BUDGET_CATEGORIES) {
    let result = [];
    BUDGET_CATEGORIES.forEach((c) => {
      result.push(c.value);
      const children = c.children;
      if (children.length) {
        result = [...result, ...getAllCategories(children)];
      }
    });
    return [...new Set(result)];
  }

  function handleSelectState(e) {
    const value = e.target.value?.filter((item) => !!item);
    searchParams.set("states", value.join(","));
    navigate(
      {
        pathname: window.location.pathname,
        search: searchParams.toString(),
      },
      { replace: true }
    );
  }
  function handleSelectAllStates(e) {
    try {
      const checked = e.target.checked;
      let value = [];
      if (checked) value = STATES.map((item) => item?.value);
      else value = [];

      searchParams.set("states", value.join(","));
      navigate(
        {
          pathname: window.location.pathname,
          search: searchParams.toString(),
        },
        { replace: true }
      );
    } catch (error) {
      console.error(error);
    }
  }
};

export default BudgetComparison;
