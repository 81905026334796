import React from "react";
import Content from "./components/Content";

const Dashboard = () => {
  return (
    <div className="container">
      <Content />
    </div>
  );
};

export default Dashboard;
