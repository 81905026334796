import { Button, CircularProgress, Collapse, IconButton } from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import styles from "../styles/styles.module.css";
import UploadImg from "../assets/upload.png";

const AddNew = ({ formData, setFormData, handleSubmit, loading }) => {
  const [open, setOpen] = useState(true);
  return (
    <>
      <div className={styles.addNew}>
        <div></div>
        <div>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            endIcon={<AddIcon />}
          >
            upload New
          </Button>
        </div>
      </div>
      <Collapse in={open}>
        <div className={styles.upload_file}>
          <h3>Add New Data</h3>
          <IconButton
            onClick={() => setOpen(false)}
            className={styles.close_icon}
          >
            <Close />
          </IconButton>
          <div className={styles.form_fields}>
            <div>
              <Button
                className={styles.upload_btn}
                size="large"
                variant="outlined"
                component="label"
                color="secondary"
                endIcon={<img src={UploadImg} alt="upload file" />}
              >
                Select file
                <input
                  onChange={(e) =>
                    setFormData({ ...formData, file: e.target.files[0] })
                  }
                  type="file"
                  hidden
                  accept=".xls, .xlsx"
                />
              </Button>
              <div className={styles.file_name}>{formData?.file?.name}</div>
            </div>
            <div>
              <Button
                onClick={handleSubmit}
                disabled={loading}
                size="large"
                color="primary"
                variant="contained"
                startIcon={
                  loading && (
                    <CircularProgress
                      style={{ color: "#fff", width: 20, height: 20 }}
                    />
                  )
                }
              >
                Upload
              </Button>
            </div>
            <div className={styles.note}>
              Naming files are important, here are some examples. For Original
              budget: B2022, for Actual Budget: A2022, and for Performance
              indicators: PI2022. If a document is not in these names, the
              system will not upload it.
            </div>
          </div>
        </div>
      </Collapse>
    </>
  );
};

export default AddNew;
