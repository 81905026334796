import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import styles from "../styles/styles.module.css";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { categoriesTitles } from "../../../Data/2018/Titles";

function convertToInternationalCurrencySystem(labelValue) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e12
    ? (Math.abs(Number(labelValue)) / 1.0e12).toFixed(2) + "T"
    : Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
}

function numberWithCommas(x) {
  return Number(parseFloat(x).toFixed(2)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
}

function capital_letter(str) {
  str = str.split(" ");

  for (var i = 0, x = str.length; i < x; i++) {
    str[i] =
      str[i].length > 1 ? str[i][0].toUpperCase() + str[i].substr(1) : str[i];
  }

  return str.join(" ");
}

function CustomTooltip({ payload, label, active }) {
  if (active) {
    return (
      <div className={styles.custom_tooltip}>
        <p className="label">{`${label} : ${payload[0]?.payload?.amount?.toLocaleString()}`}</p>
      </div>
    );
  }

  return null;
}

const RankTable = ({ formatData, componentRef }) => {
  const data = formatData?.map((item) => {
    return {
      name: capital_letter(`${item?.state}`),
      amount: item?.amount,
    };
  });
  return (
    <div className={styles.RankTable} ref={componentRef}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <h3 style={{ fontWeight: 500, fontSize: 16, marginBottom: 10 }}>
            {categoriesTitles[`${formatData[0]?.category}`]}
          </h3>
          <TableContainer component={Paper}>
            <Table
              className={styles.RankTableWrapper}
              aria-label="simple table"
              id="data-table-mini"
            >
              <TableHead className={styles.thead}>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell align="right">
                    <span style={{ marginRight: 20 }}>Amount(NGN)</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formatData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      style={{
                        fontWeight: 500,
                        color:
                          (index === 0 || index === 1 || index === 2) &&
                          "#007bff",
                      }}
                      component="th"
                      scope="row"
                    >
                      {index + 1}
                      {/* {row?.rank} */}
                    </TableCell>
                    <TableCell
                      // className={styles.tcell}
                      style={{ fontWeight: 500 }}
                    >
                      {capital_letter(`${row?.state}`)}
                    </TableCell>
                    <TableCell align="right">
                      {row?.type === "pi"
                        ? (row?.amount * 100)?.toFixed(2) + "%"
                        : numberWithCommas(row?.amount)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px 0",
            }}
          >
            <span style={{ color: "#888", fontWeight: 300, fontSize: 12 }}>
              <span style={{ fontStyle: "italic" }}>Source:</span> NGF Public
              Finance Database
            </span>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={6}>
          <div
            className={styles.indicators}
            style={{
              overflowX: "auto",
              overflowY: "hidden",
              transform: "translate",
              marginTop: 33,
            }}
          >
            <BarChart
              width={600}
              height={
                data?.length < 10
                  ? 350
                  : data?.length < 20
                  ? 700
                  : data?.length < 30
                  ? 1000
                  : 1100
              }
              data={data}
              layout="vertical"
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                // orientation={"top"}
                // reversed

                // sclaeToFit="true"
                // verticalAnchor="start"
                interval={0}
                // angle="60"
                // angle="-60"
                type="number"
                dataKey={"amount"}
                tickFormatter={convertToInternationalCurrencySystem}
              />
              <YAxis
                // orientation={"right"}
                type="category"
                dataKey={"name"}
                interval={0}
                // textAnchor="end"
                // angle="-50"
                sclaeToFit="true"
                verticalAnchor="start"
                // tickFormatter={convertToInternationalCurrencySystem}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey="amount" barSize={35} fill="#009688" />
            </BarChart>
            <div style={{ textAlign: "center", fontSize: 14 }}>
              NGN(Billion)
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default RankTable;
