import { Close } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as budgetData from "../../../Data/2018/categoriesData";

let years = [];
let date = 2018;
let currentYear = new Date().getFullYear();
for (let i = date; i <= currentYear; i++) {
  years.push(i);
}

const Rank = ({
  styles,
  setRankData,
  setGeneralData,
  handleTypeClick,
  handleTypeClickAway,
  typeOpen,
  handleRankShow,
  selectBudgetType,
  setSelectBudgetType,
  // state
  states,
  selectedState,
  setSelectedStates,
  setSearchState,
  searchState,
  filteredStates,
  // year
  selectedYear,
  setSelectedYear,
  // handlers
  sortBy,
  setSortBy,
  fetchBudgets,
  fetchPiBudgets,
  isLoading,
  popItems,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [more, setMore] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(null);
  const [categoryTitle, setCategoryTitle] = useState("");
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [collapse_third, set_collapse_third] = useState(false);
  const [second_index, set_second_index] = useState(null);

  const collapse_third_category = (index) => {
    if (collapse_third && index === second_index) {
      set_collapse_third(!collapse_third);
    } else {
      set_collapse_third(!collapse_third);
    }
  };

  const [collapse_forth, set_collapse_forth] = useState(false);
  const [third_c_index, set_third_c_index] = useState(null);

  const collapse_forth_category = (index) => {
    if (collapse_third && index === third_c_index) {
      set_collapse_forth(!collapse_forth);
    } else {
      set_collapse_forth(!collapse_forth);
    }
  };

  const expandMore = (index) => {
    if (more && index === currentIndex) {
      setMore(!more);
      set_collapse_forth(!collapse_forth);
      set_collapse_third(!collapse_third);
    } else {
      setMore(!more);
    }
  };

  // Select state
  const [stateAnchorEl, setStateAnchorEl] = React.useState(null);
  const openState = Boolean(stateAnchorEl);
  const stateId = open ? "simple-popover" : undefined;

  const handleStateClick = (event) => {
    setStateAnchorEl(event.currentTarget);
  };

  const handleStateClose = () => {
    setStateAnchorEl(null);
  };

  const piDataFilter = budgetData?.piRankData?.filter((f) =>
    f?.title
      ?.trim()
      ?.toLowerCase()
      ?.includes(popItems?.searchedCategory?.trim()?.toLowerCase())
  );

  return (
    <div className={styles.rankSection}>
      <div className={styles.rankFilters}>
        <div className={styles.filterInput}>
          <FormControl fullWidth>
            <Select
              fullWidth
              className={styles.SelectInput}
              size="small"
              displayEmpty
              value={selectBudgetType}
              onChange={(e) => setSelectBudgetType(e.target.value)}
              required
            >
              <MenuItem value="" disabled>
                Budget Type
              </MenuItem>
              <MenuItem
                value="original"
                onClick={() => {
                  setGeneralData([]);
                  setRankData([]);
                  setCategoryTitle("");
                  popItems?.setSelectedCategory("");
                }}
              >
                Original Budget
              </MenuItem>
              <MenuItem
                value="actual"
                onClick={() => {
                  setGeneralData([]);
                  setRankData([]);
                  setCategoryTitle("");
                  popItems?.setSelectedCategory("");
                }}
              >
                Actual
              </MenuItem>
              <MenuItem
                value="pi"
                onClick={() => {
                  setRankData([]);
                  setCategoryTitle("");
                  popItems?.setSelectedCategory("");
                }}
              >
                Budget Performance
              </MenuItem>
              <MenuItem
                value="revised"
                onClick={() => {
                  setGeneralData([]);
                  setRankData([]);
                  setCategoryTitle("");
                  popItems?.setSelectedCategory("");
                }}
              >
                Revised
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={styles.filterInput}>
          <button
            className={styles.selectMany}
            aria-describedby={stateId}
            onClick={handleStateClick}
          >
            <span>
              {selectedState?.length > 0
                ? selectedState.join(", ")
                : "Select States"}
            </span>
            <span>{<ArrowDropDownIcon />}</span>
          </button>
          <Popover
            id={stateId}
            open={openState}
            anchorEl={stateAnchorEl}
            onClose={handleStateClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ul className={styles.catSelect}>
              <li className={styles.header}>Select States</li>
              <div className={styles.search}>
                <TextField
                  size="small"
                  placeholder="Search"
                  value={searchState}
                  onChange={(e) => setSearchState(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {filteredStates?.length <= 0 && (
                <div style={{ padding: "0px 16px 16px 16px", fontSize: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <SearchIcon /> Results for "{searchState}"
                  </div>

                  <div>Check your spelling, or search for something else</div>
                </div>
              )}
              {filteredStates?.length > 0 && (
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    className={styles.select}
                    onClick={() =>
                      filteredStates.length !== selectedState.length
                        ? setSelectedStates([...filteredStates])
                        : setSelectedStates([])
                    }
                  >
                    <Checkbox
                      size="small"
                      indeterminate={
                        selectedState.length > 0 &&
                        selectedState.length < filteredStates.length
                      }
                      checked={selectedState.length === filteredStates.length}
                    />
                    Select All
                  </div>
                </li>
              )}
              {filteredStates?.map((item, index) => (
                <div key={index}>
                  <div
                    style={{
                      padding: "0px 14px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    className={styles.select}
                    key={index}
                    onClick={() =>
                      selectedState.some((state) => state === item)
                        ? setSelectedStates(
                            selectedState?.filter((i) => i !== item)
                          )
                        : setSelectedStates([...selectedState, item])
                    }
                  >
                    <Checkbox
                      size="small"
                      checked={selectedState.indexOf(item) > -1}
                    />
                    {item}
                  </div>
                </div>
              ))}
            </ul>
          </Popover>
          {selectedState?.length > 0 && selectedState.length < 2 && (
            <div style={{ fontSize: 12, color: "red" }}>minimum(2)</div>
          )}
        </div>
        <div className={styles.filterInput}>
          <button
            className={styles.selectMany}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <span>{categoryTitle || "Select Category"}</span>
            <span>{<ArrowDropDownIcon />}</span>
          </button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ul className={styles.catSelect} style={{ marginBottom: 18 }}>
              <li className={styles.header}>Select Category</li>
              <div className={styles.search}>
                <TextField
                  size="small"
                  placeholder="Search"
                  value={popItems?.searchedCategory}
                  onChange={(e) => popItems?.setSearchCategory(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {selectBudgetType === "pi" ? (
                <div style={{ paddingBottom: "24px" }}>
                  {piDataFilter?.map((item, index) => (
                    <div key={index}>
                      <div
                        style={{ padding: "6px 12px" }}
                        className={styles.first_category}
                        onClick={() => {
                          if (
                            budgetData?.get_pi_category(item?.name)?.length > 0
                          ) {
                            setCurrentIndex(index);
                            expandMore(index);
                          } else {
                            popItems?.setSelectedCategory(item?.name);
                            setCategoryTitle(item?.title);
                            setAnchorEl(null);
                          }
                        }}
                      >
                        <span
                          style={{
                            padding: " 6px 18px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item?.title}
                        </span>
                        {budgetData?.get_pi_category(item?.name)?.length >
                          0 && (
                          <span>
                            <ExpandMoreIcon />
                          </span>
                        )}
                      </div>
                      {budgetData
                        ?.get_pi_category(item?.name)
                        ?.map((content, i) => (
                          <Collapse key={i} in={more && currentIndex === index}>
                            <div
                              style={{ padding: "6px 12px" }}
                              className={styles.second_category}
                            >
                              <span
                                style={{
                                  padding: " 0px 24px",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                }}
                                onClick={() => {
                                  popItems?.setSelectedCategory(content?.name);
                                  setCategoryTitle(content?.title);
                                  setAnchorEl(null);
                                }}
                              >
                                {content?.title}
                              </span>
                            </div>
                          </Collapse>
                        ))}
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  {budgetData?.query(popItems?.searchedCategory)?.length <=
                    0 && (
                    <div
                      style={{ padding: "0px 16px 16px 16px", fontSize: 12 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <SearchIcon /> Results for "{popItems?.searchedCategory}
                        "
                      </div>

                      <div>
                        Check your spelling, or search for something else
                      </div>
                    </div>
                  )}
                  {/* Search Results */}
                  {popItems?.searchedCategory?.length > 0 &&
                    budgetData
                      ?.query(popItems?.searchedCategory)
                      ?.map((item, index) => (
                        <ul key={index}>
                          <li
                            onClick={() => {
                              popItems?.setSelectedCategory(item?.name);
                              setCategoryTitle(item?.title);
                              setAnchorEl(null);
                            }}
                          >
                            {item?.title}
                          </li>
                        </ul>
                      ))}
                  {/* Categories Rendered */}
                  {popItems?.searchedCategory?.length <= 0 &&
                    budgetData?.first_category?.map((item, index) => (
                      <div key={index}>
                        <div
                          style={{ padding: "6px 12px" }}
                          className={styles.first_category}
                          onClick={() => {
                            setCurrentIndex(index);
                            expandMore(index);
                          }}
                        >
                          <span>{item?.category}</span>
                          <span>
                            <ExpandMoreIcon />
                          </span>
                        </div>
                        {budgetData
                          ?.get_second_category(item?.name)
                          ?.map((sub_category, sub_index) => (
                            <div key={sub_index}>
                              <Collapse in={more && currentIndex === index}>
                                <div
                                  style={{ padding: "3px 12px 3px 24px" }}
                                  className={styles.second_category}
                                >
                                  <span
                                    onClick={() => {
                                      popItems?.setSelectedCategory(
                                        sub_category?.name
                                      );
                                      setCategoryTitle(sub_category?.title);
                                      setAnchorEl(null);
                                    }}
                                  >
                                    {sub_category?.title}
                                  </span>
                                  {budgetData?.get_third_category(
                                    sub_category?.name,
                                    item?.name
                                  )?.length > 0 && (
                                    <span
                                      onClick={() => {
                                        set_second_index(sub_index);
                                        collapse_third_category(sub_index);
                                      }}
                                    >
                                      <ExpandMoreIcon />
                                    </span>
                                  )}
                                </div>
                                {budgetData
                                  ?.get_third_category(
                                    sub_category?.name,
                                    item?.name
                                  )
                                  ?.map((third_category, third_index) => (
                                    <div key={third_index}>
                                      <Collapse
                                        in={
                                          collapse_third &&
                                          second_index === sub_index
                                        }
                                      >
                                        <div
                                          className={styles.third_category}
                                          style={{
                                            minHeight: "28px",
                                            padding: "0px 12px 0px 32px",
                                          }}
                                        >
                                          <span
                                            onClick={() => {
                                              popItems?.setSelectedCategory(
                                                third_category?.name
                                              );
                                              setCategoryTitle(
                                                third_category?.title
                                              );
                                              setAnchorEl(null);
                                            }}
                                          >
                                            {third_category?.title}
                                          </span>
                                          {budgetData?.get_forth_category(
                                            third_category?.name
                                          )?.length > 0 && (
                                            <span
                                              onClick={() => {
                                                set_third_c_index(third_index);
                                                collapse_forth_category(
                                                  third_index
                                                );
                                              }}
                                            >
                                              <ExpandMoreIcon />
                                            </span>
                                          )}
                                        </div>
                                        {budgetData
                                          ?.get_forth_category(
                                            third_category?.name
                                          )
                                          ?.map(
                                            (forth_category, forth_index) => (
                                              <div key={forth_index}>
                                                <Collapse
                                                  in={
                                                    collapse_forth &&
                                                    third_c_index ===
                                                      third_index
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.forth_category
                                                    }
                                                    style={{
                                                      padding:
                                                        "5px 12px 5px 40px",
                                                    }}
                                                  >
                                                    <span
                                                      onClick={() => {
                                                        popItems?.setSelectedCategory(
                                                          forth_category?.name
                                                        );
                                                        setCategoryTitle(
                                                          forth_category?.title
                                                        );
                                                        setAnchorEl(null);
                                                      }}
                                                    >
                                                      {forth_category?.title}
                                                    </span>
                                                  </div>
                                                </Collapse>
                                              </div>
                                            )
                                          )}
                                      </Collapse>
                                    </div>
                                  ))}
                              </Collapse>
                            </div>
                          ))}
                      </div>
                    ))}
                </>
              )}
              {/* No result for search UI */}
            </ul>
          </Popover>
        </div>
        <div className={styles.filterInput}>
          <FormControl fullWidth>
            {selectBudgetType === "revised" ? (
              <Select
                fullWidth
                className={styles.SelectInput}
                size="small"
                displayEmpty
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                required
              >
                <MenuItem value="" disabled>
                  Year
                </MenuItem>

                <MenuItem value="2020">2020</MenuItem>
              </Select>
            ) : (
              <Select
                fullWidth
                className={styles.SelectInput}
                size="small"
                displayEmpty
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                required
              >
                <MenuItem value="" disabled>
                  Year
                </MenuItem>
                {years?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </div>
        <div
          className={styles.mobileSort}
          style={{
            padding: "0px",
            marginTop: "-10px",
          }}
        >
          <span style={{ marginRight: "10px", fontWeight: 500 }}>Sort:</span>
          <FormControl>
            <RadioGroup
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              row
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="ascending"
                control={<Radio />}
                label="Ascending"
              />
              <FormControlLabel
                value="descending"
                control={<Radio />}
                label="Descending"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={styles.filterInput}>
          <Button
            onClick={() => {
              selectBudgetType === "pi" ? fetchPiBudgets() : fetchBudgets();
            }}
            disabled={
              selectedState?.length < 2 ||
              !popItems?.selectedCategory ||
              !selectBudgetType ||
              !selectedYear ||
              !sortBy
            }
            variant="contained"
            color="primary"
            endIcon={
              isLoading && (
                <CircularProgress
                  style={{ color: "#fff", width: "20px", height: "20px" }}
                />
              )
            }
          >
            Rank
          </Button>
        </div>
      </div>
      <div
        className={styles.webSort}
        style={{
          padding: "10px 20px",
          paddingLeft: "0px",
        }}
      >
        <span style={{ marginRight: "10px", fontWeight: 500 }}>Sort By:</span>
        <FormControl>
          <RadioGroup
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            row
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="ascending"
              control={<Radio />}
              label="Ascending"
            />
            <FormControlLabel
              value="descending"
              control={<Radio />}
              label="Descending"
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default Rank;
