import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, CircularProgress, Dialog, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import httpService from "../../../service/httpService";

const getType = (type) => {
  switch (type) {
    case "pi":
      return "Indicators";
    case "actual":
      return "Actual";
    case "original":
      return "Original Budget";
    case "revised":
      return "Revised Budget";
    default:
  }
};
const DataTable = ({ styles, reload, setReload }) => {
  const [budgets, setBudgets] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlers = {
    handleClose() {
      setDialogOpen(false);
      setSelectedFile(null);
    },
    handleOpen(file) {
      setDialogOpen(true);
      setSelectedFile(file);
    },
  };

  // eslint-disable-next-line
  useEffect(() => {
    fetchBudgets();
  }, [reload]);

  return (
    <div>
      {!loading && budgets?.length > 0 && (
        <TableContainer component={Paper} style={{ margin: "20px 0 50px 0" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead className={styles.thead}>
              <TableRow>
                <TableCell>Year</TableCell>
                <TableCell>Budget Type</TableCell>
                <TableCell>Upload Date</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody size="small">
              {budgets?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell className={styles.td} component="th" scope="row">
                    {item?.year}
                  </TableCell>
                  <TableCell className={styles.td} component="th" scope="row">
                    {getType(item?.type)}
                  </TableCell>
                  <TableCell className={styles.td} component="th" scope="row">
                    {new Date(item?.createdAt).toLocaleDateString("en-GB")}
                  </TableCell>
                  <TableCell className={styles.td} component="th" scope="row">
                    <IconButton onClick={() => handlers?.handleOpen(item)}>
                      <DeleteForeverIcon color="danger" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog open={dialogOpen}>
        <div className={styles.dialog_content}>
          Are you sure you want to delete this data? <br /> This action cannot
          be undone!
        </div>
        <div className={styles.action_btn}>
          <Button
            onClick={handlers?.handleClose}
            variant="outlined"
            color="gray"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              selectedFile?.type === "pi" ? deletePI() : deleteBudgets();
            }}
            className={styles.btn_del}
            variant="contained"
            color="danger"
          >
            Delete
          </Button>
        </div>
      </Dialog>
      {loading && (
        <div
          style={{
            minHeight: "30vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{ color: "#059669", width: "50px", height: "50px" }}
          />
        </div>
      )}
    </div>
  );

  async function fetchBudgets() {
    try {
      setLoading(true);
      const { data } = await httpService.get("/budget/uploaded-sheets");
      setBudgets(data?.data?.result?.sort((a, b) => b?.year - a?.year));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      httpService.showFeedback(error);
    }
  }

  async function deleteBudgets() {
    const { type, year } = selectedFile;
    const url = `/budget/delete?year=${year}&type=${type}`;

    try {
      await httpService.delete(url);
      httpService.showSuccess("Budget deleted successfully");
      handlers.handleClose();
      setReload((reload) => !reload);
    } catch (error) {
      httpService.showFeedback(error);
    }
  }

  async function deletePI() {
    const { type, year } = selectedFile;
    const url = `/budget/delete-${type}?year=${year}`;
    try {
      await httpService.delete(url);
      httpService.showSuccess("Budget deleted successfully");
      setReload((reload) => !reload);
      handlers.handleClose();
    } catch (error) {
      httpService.showFeedback(error);
    }
  }
};

export default DataTable;
