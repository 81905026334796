import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoWeb from "../../../Lib/assets/images/logo-web.png";
import LogoMobile from "../../../Lib/assets/images/logo-mobile.png";
import styles from "../styles/styles.module.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

const Topbar = () => {
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const [open, setOpen] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleOpen = () => {
    setOpen((open) => !open);
  };
  const handleClose = () => {
    setOpen((open) => !open);
  };

  const updateSize = () => setInnerWidth(window.innerWidth);

  useEffect(() => {
    window.onresize = updateSize;
  }, []);

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
        <span>
          <a href="https://www.publicfinance.ngf.org.ng">
            <img src={innerWidth > 1080 ? LogoWeb : LogoMobile} alt="NGF" />
          </a>
        </span>
        <span className={styles.menu}>
          {open ? (
            <CloseIcon onClick={handleClose} className={styles.menuIcon} />
          ) : (
            <MenuIcon onClick={handleOpen} className={styles.menuIcon} />
          )}
        </span>
        <ul>
          <li>
            <a href="https://www.publicfinance.ngf.org.ng">Home</a>
          </li>
          <li
            className={
              path?.includes("group-explorer") ? styles.activeLink : undefined
            }
          >
            <Link to="/group-explorer">Group Explorer</Link>
          </li>
          <li>
            <a href="https://www.publicfinance.ngf.org.ng/state-budget/">
              States Explorer
            </a>
          </li>
          {/* <li>
            <a href="https://dev.fresible.website/sandbox/stakeholders/">
              Stakeholders
            </a>
          </li> */}

          <li>
            <a href="https://www.publicfinance.ngf.org.ng/contact-us/">
              Contact Us
            </a>
          </li>
        </ul>
        <ul className={open ? styles.mobileMenuShow : styles.mobileMenu}>
          <li>
            <a href="https://www.publicfinance.ngf.org.ng">Home</a>
          </li>
          <li
            className={
              path?.includes("group-explorer") ? styles.activeLink : undefined
            }
          >
            <Link to="/group-explorer">Group Explorer</Link>
          </li>
          <li>
            <a href="https://www.publicfinance.ngf.org.ng/state-budget/">
              States Explorer
            </a>
          </li>
          {/* <li>
            <a href="https://dev.fresible.website/sandbox/stakeholders/">
              Stakeholders
            </a>
          </li> */}

          <li>
            <a href="https://www.publicfinance.ngf.org.ng/contact-us/">
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Topbar;
