import * as XLSX from "xlsx/xlsx.mjs";
import TableToExcel from "table-to-excel";

function ExportFile(type, fn, dl) {
  let elt = document.getElementById("data-table");
  let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });

  console.log("Clicked");

  const decimalStyle = {
    numFmt: "0.00",
  };

  return XLSX.write(wb, {
    bookType: type,
    bookSST: true,
    type: "base64",
    cellStyles: true, // Enable cell styles
    compression: true, // Enable compression
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // Set style for numbers with decimals
    cellStyles: true,
    bookVBA: true,
    sheets: {
      sheet1: {
        A1: decimalStyle, // Example: apply the style to cell A1
      },
    },
  });

  return dl
    ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
    : XLSX.writeFile(wb, fn || "NGF Database." + (type || "xlsx"));
}
function ExportFileMini(type, fn, dl) {
  let elt = document.getElementById("data-table-mini");
  let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });

  return dl
    ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
    : XLSX.writeFile(wb, fn || "NGF Database." + (type || "xlsx"));
}

export { ExportFile, ExportFileMini };
