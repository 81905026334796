import { createTheme, ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Dashboard from "./Pages/dashboard/Dashboard";
import Home from "./Pages/Home";
import RankStates from "./Pages/RankStates/RankStates";
import GroupExplorer from "./modules/group-explorer";
import BudgetComparison from "./modules/budget-comparison";
import StateBudgetComparison from "./modules/state-budget-comparison";
import StateBudgetTotalRevenue from "./modules/state-budget__total-revenue";
import { ToastContainer } from "react-toastify";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#009688",
        dark: "#009688",
      },
      secondary: {
        main: "#017F01",
      },
      danger: {
        main: "#FF0303",
      },
      success: {
        main: "#4bb543",
      },
      gray: {
        main: "#6F727A",
        dark: "#6F727A",
      },
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path={"/"} exact element={<Home />} />
          <Route path={"/group-explorer"} exact element={<GroupExplorer />} />
          <Route
            path={"/budget-type-compare"}
            exact
            element={<BudgetComparison />}
          />
          <Route
            path={"/state-budget-compare"}
            exact
            element={<StateBudgetComparison />}
          />
          <Route
            path={"/state-budget-revenue"}
            exact
            element={<StateBudgetTotalRevenue />}
          />
          <Route path={"/rank-budget"} exact element={<RankStates />} />
          <Route path={"/upload/:id"} exact element={<Dashboard />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
