export const categoriesTitles = {
  total_revenue: "Total Revenue",
  government_share_of_federation_revenue_faac:
    "Government Share of Federation Revenue (FAAC)",
  state_government_of_statutory_allocation:
    "State Government Share of Statutory Allocation",
  statutory_allocation: "Statutory Allocation",
  government_share_of_value_added_tax_vat:
    "Government Share of Value Added Tax (VAT)",
  government_share_of_other_federation_revenues:
    "Government Share of Other Federation Revenues",
  independent_revenue: "Independent Revenue (Internally Generated Revenue)",
  tax_revenue: "Tax Revenue",
  personal_taxes: "Personal Taxes",
  other_taxes: "Other Taxes",
  non_tax_revenue: "Non-Tax Revenue",
  licences_general: "Licences General",
  fees_general: "Fees – General",
  fines_general: "Fines – General",
  sales_general: "Sales – General",
  earnings_general: "Earnings – General",
  rent_on_gov_buildings_general: "Rent On Government Buildings – General",
  rent_on_land_and_others_general: "Rent on Land and Others – General",
  repayments: "Repayments",
  investment_income: "Investment Income",
  interest_earned: "Interest Earned",
  reimbursement: "Reimbursement",
  miscellaneous_income: "Miscellaneous Income",
  aids_grants: "Aids and Grants",
  domestic_aids: "Domestic Aids",
  foreign_aids: "Foreign Aids",
  domestic_grants: "Domestic Grants",
  foreign_grants: "Foreign Grants",
  capital_development_fund_receipts: "Capital Development Fund (CDF) Receipts",
  // Exp by Economic
  exp_by_economic: "Expenditure by economic",
  personal_expenditure: "Personnel Expenditure",
  personal_cost: "Personnel Cost",
  salaries_wages_general: "Salaries and Wages – General",
  allowances: "Allowances",
  social_contribution: "Social Contribution",
  social_benefit: "Social Benefit",
  other_capital_expenditure: "Other Capital Expenditure",
  overhead_costs: "Overhead Costs",
  transport_and_travelling_general: "Transport and Travelling General",
  utilities_general: "Utilities General",
  materials_and_supplies_general: "Materials and Supplies General",
  maintainance_services_general: "Maintenance Services General",
  training_general: "Training – General",
  other_services_general: "Other Services General",
  consulting_and_professional_services_general:
    "Consulting and Professional Services General",
  fuel_and_lubricant_general: "Fuel and Lubricant General",
  financial_general: "Financial General",
  miscellaneous_general: "Miscellaneous General",
  loans_and_advances: "Loans and Advances",
  staff_loans_and_allowances: "Staff Loans and Allowances",
  grants_and_contribution_general: "Grants and Contribution General",
  subsidies_general: "Subsidies General",
  public_debt_charges: "Public Debt Charges",
  foreign_debt_interest: "Foreign Debt Interest",
  domestic_debt_interest: "Domestic Debt Interest",
  foreign_debt_principal: "Foreign Debt Principal",
  domestic_debt_principal: "Domestic Debt Principal",
  transfers_payments: "Transfers-Payments",
  capital_expenditure: "Capital Expenditure",
  fixed_assets_purchased: "Fixed Assets Purchased",
  construction_provision: "Construction/Provision",
  rehabilitation_repairs: "Rehabilitation/Repairs",
  preservation_of_the_environment: "Preservation of The Environment",
  other_capital_projects: "Other Capital Projects",
  // Exp admin (rec)
  exp_by_admin_recurrent: "Expenditure by Administrative (Recurrent)",
  administration_sector__recurrent_expenditure:
    "Administration Sector (Recurrent Expenditure)",
  economic_sector__recurrent_expenditure:
    "Economic Sector (Recurrent Expenditure)",
  law_and_justice_sector__recurrent_expenditure:
    "Law and Justice Sector (Recurrent Expenditure)",
  regional_sector__recurrent_expenditure:
    "Regional Sector (Recurrent Expenditure)",
  social_sector__recurrent_expenditure: "Social Sector (Recurrent Expenditure)",
  // Exp admin (cap)
  exp_by_admin_capital: "Expenditure by Administrative (Capital)",
  administration_sector__capital_expenditure:
    "Administration Sector (Capital Expenditure)",
  economic_sector__capital_expenditure: "Economic Sector (Capital Expenditure)",
  law_and_justice_sector__capital_expenditure:
    "Law and Justice Sector (Capital Expenditure)",
  regional_sector__capital_expenditure: "Regional Sector (Capital Expenditure)",
  social_sector__capital_expenditure: "Social Sector (Capital Expenditure)",
  // Exp by func recurrent
  exp_by_func_recurrent: "Expenditure by Functions (Recurrent)",
  total_recurrent_expenditure: "Total Recurrent Expenditure",
  general_public_service: "General Public Service (Recurrent Expenditure)",
  public_order_safety: "Public Order and Safety (Recurrent Expenditure)",
  economic_affairs: "Economic Affairs (Recurrent Expenditure)",
  evironmental_protection: "Environmental Protection (Recurrent Expenditure)",
  housing_community_amenities:
    "Housing and Community Amenities (Recurrent Expenditure)",
  health: "Health (Recurrent Expenditure)",
  recreation_culture_religon:
    "Recreation, Culture and Religion (Recurrent Expenditure)",
  education: "Education (Recurrent Expenditure)",
  social_protection: "Social Protection (Recurrent Expenditure)",
  executive_legislative_organ_financial_external:
    "Executive & Legislative Organ, Financial Affairs and External Affairs (Recurrent Expenditure)",
  foreign_economic_aid__recurrent_expenditure:
    "Foreign and Economic Aid (Recurrent Expenditure)",
  general_services__recurrent_expenditure:
    "General Services (Recurrent Expenditure)",
  basic_research__recurrent_expenditure:
    "Basic Research (Recurrent Expenditure)",
  rd_general_public_services__recurrent_expenditure:
    "R&D General Public Services (Recurrent Expenditure)",
  general_public_servuces_nec__recurrent_expenditure:
    "General Public Services N.E.C (Recurrent Expenditure)",
  public_debt_transactions__recurrent_expenditure:
    "Public Debt Transactions (Recurrent Expenditure)",
  transfer_general_character_between_dif_levels_of_gov:
    "Transfer of a General Character between Different Levels of Government (Recurrent Expenditure)",
  police_services__recurrent_expenditure:
    "Police Services (Recurrent Expenditure)",
  fire_protection_services__recurrent_expenditure:
    "Fire Protection Services (Recurrent Expenditure)",
  justice_law_courts__recurrent_expenditure:
    "Justice & Law Courts (Recurrent Expenditure)",
  general_economic_commercial_labour_affairs:
    "General Economic, Commercial and Labour Affairs (Recurrent Expenditure)",
  agriculture_forestry_fishing_hunting__recurrent_expenditure:
    "Agriculture, Forestry, Fishing and Hunting (Recurrent Expenditure)",
  fuel_energy__recurrent_expenditure: "Fuel and Energy (Recurrent Expenditure)",
  mining_manufacturing_construction__recurrent_expenditure:
    "Mining, Manufacturing and Construction (Recurrent Expenditure)",
  transport__recurrent_expenditure: "Transport (Recurrent Expenditure)",
  communication__recurrent_expenditure: "Communication (Recurrent Expenditure)",
  other_inductries__recurrent_expenditure:
    "Other Industries (Recurrent Expenditure)",
  ed_economic_affairs__recurrent_expenditure:
    "R&D Economic Affairs (Recurrent Expenditure)",
  economic_affairs_nec__recurrent_expenditure:
    "Economic Affairs N. E. C (Recurrent Expenditure)",
  waste_management__recurrent_expenditure:
    "Waste Management (Recurrent Expenditure)",
  waste_water_management__recurrent_expenditure:
    "Waste Water Management (Recurrent Expenditure)",
  pollution_abatement__recurrent_expenditure:
    "Pollution Abatement (Recurrent Expenditure)",
  protection_biodiversity_landscape__recurrent_expenditure:
    "Protection of Biodiversity and Landscape (Recurrent Expenditure)",
  rd_environmental_protection__recurrent_expenditure:
    "R&D Environmental Protection (Recurrent Expenditure)",
  environmental_protection_nec__recurrent_expenditure:
    "Environmental Protection N.E.C. (Recurrent Expenditure)",
  community_development__recurrent_expenditure:
    "Community Development (Recurrent Expenditure)",
  water_supply__recurrent_expenditure: "Water Supply (Recurrent Expenditure)",
  street_light__recurrent_expenditure:
    "Street Lighting (Recurrent Expenditure)",
  rd_housing_community_amenities__recurrent_expenditure:
    "R&D Housing and Community Amenities (Recurrent Expenditure)",
  housing_community_amenities_nec__recurrent_expenditure:
    "Housing and Community Amenities N. E. C (Recurrent Expenditure)",
  medical_products_appliances_equiptment__recurrent_expenditure:
    "Medical Products, Appliances and Equipment (Recurrent Expenditure)",
  outpatient_services__recurrent_expenditure:
    "Outpatient Services (Recurrent Expenditure)",
  hospital_services__recurrent_expenditure:
    "Hospital Services (Recurrent Expenditure)",
  public_health_services__recurrent_expenditure:
    "Public Health Services (Recurrent Expenditure)",
  rd_health__recurrent_expenditure: "R&D Health (Recurrent Expenditure)",
  health_nec__recurrent_expenditure: "Health N. E. C (Recurrent Expenditure)",
  recreational_sporting_services__recurrent_expenditure:
    "Recreational and Sporting Services (Recurrent Expenditure)",
  cultural_services__recurrent_expenditure:
    "Cultural Services (Recurrent Expenditure)",
  broadcasting_publishing_services__recurrent_expenditure:
    "Broadcasting and Publishing Services (Recurrent Expenditure)",
  religous_other_community_services__recurrent_expenditure:
    "Religious and Other Community Services (Recurrent Expenditure)",
  rd_recreation_culture_religon__recurrent_expenditure:
    "R&D Recreation, Culture and Religion (Recurrent Expenditure)",
  recreation_culture_religon_nec__recurrent_expenditure:
    "Recreation, Culture and Religion N. E. C (Recurrent Expenditure)",
  preprimary_primary_education__recurrent_expenditure:
    "Pre-Primary and Primary Education (Recurrent Expenditure)",
  secondary_education__recurrent_expenditure:
    "Secondary Education (Recurrent Expenditure)",
  postsecondary_nontetiary_education__recurrent_expenditure:
    "Post-Secondary and Non Tertiary Education (Recurrent Expenditure)",
  tetiary_education__recurrent_expenditure:
    "Tertiary Education (Recurrent Expenditure)",
  education_nondefinable_by_level__recurrent_expenditure:
    "Education Not Definable by Level (Recurrent Expenditure)",
  subsidiary_services_to_education__recurrent_expenditure:
    "Subsidiary Services to Education (Recurrent Expenditure)",
  rd_education__recurrent_expenditure: "R&D Education (Recurrent Expenditure)",
  education_nec__recurrent_expenditure:
    "Education N. E. C (Recurrent Expenditure)",
  sickness_disability__recurrent_expenditure:
    "Sickness and Disability (Recurrent Expenditure)",
  old_age__recurrent_expenditure: "Old Age (Recurrent Expenditure)",
  survivors__recurrent_expenditure: "Survivors (Recurrent Expenditure)",
  family_and_children__recurrent_expenditure:
    "Family and Children (Recurrent Expenditure)",
  unemployment__recurrent_expenditure: "Unemployment (Recurrent Expenditure)",
  housing__recurrent_expenditure: "Housing (Recurrent Expenditure)",
  social_exclusion_nec__recurrent_expenditure:
    "Social Exclusion N. E. C (Recurrent Expenditure)",
  rd_social_protection__recurrent_expenditure:
    "R&D Social Protection (Recurrent Expenditure)",
  social_protection_nec__recurrent_expenditure:
    "Social Protection N. E. C (Recurrent Expenditure)",

  // Exp by func capital
  exp_by_func_capital: "Expenditure by Functions (Capital)",
  total_capital_expenditure: "Total Capital Expenditure",
  general_public_service_func: "General Public Service (Capital Expenditure)",
  public_order_safety_func: "Public Order and Safety (Capital Expenditure)",
  economic_affairs_func: "Economic Affairs (Capital Expenditure)",
  evironmental_protection_func:
    "Environmental Protection (Capital Expenditure)",
  housing_community_amenities_func:
    "Housing and Community Amenities (Capital Expenditure)",
  health_func: "Health (Capital Expenditure)",
  recreation_culture_religon_func:
    "Recreation, Culture and Religion (Capital Expenditure)",
  education_func: "Education (Capital Expenditure)",
  social_protection_func: "Social Protection (Capital Expenditure)",
  executive_legislative_organ_financial_external_func:
    "Executive & Legislative Organ, Financial Affairs and External Affairs (Capital Expenditure)",
  foreign_economic_aid__capital_expenditure_func:
    "Foreign and Economic Aid (Capital Expenditure)",
  general_services__capital_expenditure_func:
    "General Services (Capital Expenditure)",
  basic_research__capital_expenditure_func:
    "Basic Research (Capital Expenditure)",
  rd_general_public_services__capital_expenditure_func:
    "R&D General Public Services (Capital Expenditure)",
  general_public_servuces_nec__capital_expenditure_func:
    "General Public Services N.E.C (Capital Expenditure)",
  public_debt_transactions__capital_expenditure_func:
    "Public Debt Transactions (Capital Expenditure)",
  transfer_general_character_between_dif_levels_of_gov_func:
    "Transfer of a General Character between Different Levels of Government (Capital Expenditure)",
  police_services__capital_expenditure_func:
    "Police Services (Capital Expenditure)",
  fire_protection_services__capital_expenditure_func:
    "Fire Protection Services (Capital Expenditure)",
  justice_law_courts__capital_expenditure_func:
    "Justice & Law Courts (Capital Expenditure)",
  general_economic_commercial_labour_affairs_func:
    "General Economic, Commercial and Labour Affairs (Capital Expenditure)",
  agriculture_forestry_fishing_hunting__capital_expenditure_func:
    "Agriculture, Forestry, Fishing and Hunting (Capital Expenditure)",
  fuel_energy__capital_expenditure_func:
    "Fuel and Energy (Capital Expenditure)",
  mining_manufacturing_constru_funcction__capital_expenditure:
    "Mining, Manufacturing and Construction (Capital Expenditure)",
  transport__capital_expenditure_func: "Transport (Capital Expenditure)",
  communication__capital_expenditure_func:
    "Communication (Capital Expenditure)",
  other_inductries__capital_expenditure_func:
    "Other Industries (Capital Expenditure)",
  ed_economic_affairs__capital_expenditure_func:
    "R&D Economic Affairs (Capital Expenditure)",
  economic_affairs_nec__capital_expenditure_func:
    "Economic Affairs N. E. C (Capital Expenditure)",
  waste_management__capital_expenditure_func:
    "Waste Management (Capital Expenditure)",
  waste_water_management__capital_expenditure_func:
    "Waste Water Management (Capital Expenditure)",
  pollution_abatement__capital_expenditure_func:
    "Pollution Abatement (Capital Expenditure)",
  protection_biodiversity_landscape__capital_expenditure_func:
    "Protection of Biodiversity and Landscape (Capital Expenditure)",
  rd_environmental_protection__capital_expenditure_func:
    "R&D Environmental Protection (Capital Expenditure)",
  environmental_protection_nec__capital_expenditure_func:
    "Environmental Protection N.E.C. (Capital Expenditure)",
  community_development__capital_expenditure_func:
    "Community Development (Capital Expenditure)",
  water_supply__capital_expenditure_func: "Water Supply (Capital Expenditure)",
  street_light__capital_expenditure_func:
    "Street Lighting (Capital Expenditure)",
  rd_housing_community_amenities__capital_expenditure_func:
    "R&D Housing and Community Amenities (Capital Expenditure)",
  housing_community_amenities_nec__capital_expenditure_func:
    "Housing and Community Amenities N. E. C (Capital Expenditure)",
  medical_products_appliances_equiptment__capital_expenditure_func:
    "Medical Products, Appliances and Equipment (Capital Expenditure)",
  outpatient_services__capital_expenditure_func:
    "Outpatient Services (Capital Expenditure)",
  hospital_services__capital_expenditure_func:
    "Hospital Services (Capital Expenditure)",
  public_health_services__capital_expenditure_func:
    "Public Health Services (Capital Expenditure)",
  rd_health__capital_expenditure_func: "R&D Health (Capital Expenditure)",
  health_nec__capital_expenditure_func: "Health N. E. C (Capital Expenditure)",
  recreational_sporting_services__capital_expenditure_func:
    "Recreational and Sporting Services (Capital Expenditure)",
  cultural_services__capital_expenditure_func:
    "Cultural Services (Capital Expenditure)",
  broadcasting_publishing_services__capital_expenditure_func:
    "Broadcasting and Publishing Services (Capital Expenditure)",
  religous_other_community_services__capital_expenditure_func:
    "Religious and Other Community Services (Capital Expenditure)",
  rd_recreation_culture_religon__capital_expenditure_func:
    "R&D Recreation, Culture and Religion (Capital Expenditure)",
  recreation_culture_religon_nec__capital_expenditure_func:
    "Recreation, Culture and Religion N. E. C (Capital Expenditure)",
  preprimary_primary_education__capital_expenditure_func:
    "Pre-Primary and Primary Education (Capital Expenditure)",
  secondary_education__capital_expenditure_func:
    "Secondary Education (Capital Expenditure)",
  postsecondary_nontetiary_education__capital_expenditure_func:
    "Post-Secondary and Non Tertiary Education (Capital Expenditure)",
  tetiary_education__capital_expenditure_func:
    "Tertiary Education (Capital Expenditure)",
  education_nondefinable_by_level__capital_expenditure_func:
    "Education Not Definable by Level (Capital Expenditure)",
  subsidiary_services_to_education__capital_expenditure_func:
    "Subsidiary Services to Education (Capital Expenditure)",
  rd_education__capital_expenditure_func: "R&D Education (Capital Expenditure)",
  education_nec__capital_expenditure_func:
    "Education N. E. C (Capital Expenditure)",
  sickness_disability__capital_expenditure_func:
    "Sickness and Disability (Capital Expenditure)",
  old_age__capital_expenditure_func: "Old Age (Capital Expenditure)",
  survivors__capital_expenditure_func: "Survivors (Capital Expenditure)",
  family_and_children__capital_expenditure_func:
    "Family and Children (Capital Expenditure)",
  unemployment__capital_expenditure_func: "Unemployment (Capital Expenditure)",
  housing__capital_expenditure_func: "Housing (Capital Expenditure)",
  social_exclusion_nec__capital_expenditure_func:
    "Social Exclusion N. E. C (Capital Expenditure)",
  rd_social_protection__capital_expenditure_func:
    "R&D Social Protection (Capital Expenditure)",
  social_protection_nec__capital_expenditure_func:
    "Social Protection N. E. C (Capital Expenditure)",
};
