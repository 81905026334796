import React, { useState } from "react";
import AddNew from "./AddNew";
import DataTable from "./DataTable";
import styles from "../styles/styles.module.css";
import httpService from "../../../service/httpService";

const initialValues = {
  type: "",
  year: "",
  file: [],
};
const Content = () => {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [formData, setFormData] = useState(initialValues);

  const handleSubmit = async () => {
    const file = new FormData();
    file.append("budget-sheet", formData?.file);
    try {
      setLoading(true);
      await httpService.post("/budget/upload", file);
      setFormData(initialValues);
      httpService.showSuccess("File uploaded successfully");
    } catch (error) {
      setLoading(false);
      httpService.showFeedback(error);
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.toggle_wrapper}>
        <h2>Upload Data</h2>
      </div>
      <AddNew
        formData={formData}
        loading={loading}
        setFormData={setFormData}
        handleSubmit={handleSubmit}
      />
      <DataTable styles={styles} reload={reload} setReload={setReload} />
    </div>
  );
};

export default Content;
