import axios from "axios";
import React, { useEffect, useState } from "react";

// import { budgets } from "../../../budgets";

// const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });

const useFetch = () => {
  const [snakOpen, setSnakOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectBudgetType, setSelectBudgetType] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedState, setSelectedStates] = useState([]);
  const [fetchedData] = useState([]);
  const [sortBy, setSortBy] = useState("ascending");
  const [isLoading, setIsLoading] = useState(false);
  const categories = selectedCategories.map((item) =>
    item.split(" ").join("_").toLowerCase()
  );
  const [data, setData] = useState([]);
  const [budgets, setBudgets] = useState([]);
  const [generalData, setGeneralData] = useState([]);
  const [rankData, setRankData] = useState([]);
  const [PI, setPI] = useState([]);
  const [searchedCategory, setSearchCategory] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");

  const stateArray = selectedState
    .map((el) => {
      return `state=${el?.split(" ").join("_")?.toUpperCase()}`;
    })
    .join("&");

  const url = `https://www.pfmapi.ngf.org.ng/api/v1/${categories[0]}/filter?type=${selectBudgetType}&year=${selectedYear}`;
  const budgetUrl = `https://www.pfmapi.ngf.org.ng/api/v1/budget/fetch?year=${selectedYear}&type=${selectBudgetType}&${stateArray}`;
  const piBudgetUrl = `https://www.pfmapi.ngf.org.ng/api/v1/budget/fetch-${selectBudgetType}?year=${selectedYear}&${stateArray}`;
  const indicatorsUrl = `https://www.pfmapi.ngf.org.ng/api/v1/budget/fetch?year=${selectedYear}&type=pi&${stateArray}`;
  const csv_url = `https://www.pfmapi.ngf.org.ng/api/v1/budget/fetch?year=${selectedYear}&type=${selectBudgetType}&${stateArray}&download=csv`;
  const pdf_url = `https://www.pfmapi.ngf.org.ng/api/v1/budget/fetch?year=${selectedYear}&type=${selectBudgetType}&${stateArray}&download=xlsx `;

  selectedState.map((item) => item?.toLowerCase());

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await axios.get(url);
    } catch (error) {}
  };

  const popItems = {
    searchedCategory,
    setSearchCategory,
    selectedCategory,
    setSelectedCategory,
    selectedYear,
    selectBudgetType,
    stateArray,
  };

  const rankDataFormat = rankData?.map((item, index) => {
    const {
      total_revenue,
      aids_grants,
      aids_grants: {
        domestic_aids,
        domestic_grants,
        foreign_aids,
        foreign_grants,
      },
      independent_revenue,
      capital_development_fund_receipts,
      independent_revenue: {
        tax_revenue,
        personal_taxes,
        other_taxes,
        non_tax_revenue,
        licences_general,
        fees_general,
        fines_general,
        earnings_general,
        rent_on_gov_buildings_general,
        rent_on_land_and_others_general,
        repayments,
        investment_income,
        interest_earned,
        reimbursement,
        miscellaneous_income,
      },
    } = item[0]?.revenue_by_economuc;

    const {
      personal_expenditure,
      personal_expenditure: {
        personal_cost,
        salaries_wages_general,
        social_benefit,
        allowances,
        social_contribution,
      },
      other_recurrent_expenditure,
      other_recurrent_expenditure: {
        overhead_costs,
        transport_and_travelling_general,
        grants_and_contribution_general,
        subsidies_general,
        public_debt_charges,

        utilities_general,
        materials_and_supplies_general,
        maintainance_services_general,
        training_general,
        other_services_general,
        consulting_and_professional_services_general,
        fuel_and_lubricant_general,
        financial_general,
        miscellaneous_general,
        loans_and_advances,
        staff_loans_and_allowances,
      },
      capital_expenditure,
      capital_expenditure: { fixed_assets_purchased, construction_provision },
    } = item[0]?.exp_by_economic[0];

    const { statutory_allocation, oil_gas_derivation } =
      total_revenue?.state_government_share_of_statutory_allocation;

    const {
      total_recurrent_expenditure: {
        administration_sector__recurrent_expenditure,
        economic_sector__recurrent_expenditure,
        law_and_justice_sector__recurrent_expenditure,
        regional_sector__recurrent_expenditure,
        social_sector__recurrent_expenditure,
      },
    } = item[0]?.exp_by_admin_recurrent[0];
    const {
      total__capital_expenditure: {
        administration_sector__capital_expenditure,
        economic_sector__capital_expenditure,
        law_and_justice_sector__capital_expenditure,
        regional_sector__capital_expenditure,
        social_sector__capital_expenditure,
      },
    } = item[0]?.exp_by_admin_capital[0];

    const {
      total_recurrent_expenditure,
      general_public_service,
      public_order_safety,
      economic_affairs,
      evironmental_protection,
      housing_community_amenities,
      health,
      recreation_culture_religon,
      education,
      social_protection,
      general_public_service: {
        executive_legislative_organ_financial_external,
        foreign_economic_aid__recurrent_expenditure,
        general_services__recurrent_expenditure,
        basic_research__recurrent_expenditure,
        rd_general_public_services__recurrent_expenditure,
        general_public_servuces_nec__recurrent_expenditure,
        public_debt_transactions__recurrent_expenditure,
        transfer_general_character_between_dif_levels_of_gov,
      },
      public_order_safety: {
        police_services__recurrent_expenditure,
        fire_protection_services__recurrent_expenditure,
        justice_law_courts__recurrent_expenditure,
      },
      economic_affairs: {
        general_economic_commercial_labour_affairs,
        agriculture_forestry_fishing_hunting__recurrent_expenditure,
        mining_manufacturing_construction__recurrent_expenditure,
        fuel_energy__recurrent_expenditure,
        transport__recurrent_expenditure,
        communication__recurrent_expenditure,
        other_inductries__recurrent_expenditure,
        ed_economic_affairs__recurrent_expenditure,
        economic_affairs_nec__recurrent_expenditure,
      },
      evironmental_protection: {
        waste_management__recurrent_expenditure,
        waste_water_management__recurrent_expenditure,
        pollution_abatement__recurrent_expenditure,
        protection_biodiversity_landscape__recurrent_expenditure,
        rd_environmental_protection__recurrent_expenditure,
        environmental_protection_nec__recurrent_expenditure,
      },
      housing_community_amenities: {
        community_development__recurrent_expenditure,
        water_supply__recurrent_expenditure,
        street_light__recurrent_expenditure,
        rd_housing_community_amenities__recurrent_expenditure,
        housing_community_amenities_nec__recurrent_expenditure,
      },
      health: {
        medical_products_appliances_equiptment__recurrent_expenditure,
        outpatient_services__recurrent_expenditure,
        hospital_services__recurrent_expenditure,
        public_health_services__recurrent_expenditure,
        rd_health__recurrent_expenditure,
        health_nec__recurrent_expenditure,
      },
      recreation_culture_religon: {
        recreational_sporting_services__recurrent_expenditure,
        cultural_services__recurrent_expenditure,
        broadcasting_publishing_services__recurrent_expenditure,
        religous_other_community_services__recurrent_expenditure,
        rd_recreation_culture_religon__recurrent_expenditure,
        recreation_culture_religon_nec__recurrent_expenditure,
      },
      education: {
        preprimary_primary_education__recurrent_expenditure,
        secondary_education__recurrent_expenditure,
        postsecondary_nontetiary_education__recurrent_expenditure,
        tetiary_education__recurrent_expenditure,
        education_nondefinable_by_level__recurrent_expenditure,
        subsidiary_services_to_education__recurrent_expenditure,
        rd_education__recurrent_expenditure,
        education_nec__recurrent_expenditure,
      },
      social_protection: {
        sickness_disability__recurrent_expenditure,
        old_age__recurrent_expenditure,
        survivors__recurrent_expenditure,
        family_and_children__recurrent_expenditure,
        unemployment__recurrent_expenditure,
        housing__recurrent_expenditure,
        social_exclusion_nec__recurrent_expenditure,
        rd_social_protection__recurrent_expenditure,
        social_protection_nec__recurrent_expenditure,
      },
    } = item[0]?.exp_by_func_recurrent[0];

    const {
      total_capital_expenditure,
      general_public_service: general_public_service_func,
      public_order_safety: public_order_safety_func,
      economic_affairs: economic_affairs_func,
      evironmental_protection: evironmental_protection_func,
      housing_community_amenities: housing_community_amenities_func,
      health: health_func,
      recreation_culture_religon: recreation_culture_religon_func,
      education: education_func,
      social_protection: social_protection_func,
      general_public_service: {
        executive_legislative_organ_financial_external:
          executive_legislative_organ_financial_external_func,
        foreign_economic_aid__capital_expenditure:
          foreign_economic_aid__capital_expenditure_func,
        general_services__capital_expenditure:
          general_services__capital_expenditure_func,
        basic_research__capital_expenditure:
          basic_research__capital_expenditure_func,
        rd_general_public_services__capital_expenditure:
          rd_general_public_services__capital_expenditure_func,
        general_public_servuces_nec__capital_expenditure:
          general_public_servuces_nec__capital_expenditure_func,
        public_debt_transactions__capital_expenditure:
          public_debt_transactions__capital_expenditure_func,
        transfer_general_character_between_dif_levels_of_gov:
          transfer_general_character_between_dif_levels_of_gov_func,
      },
      public_order_safety: {
        police_services__capital_expenditure:
          police_services__capital_expenditure_func,
        fire_protection_services__capital_expenditure:
          fire_protection_services__capital_expenditure_func,
        justice_law_courts__capital_expenditure:
          justice_law_courts__capital_expenditure_func,
      },
      economic_affairs: {
        general_economic_commercial_labour_affairs:
          general_economic_commercial_labour_affairs_func,
        agriculture_forestry_fishing_hunting__capital_expenditure:
          agriculture_forestry_fishing_hunting__capital_expenditure_func,
        mining_manufacturing_construction__capital_expenditure:
          mining_manufacturing_construction__capital_expenditure_func,
        fuel_energy__capital_expenditure: fuel_energy__capital_expenditure_func,
        transport__capital_expenditure: transport__capital_expenditure_func,
        communication__capital_expenditure:
          communication__capital_expenditure_func,
        other_inductries__capital_expenditure:
          other_inductries__capital_expenditure_func,
        ed_economic_affairs__capital_expenditure:
          ed_economic_affairs__capital_expenditure_func,
        economic_affairs_nec__capital_expenditure:
          economic_affairs_nec__capital_expenditure_func,
      },
      evironmental_protection: {
        waste_management__capital_expenditure:
          waste_management__capital_expenditure_func,
        waste_water_management__capital_expenditure:
          waste_water_management__capital_expenditure_func,
        pollution_abatement__capital_expenditure:
          pollution_abatement__capital_expenditure_func,
        protection_biodiversity_landscape__capital_expenditure:
          protection_biodiversity_landscape__capital_expenditure_func,
        rd_environmental_protection__capital_expenditure:
          rd_environmental_protection__capital_expenditure_func,
        environmental_protection_nec__capital_expenditure:
          environmental_protection_nec__capital_expenditure_func,
      },
      housing_community_amenities: {
        community_development__capital_expenditure:
          community_development__capital_expenditure_func,
        water_supply__capital_expenditure:
          water_supply__capital_expenditure_func,
        street_light__capital_expenditure:
          street_light__capital_expenditure_func,
        rd_housing_community_amenities__capital_expenditure:
          rd_housing_community_amenities__capital_expenditure_func,
        housing_community_amenities_nec__capital_expenditure:
          housing_community_amenities_nec__capital_expenditure_func,
      },
      health: {
        medical_products_appliances_equiptment__capital_expenditure:
          medical_products_appliances_equiptment__capital_expenditure_func,
        outpatient_services__capital_expenditure:
          outpatient_services__capital_expenditure_func,
        hospital_services__capital_expenditure:
          hospital_services__capital_expenditure_func,
        public_health_services__capital_expenditure:
          public_health_services__capital_expenditure_func,
        rd_health__capital_expenditure: rd_health__capital_expenditure_func,
        health_nec__capital_expenditure: health_nec__capital_expenditure_func,
      },
      recreation_culture_religon: {
        recreational_sporting_services__capital_expenditure:
          recreational_sporting_services__capital_expenditure_func,
        cultural_services__capital_expenditure:
          cultural_services__capital_expenditure_func,
        broadcasting_publishing_services__capital_expenditure:
          broadcasting_publishing_services__capital_expenditure_func,
        religous_other_community_services__capital_expenditure:
          religous_other_community_services__capital_expenditure_func,
        rd_recreation_culture_religon__capital_expenditure:
          rd_recreation_culture_religon__capital_expenditure_func,
        recreation_culture_religon_nec__capital_expenditure:
          recreation_culture_religon_nec__capital_expenditure_func,
      },
      education: {
        preprimary_primary_education__capital_expenditure:
          preprimary_primary_education__capital_expenditure_func,
        secondary_education__capital_expenditure:
          secondary_education__capital_expenditure_func,
        postsecondary_nontetiary_education__capital_expenditure:
          postsecondary_nontetiary_education__capital_expenditure_func,
        tetiary_education__capital_expenditure:
          tetiary_education__capital_expenditure_func,
        education_nondefinable_by_level__capital_expenditure:
          education_nondefinable_by_level__capital_expenditure_func,
        subsidiary_services_to_education__capital_expenditure:
          subsidiary_services_to_education__capital_expenditure_func,
        rd_education__capital_expenditure:
          rd_education__capital_expenditure_func,
        education_nec__capital_expenditure:
          education_nec__capital_expenditure_func,
      },
      social_protection: {
        sickness_disability__capital_expenditure:
          sickness_disability__capital_expenditure_func,
        old_age__capital_expenditure: old_age__capital_expenditure_func,
        survivors__capital_expenditure: survivors__capital_expenditure_func,
        family_and_children__capital_expenditure:
          family_and_children__capital_expenditure_func,
        unemployment__capital_expenditure:
          unemployment__capital_expenditure_func,
        housing__capital_expenditure: housing__capital_expenditure_func,
        social_exclusion_nec__capital_expenditure:
          social_exclusion_nec__capital_expenditure_func,
        rd_social_protection__capital_expenditure:
          rd_social_protection__capital_expenditure_func,
        social_protection_nec__capital_expenditure:
          social_protection_nec__capital_expenditure_func,
      },
    } = item[0]?.exp_by_func_capital[0];

    return {
      rank: index + 1,
      state: item[0]?.revenue_by_economuc?.state,
      type: item[0]?.revenue_by_economuc?.type,
      total_revenue: total_revenue?.value,
      government_share_of_federation_revenue_faac:
        total_revenue?.government_share_of_federation_revenue_faac?.value,
      state_government_of_statutory_allocation:
        total_revenue?.state_government_share_of_statutory_allocation?.value,
      statutory_allocation: statutory_allocation?.value,
      oil_gas_derivation: oil_gas_derivation?.value,
      government_share_of_value_added_tax_vat:
        total_revenue?.government_share_of_value_added_tax_vat?.value,
      government_share_of_other_federation_revenues:
        total_revenue?.government_share_of_other_federation_revenues?.value,

      // independent Revenue
      independent_revenue: independent_revenue?.value,
      tax_revenue: tax_revenue?.value,
      non_tax_revenue: non_tax_revenue?.value,
      personal_taxes: personal_taxes?.value,
      other_taxes: other_taxes?.value,
      aids_grants: aids_grants?.value,
      domestic_aids: domestic_aids?.value,
      domestic_grants: domestic_grants?.value,
      foreign_aids: foreign_aids?.value,
      foreign_grants: foreign_grants?.value,
      licences_general: licences_general?.value,
      capital_development_fund_receipts:
        capital_development_fund_receipts?.value,
      fees_general: fees_general?.value,
      fines_general: fines_general?.value,
      earnings_general: earnings_general?.value,
      rent_on_gov_buildings_general: rent_on_gov_buildings_general?.value,
      rent_on_land_and_others_general: rent_on_land_and_others_general?.value,
      repayments: repayments?.value,
      investment_income: investment_income?.value,
      interest_earned: interest_earned?.value,
      reimbursement: reimbursement?.value,
      miscellaneous_income: miscellaneous_income?.value,

      // Exp By Economic
      personal_expenditure: personal_expenditure?.value,
      other_recurrent_expenditure: other_recurrent_expenditure?.value,
      overhead_costs: overhead_costs?.value,
      transport_and_travelling_general: transport_and_travelling_general?.value,
      capital_expenditure: capital_expenditure?.value,
      fixed_assets_purchased: fixed_assets_purchased?.value,
      construction_provision: construction_provision?.value,
      personal_cost: personal_cost?.value,
      allowances: allowances?.value,
      salaries_wages_general: salaries_wages_general?.value,
      social_benefit: social_benefit?.value,
      social_contribution: social_contribution?.value,
      grants_and_contribution_general: grants_and_contribution_general?.value,
      subsidies_general: subsidies_general?.value,
      public_debt_charges: public_debt_charges?.value,
      utilities_general: utilities_general?.value,
      materials_and_supplies_general: materials_and_supplies_general?.value,
      maintainance_services_general: maintainance_services_general?.value,
      training_general: training_general?.value,
      other_services_general: other_services_general?.value,
      consulting_and_professional_services_general:
        consulting_and_professional_services_general?.value,
      fuel_and_lubricant_general: fuel_and_lubricant_general?.value,
      financial_general: financial_general?.value,
      miscellaneous_general: miscellaneous_general?.value,
      loans_and_advances: loans_and_advances?.value,
      staff_loans_and_allowances: staff_loans_and_allowances?.value,
      // Exp by admin Rec
      administration_sector__recurrent_expenditure:
        administration_sector__recurrent_expenditure?.value,
      economic_sector__recurrent_expenditure:
        economic_sector__recurrent_expenditure?.value,
      law_and_justice_sector__recurrent_expenditure:
        law_and_justice_sector__recurrent_expenditure?.value,
      regional_sector__recurrent_expenditure:
        regional_sector__recurrent_expenditure?.value,
      social_sector__recurrent_expenditure:
        social_sector__recurrent_expenditure?.value,
      // Expenditure by Administrative (Capital)
      administration_sector__capital_expenditure:
        administration_sector__capital_expenditure?.value,
      economic_sector__capital_expenditure:
        economic_sector__capital_expenditure?.value,
      law_and_justice_sector__capital_expenditure:
        law_and_justice_sector__capital_expenditure?.value,
      regional_sector__capital_expenditure:
        regional_sector__capital_expenditure?.value,
      social_sector__capital_expenditure:
        social_sector__capital_expenditure?.value,
      // Expenditure by Functions (Recurrent)

      total_recurrent_expenditure: total_recurrent_expenditure?.value,
      general_public_service: general_public_service?.value,
      public_order_safety: public_order_safety?.value,
      economic_affairs: economic_affairs?.value,
      evironmental_protection: evironmental_protection?.value,
      housing_community_amenities: housing_community_amenities?.value,
      health: health?.value,
      recreation_culture_religon: recreation_culture_religon?.value,
      education: education?.value,
      social_protection: social_protection?.value,
      executive_legislative_organ_financial_external:
        executive_legislative_organ_financial_external?.value,
      foreign_economic_aid__recurrent_expenditure:
        foreign_economic_aid__recurrent_expenditure?.value,
      general_services__recurrent_expenditure:
        general_services__recurrent_expenditure?.value,
      basic_research__recurrent_expenditure:
        basic_research__recurrent_expenditure?.value,
      // 2
      rd_general_public_services__recurrent_expenditure:
        rd_general_public_services__recurrent_expenditure?.value,
      general_public_servuces_nec__recurrent_expenditure:
        general_public_servuces_nec__recurrent_expenditure?.value,
      public_debt_transactions__recurrent_expenditure:
        public_debt_transactions__recurrent_expenditure?.value,
      transfer_general_character_between_dif_levels_of_gov:
        transfer_general_character_between_dif_levels_of_gov?.value,
      police_services__recurrent_expenditure:
        police_services__recurrent_expenditure?.value,
      fire_protection_services__recurrent_expenditure:
        fire_protection_services__recurrent_expenditure?.value,
      justice_law_courts__recurrent_expenditure:
        justice_law_courts__recurrent_expenditure?.value,
      general_economic_commercial_labour_affairs:
        general_economic_commercial_labour_affairs?.value,
      agriculture_forestry_fishing_hunting__recurrent_expenditure:
        agriculture_forestry_fishing_hunting__recurrent_expenditure?.value,
      fuel_energy__recurrent_expenditure:
        fuel_energy__recurrent_expenditure?.value,
      mining_manufacturing_construction__recurrent_expenditure:
        mining_manufacturing_construction__recurrent_expenditure?.value,
      transport__recurrent_expenditure: transport__recurrent_expenditure?.value,
      communication__recurrent_expenditure:
        communication__recurrent_expenditure?.value,
      other_inductries__recurrent_expenditure:
        other_inductries__recurrent_expenditure?.value,
      ed_economic_affairs__recurrent_expenditure:
        ed_economic_affairs__recurrent_expenditure?.value,
      // 3
      economic_affairs_nec__recurrent_expenditure:
        economic_affairs_nec__recurrent_expenditure?.value,
      waste_management__recurrent_expenditure:
        waste_management__recurrent_expenditure?.value,
      waste_water_management__recurrent_expenditure:
        waste_water_management__recurrent_expenditure?.value,
      pollution_abatement__recurrent_expenditure:
        pollution_abatement__recurrent_expenditure?.value,
      protection_biodiversity_landscape__recurrent_expenditure:
        protection_biodiversity_landscape__recurrent_expenditure?.value,
      rd_environmental_protection__recurrent_expenditure:
        rd_environmental_protection__recurrent_expenditure?.value,
      environmental_protection_nec__recurrent_expenditure:
        environmental_protection_nec__recurrent_expenditure?.value,
      community_development__recurrent_expenditure:
        community_development__recurrent_expenditure?.value,
      water_supply__recurrent_expenditure:
        water_supply__recurrent_expenditure?.value,
      street_light__recurrent_expenditure:
        street_light__recurrent_expenditure?.value,
      rd_housing_community_amenities__recurrent_expenditure:
        rd_housing_community_amenities__recurrent_expenditure?.value,
      housing_community_amenities_nec__recurrent_expenditure:
        housing_community_amenities_nec__recurrent_expenditure?.value,
      medical_products_appliances_equiptment__recurrent_expenditure:
        medical_products_appliances_equiptment__recurrent_expenditure?.value,
      outpatient_services__recurrent_expenditure:
        outpatient_services__recurrent_expenditure?.value,
      hospital_services__recurrent_expenditure:
        hospital_services__recurrent_expenditure?.value,
      public_health_services__recurrent_expenditure:
        public_health_services__recurrent_expenditure?.value,
      rd_health__recurrent_expenditure: rd_health__recurrent_expenditure?.value,
      health_nec__recurrent_expenditure:
        health_nec__recurrent_expenditure?.value,
      recreational_sporting_services__recurrent_expenditure:
        recreational_sporting_services__recurrent_expenditure?.value,
      cultural_services__recurrent_expenditure:
        cultural_services__recurrent_expenditure?.value,
      // 4
      broadcasting_publishing_services__recurrent_expenditure:
        broadcasting_publishing_services__recurrent_expenditure?.value,
      religous_other_community_services__recurrent_expenditure:
        religous_other_community_services__recurrent_expenditure?.value,
      rd_recreation_culture_religon__recurrent_expenditure:
        rd_recreation_culture_religon__recurrent_expenditure?.value,
      recreation_culture_religon_nec__recurrent_expenditure:
        recreation_culture_religon_nec__recurrent_expenditure?.value,
      preprimary_primary_education__recurrent_expenditure:
        preprimary_primary_education__recurrent_expenditure?.value,
      secondary_education__recurrent_expenditure:
        secondary_education__recurrent_expenditure?.value,
      postsecondary_nontetiary_education__recurrent_expenditure:
        postsecondary_nontetiary_education__recurrent_expenditure?.value,
      tetiary_education__recurrent_expenditure:
        tetiary_education__recurrent_expenditure?.value,
      education_nondefinable_by_level__recurrent_expenditure:
        education_nondefinable_by_level__recurrent_expenditure?.value,
      subsidiary_services_to_education__recurrent_expenditure:
        subsidiary_services_to_education__recurrent_expenditure?.value,
      rd_education__recurrent_expenditure:
        rd_education__recurrent_expenditure?.value,
      education_nec__recurrent_expenditure:
        education_nec__recurrent_expenditure?.value,
      sickness_disability__recurrent_expenditure:
        sickness_disability__recurrent_expenditure?.value,
      old_age__recurrent_expenditure: old_age__recurrent_expenditure?.value,
      survivors__recurrent_expenditure: survivors__recurrent_expenditure?.value,
      family_and_children__recurrent_expenditure:
        family_and_children__recurrent_expenditure?.value,
      unemployment__recurrent_expenditure:
        unemployment__recurrent_expenditure?.value,
      housing__recurrent_expenditure: housing__recurrent_expenditure?.value,
      social_exclusion_nec__recurrent_expenditure:
        social_exclusion_nec__recurrent_expenditure?.value,
      rd_social_protection__recurrent_expenditure:
        rd_social_protection__recurrent_expenditure?.value,
      social_protection_nec__recurrent_expenditure:
        social_protection_nec__recurrent_expenditure?.value,

      // Expenditure by Functions (Capital)
      total_capital_expenditure: total_capital_expenditure?.value,
      general_public_service_func: general_public_service_func?.value,
      public_order_safety_func: public_order_safety_func?.value,
      economic_affairs_func: economic_affairs_func?.value,
      evironmental_protection_func: evironmental_protection_func?.value,
      housing_community_amenities_func: housing_community_amenities_func?.value,
      health_func: health_func?.value,
      recreation_culture_religon_func: recreation_culture_religon_func?.value,
      education_func: education_func?.value,
      social_protection_func: social_protection_func?.value,
      executive_legislative_organ_financial_external_func:
        executive_legislative_organ_financial_external_func?.value,
      foreign_economic_aid__capital_expenditure_func:
        foreign_economic_aid__capital_expenditure_func?.value,
      general_services__capital_expenditure_func:
        general_services__capital_expenditure_func?.value,
      basic_research__capital_expenditure_func:
        basic_research__capital_expenditure_func?.value,
      // 2
      rd_general_public_services__capital_expenditure_func:
        rd_general_public_services__capital_expenditure_func?.value,
      general_public_servuces_nec__capital_expenditure_func:
        general_public_servuces_nec__capital_expenditure_func?.value,
      public_debt_transactions__capital_expenditure_func:
        public_debt_transactions__capital_expenditure_func?.value,
      transfer_general_character_between_dif_levels_of_gov_func:
        transfer_general_character_between_dif_levels_of_gov_func?.value,
      police_services__capital_expenditure_func:
        police_services__capital_expenditure_func?.value,
      fire_protection_services__capital_expenditure_func:
        fire_protection_services__capital_expenditure_func?.value,
      justice_law_courts__capital_expenditure_func:
        justice_law_courts__capital_expenditure_func?.value,
      general_economic_commercial_labour_affairs_func:
        general_economic_commercial_labour_affairs_func?.value,
      agriculture_forestry_fishing_hunting__capital_expenditure_func:
        agriculture_forestry_fishing_hunting__capital_expenditure_func?.value,
      fuel_energy__capital_expenditure_func:
        fuel_energy__capital_expenditure_func?.value,
      mining_manufacturing_construction__capital_expenditure_func:
        mining_manufacturing_construction__capital_expenditure_func?.value,
      transport__capital_expenditure_func:
        transport__capital_expenditure_func?.value,
      communication__capital_expenditure_func:
        communication__capital_expenditure_func?.value,
      other_inductries__capital_expenditure_func:
        other_inductries__capital_expenditure_func?.value,
      ed_economic_affairs__capital_expenditure_func:
        ed_economic_affairs__capital_expenditure_func?.value,
      // 3
      economic_affairs_nec__capital_expenditure_func:
        economic_affairs_nec__capital_expenditure_func?.value,
      waste_management__capital_expenditure_func:
        waste_management__capital_expenditure_func?.value,
      waste_water_management__capital_expenditure:
        waste_water_management__capital_expenditure_func?.value,
      pollution_abatement__capital_expenditure_func:
        pollution_abatement__capital_expenditure_func?.value,
      protection_biodiversity_landscape__capital_expenditure_func:
        protection_biodiversity_landscape__capital_expenditure_func?.value,
      rd_environmental_protection__capital_expenditure_func:
        rd_environmental_protection__capital_expenditure_func?.value,
      environmental_protection_nec__capital_expenditure_func:
        environmental_protection_nec__capital_expenditure_func?.value,
      community_development__capital_expenditure:
        community_development__capital_expenditure_func?.value,
      water_supply__capital_expenditure_func:
        water_supply__capital_expenditure_func?.value,
      street_light__capital_expenditure_func:
        street_light__capital_expenditure_func?.value,
      rd_housing_community_amenities__capital_expenditure_func:
        rd_housing_community_amenities__capital_expenditure_func?.value,
      housing_community_amenities_nec__capital_expenditure_func:
        housing_community_amenities_nec__capital_expenditure_func?.value,
      medical_products_appliances_equiptment__capital_expenditure_func:
        medical_products_appliances_equiptment__capital_expenditure_func?.value,
      outpatient_services__capital_expenditure_func:
        outpatient_services__capital_expenditure_func?.value,
      hospital_services__capital_expenditure_func:
        hospital_services__capital_expenditure_func?.value,
      public_health_services__capital_expenditure_func:
        public_health_services__capital_expenditure_func?.value,
      rd_health__capital_expenditure_func:
        rd_health__capital_expenditure_func?.value,
      health_nec__capital_expenditure_func:
        health_nec__capital_expenditure_func?.value,
      recreational_sporting_services__capital_expenditure_func:
        recreational_sporting_services__capital_expenditure_func?.value,
      cultural_services__capital_expenditure_func:
        cultural_services__capital_expenditure_func?.value,
      // 4
      broadcasting_publishing_services__capital_expenditure_func:
        broadcasting_publishing_services__capital_expenditure_func?.value,
      religous_other_community_services__capital_expenditure_func:
        religous_other_community_services__capital_expenditure_func?.value,
      rd_recreation_culture_religon__capital_expenditure_func:
        rd_recreation_culture_religon__capital_expenditure_func?.value,
      recreation_culture_religon_nec__capital_expenditure_func:
        recreation_culture_religon_nec__capital_expenditure_func?.value,
      preprimary_primary_education__capital_expenditure_func:
        preprimary_primary_education__capital_expenditure_func?.value,
      secondary_education__capital_expenditure_func:
        secondary_education__capital_expenditure_func?.value,
      postsecondary_nontetiary_education__capital_expenditure_func:
        postsecondary_nontetiary_education__capital_expenditure_func?.value,
      tetiary_education__capital_expenditure_func:
        tetiary_education__capital_expenditure_func?.value,
      education_nondefinable_by_level__capital_expenditure_func:
        education_nondefinable_by_level__capital_expenditure_func?.value,
      subsidiary_services_to_education__capital_expenditure_func:
        subsidiary_services_to_education__capital_expenditure_func?.value,
      rd_education__capital_expenditure_func:
        rd_education__capital_expenditure_func?.value,
      education_nec__capital_expenditure_func:
        education_nec__capital_expenditure_func?.value,
      sickness_disability__capital_expenditure_func:
        sickness_disability__capital_expenditure_func?.value,
      old_age__capital_expenditure_func:
        old_age__capital_expenditure_func?.value,
      survivors__capital_expenditure_func:
        survivors__capital_expenditure_func?.value,
      family_and_children__capital_expenditure_func:
        family_and_children__capital_expenditure_func?.value,
      unemployment__capital_expenditure_func:
        unemployment__capital_expenditure_func?.value,
      housing__capital_expenditure_func:
        housing__capital_expenditure_func?.value,
      social_exclusion_nec__capital_expenditure_func:
        social_exclusion_nec__capital_expenditure_func?.value,
      rd_social_protection__capital_expenditure_func:
        rd_social_protection__capital_expenditure_func?.value,
      social_protection_nec__capital_expenditure_func:
        social_protection_nec__capital_expenditure_func?.value,
    };
  });

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const uniqueCategories = [...new Set(selectedCategories)];
  const mapped = rankDataFormat
    ?.map((item) => {
      return {
        state: item?.state.split("_").join(" ").toLowerCase(),
        type: item?.type,
        amount: item[`${selectedCategory}`],
        category: selectedCategory,
        year: selectedYear,
      };
    })
    .sort((a, b) => {
      if (a?.amount < b?.amount) return 1;
      if (a?.amount > b?.amount) return -1;
      return 0;
    })
    .map((item, index) => {
      return {
        ...item,
        rank: ordinal_suffix_of(index + 1),
      };
    });

  const formatData = mapped.sort((a, b) =>
    sortBy === "ascending" ? b?.amount - a?.amount : a.amount - b.amount
  );

  const rankDataFormatPI = generalData?.map((item) => {
    const {
      aids_grant_performance,
      budget_performance,
      capital_expenditure_performance,
      capital_reciepts_cdf_performance,
      federation_revenue_performance,
      igr_performance,
      other_recurrent_expenditure_performance,
      personnel_expenditure_performance,
      recurrent_revenue_performance,
      state,
      total_expenditure_performance,
      total_revenue_performance_excluding_opening_balance,
      year,
    } = item?.indicators[0];

    const {
      recurrent_revenue,
      federation_revenue,
      satutory_allocation,
      derivation,
      vat,
      other_federation_transfers,
      igr,
      tax_revenue,
      personal_taxes,
      personal_income_tax_paye,
      personal_income_tax_dat,
      other_personal_tax_nec,
      other_taxes,
      sales_taxes,
      lottery_tax,
      property_tax,
      capital_gain_tax,
      withholding_tax,
      other_taxes_nec,
      non_tax_revenue,
      licances_general,
      fees_general,
      fines_general,
      sales_general,
      earnings_general,
      rent_on_government_building_general,
      repayments,
      investment_income,
      interest_earned,
      reimbursement,
      miscellaneous_income,
      aids_grants,
      domestic_aids,
      foreign_aids,
      domestic_grants,
      foreign_grants,
      capital_development_fund,
      sales_of_fixed_assets,
      domestic_loans_from_financial_instituitions,
      domestic_loans_from_other_government_entities,
      domestic_loans_from_other_entities,
      international_loans_from_financial_instituitions,
      internaitional_loans_from_other_government_entities,
      internaitional_loans_from_other_entities,
      foreign_debt_forgiveness,
      domestic_debt_foregiveness,
      gain_on_disposal_asset_ppe,
      gain_on_disposal_asset_investment_property,
      minority_interest_share_of_surplus,
      extraordinary_items,
      unspecified_revenue,
    } = item?.revenues_percantage_total_revenue[0];

    const {
      recurrent_revenue: recurrent_revenue_exp,
      federation_revenue: federation_revenue_exp,
      satutory_allocation: satutory_allocation_exp,
      derivation: derivation_exp,
      vat: vat_exp,
      other_federation_transfers: other_federation_transfers_exp,
      igr: igr_exp,
      tax_revenue: tax_revenue_exp,
      personal_taxes: personal_taxes_exp,
      personal_income_tax_paye: personal_income_tax_paye_exp,
      personal_income_tax_dat: personal_income_tax_dat_exp,
      other_personal_tax_nec: other_personal_tax_nec_exp,
      other_taxes: other_taxes_exp,
      sales_taxes: sales_taxes_exp,
      lottery_tax: lottery_tax_exp,
      property_tax: property_tax_exp,
      capital_gain_tax: capital_gain_tax_exp,
      withholding_tax: withholding_tax_exp,
      other_taxes_nec: other_taxes_nec_exp,
      non_tax_revenue: non_tax_revenue_exp,
      licances_general: licances_general_exp,
      fees_general: fees_general_exp,
      fines_general: fines_general_exp,
      sales_general: sales_general_exp,
      earnings_general: earnings_general_exp,
      rent_on_government_building_general:
        rent_on_government_building_general_exp,
      repayments: repayments_exp,
      investment_income: investment_income_exp,
      interest_earned: interest_earned_exp,
      reimbursement: reimbursement_exp,
      miscellaneous_income: miscellaneous_income_exp,
      aids_grants: aids_grants_exp,
      domestic_aids: domestic_aids_exp,
      foreign_aids: foreign_aids_exp,
      domestic_grants: domestic_grants_exp,
      foreign_grants: foreign_grants_exp,
      capital_development_fund: capital_development_fund_exp,
      sales_of_fixed_assets: sales_of_fixed_assets_exp,
      domestic_loans_from_financial_instituitions:
        domestic_loans_from_financial_instituitions_exp,
      domestic_loans_from_other_government_entities:
        domestic_loans_from_other_government_entities_exp,
      domestic_loans_from_other_entities:
        domestic_loans_from_other_entities_exp,
      international_loans_from_financial_instituitions:
        international_loans_from_financial_instituitions_exp,
      internaitional_loans_from_other_government_entities:
        internaitional_loans_from_other_government_entities_exp,
      internaitional_loans_from_other_entities:
        internaitional_loans_from_other_entities_exp,
      foreign_debt_forgiveness: foreign_debt_forgiveness_exp,
      domestic_debt_foregiveness: domestic_debt_foregiveness_exp,
      gain_on_disposal_asset_ppe: gain_on_disposal_asset_ppe_exp,
      gain_on_disposal_asset_investment_property:
        gain_on_disposal_asset_investment_property_exp,
      minority_interest_share_of_surplus:
        minority_interest_share_of_surplus_exp,
      extraordinary_items: extraordinary_items_exp,
      unspecified_revenue: unspecified_revenue_exp,
    } = item?.revenues_percantage_total_expenditure[0];

    const {
      personal_expenditure,
      personal_cost,
      salaries_wages,
      salaries,
      ovetimes_payments,
      consolidated_revenue_charges,
      allowances,
      social_contribution,
      nhis_contribution,
      contributory_pension,
      group_life_insurance,
      employee_comepensation_fund,
      housing_fund_contribution,
      social_benefit,
      gratuity,
      pension,
      death_benefit,
      payment_benefit_to_past_governors_deputies,
      other_recurrent_expenditure,
    } = item?.expenditures_percentage_total_revenue[0];
    const {
      personal_expenditure: personal_expenditure_exp,
      personal_cost: personal_cost_exp,
      salaries_wages: salaries_wages_exp,
      salaries: salaries_exp,
      ovetimes_payments: ovetimes_payments_exp,
      consolidated_revenue_charges: consolidated_revenue_charges_exp,
      allowances: allowances_exp,
      social_contribution: social_contribution_exp,
      nhis_contribution: nhis_contribution_exp,
      contributory_pension: contributory_pension_exp,
      group_life_insurance: group_life_insurance_exp,
      employee_comepensation_fund: employee_comepensation_fund_exp,
      housing_fund_contribution: housing_fund_contribution_exp,
      social_benefit: social_benefit_exp,
      gratuity: gratuity_exp,
      pension: pension_exp,
      death_benefit: death_benefit_exp,
      payment_benefit_to_past_governors_deputies:
        payment_benefit_to_past_governors_deputies_exp,
      other_recurrent_expenditure: other_recurrent_expenditure_exp,
    } = item?.expenditures_percentage_total_expenditure[0];

    const {
      total_expenditure,
      recurrent_expenditure,
      capital_expenditure,
      recurrent_expenditure_total_expenditure,
      capital_expenditure_total_expenditure,
      recurrent_expenditure_total_revenue,
      capital_expenditure_total_revenue,
      recurrent_revenue_total_expenditure,
    } = item?.expenditure[0];

    const {
      governors_office,
      secretary_to_state_gov,
      admin_sector,
      state_assembly,
      ministry_of_info,
      ministry_of_internal_security_home_affairs,
      office_head_of_service,
      office_auditor_general_state,
      office_auditor_general_lga,
      lga_service_commission,
      state_inec,
      economic_sector,
      ministry_of_agric_natural_resources,
      ministry_finance,
      ministry_commerce_trade_business_industry_investment,
      ministry_labour_productivity,
      ministry_science_tech,
      ministry_transport,
      ministry_energy,
      ministry_works,
      ministry_culture_tourism,
      state_planning_commission,
      fiscal_responsibility_commission,
      ministry_water_resources,
      ministry_housing_urban_development,
      ministry_land_survey,
      law_justuce_sector,
      judiciary,
      ministry_justice,
      regional_sector,
      oil_producing_communities_dev_agency,
      state_capital_dev_agency,
      social_sector,
      ministry_youth_development,
      ministry_women_affairs,
      ministry_education,
      ministry_health,
      ministry_environment,
      ministry_sports,
      minsitry_lga,
    } = item?.expenditureMDA[0];

    const {
      governors_office: governors_office_rev,
      secretary_to_state_gov: secretary_to_state_gov_rev,
      admin_sector: admin_sector_rev,
      state_assembly: state_assembly_rev,
      ministry_of_info: ministry_of_info_rev,
      ministry_of_internal_security_home_affairs:
        ministry_of_internal_security_home_affairs_rev,
      office_head_of_service: office_head_of_service_rev,
      office_auditor_general_state: office_auditor_general_state_rev,
      office_auditor_general_lga: office_auditor_general_lga_rev,
      lga_service_commission: lga_service_commission_rev,
      state_inec: state_inec_rev,
      economic_sector: economic_sector_rev,
      ministry_of_agric_natural_resources:
        ministry_of_agric_natural_resources_rev,
      ministry_finance: ministry_finance_rev,
      ministry_commerce_trade_business_industry_investment:
        ministry_commerce_trade_business_industry_investment_rev,
      ministry_labour_productivity: ministry_labour_productivity_rev,
      ministry_science_tech: ministry_science_tech_rev,
      ministry_transport: ministry_transport_rev,
      ministry_energy: ministry_energy_rev,
      ministry_works: ministry_works_rev,
      ministry_culture_tourism: ministry_culture_tourism_rev,
      state_planning_commission: state_planning_commission_rev,
      fiscal_responsibility_commission: fiscal_responsibility_commission_rev,
      ministry_water_resources: ministry_water_resources_rev,
      ministry_housing_urban_development:
        ministry_housing_urban_development_rev,
      ministry_land_survey: ministry_land_survey_rev,
      law_justuce_sector: law_justuce_sector_rev,
      judiciary: judiciary_rev,
      ministry_justice: ministry_justice_rev,
      regional_sector: regional_sector_rev,
      oil_producing_communities_dev_agency:
        oil_producing_communities_dev_agency_rev,
      state_capital_dev_agency: state_capital_dev_agency_rev,
      social_sector: social_sector_rev,
      ministry_youth_development: ministry_youth_development_rev,
      ministry_women_affairs: ministry_women_affairs_rev,
      ministry_education: ministry_education_rev,
      ministry_health: ministry_health_rev,
      ministry_environment: ministry_environment_rev,
      ministry_sports: ministry_sports_rev,
      minsitry_lga: minsitry_lga_rev,
    } = item?.expenditure_MDA_percentage_total_revenue[0];
    const {
      governors_office: governors_office_exp,
      secretary_to_state_gov: secretary_to_state_gov_exp,
      admin_sector: admin_sector_exp,
      state_assembly: state_assembly_exp,
      ministry_of_info: ministry_of_info_exp,
      ministry_of_internal_security_home_affairs:
        ministry_of_internal_security_home_affairs_exp,
      office_head_of_service: office_head_of_service_exp,
      office_auditor_general_state: office_auditor_general_state_exp,
      office_auditor_general_lga: office_auditor_general_lga_exp,
      lga_service_commission: lga_service_commission_exp,
      state_inec: state_inec_exp,
      economic_sector: economic_sector_exp,
      ministry_of_agric_natural_resources:
        ministry_of_agric_natural_resources_exp,
      ministry_finance: ministry_finance_exp,
      ministry_commerce_trade_business_industry_investment:
        ministry_commerce_trade_business_industry_investment_exp,
      ministry_labour_productivity: ministry_labour_productivity_exp,
      ministry_science_tech: ministry_science_tech_exp,
      ministry_transport: ministry_transport_exp,
      ministry_energy: ministry_energy_exp,
      ministry_works: ministry_works_exp,
      ministry_culture_tourism: ministry_culture_tourism_exp,
      state_planning_commission: state_planning_commission_exp,
      fiscal_responsibility_commission: fiscal_responsibility_commission_exp,
      ministry_water_resources: ministry_water_resources_exp,
      ministry_housing_urban_development:
        ministry_housing_urban_development_exp,
      ministry_land_survey: ministry_land_survey_exp,
      law_justuce_sector: law_justuce_sector_exp,
      judiciary: judiciary_exp,
      ministry_justice: ministry_justice_exp,
      regional_sector: regional_sector_exp,
      oil_producing_communities_dev_agency:
        oil_producing_communities_dev_agency_exp,
      state_capital_dev_agency: state_capital_dev_agency_exp,
      social_sector: social_sector_exp,
      ministry_youth_development: ministry_youth_development_exp,
      ministry_women_affairs: ministry_women_affairs_exp,
      ministry_education: ministry_education_exp,
      ministry_health: ministry_health_exp,
      ministry_environment: ministry_environment_exp,
      ministry_sports: ministry_sports_exp,
      minsitry_lga: minsitry_lga_exp,
    } = item?.expenditure_mda_percentage_total_expenditure[0];

    const {
      admin_sector_total_expenditure,
      economic_sector_total_expenditure,
      law_justice_sector_total_expenditure,
      regional_sector_total_expenditure,
      social_sector_sector_total_expenditure,
    } = item?.expenditure_by_sector[0];
    const {
      admin_sector_total_expenditure: admin_sector_total_expenditure_exp,
      economic_sector_total_expenditure: economic_sector_total_expenditure_exp,
      law_justice_sector_total_expenditure:
        law_justice_sector_total_expenditure_exp,
      regional_sector_total_expenditure: regional_sector_total_expenditure_exp,
      social_sector_sector_total_expenditure:
        social_sector_sector_total_expenditure_exp,
    } = item?.expenditure_by_sector_percentage_total_expenditure[0];
    const {
      admin_sector_total_expenditure: admin_sector_total_expenditure_rev,
      economic_sector_total_expenditure: economic_sector_total_expenditure_rev,
      law_justice_sector_total_expenditure:
        law_justice_sector_total_expenditure_rev,
      regional_sector_total_expenditure: regional_sector_total_expenditure_rev,
      social_sector_sector_total_expenditure:
        social_sector_sector_total_expenditure_rev,
    } = item?.expenditure_by_sector_percentage_total_revenue[0];

    const {
      general_public_service_total_expenditure,
      public_order_safety_total_expenditure,
      economic_affairs_total_expenditure,
      environment_protection_total_expenditure,
      housing_community_affairs_total_expenditure,
      health_total_expenditure,
      recreation_total_expenditure,
      education_total_expenditure,
      social_protection_total_expenditure,
    } = item?.expenditure_by_function[0];
    const {
      general_public_service_total_expenditure:
        general_public_service_total_expenditure_exp,
      public_order_safety_total_expenditure:
        public_order_safety_total_expenditure_exp,
      economic_affairs_total_expenditure:
        economic_affairs_total_expenditure_exp,
      environment_protection_total_expenditure:
        environment_protection_total_expenditure_exp,
      housing_community_affairs_total_expenditure:
        housing_community_affairs_total_expenditure_exp,
      health_total_expenditure: health_total_expenditure_exp,
      recreation_total_expenditure: recreation_total_expenditure_exp,
      education_total_expenditure: education_total_expenditure_exp,
      social_protection_total_expenditure:
        social_protection_total_expenditure_exp,
    } = item?.expenditure_by_function_percentage_total_expenditure[0];
    const {
      general_public_service_total_expenditure:
        general_public_service_total_expenditure_rev,
      public_order_safety_total_expenditure:
        public_order_safety_total_expenditure_rev,
      economic_affairs_total_expenditure:
        economic_affairs_total_expenditure_rev,
      environment_protection_total_expenditure:
        environment_protection_total_expenditure_rev,
      housing_community_affairs_total_expenditure:
        housing_community_affairs_total_expenditure_rev,
      health_total_expenditure: health_total_expenditure_rev,
      recreation_total_expenditure: recreation_total_expenditure_rev,
      education_total_expenditure: education_total_expenditure_rev,
      social_protection_total_expenditure:
        social_protection_total_expenditure_rev,
    } = item?.expenditure_by_function_percentage_total_revenue[0];

    return {
      aids_grant_performance,
      budget_performance,
      capital_expenditure_performance,
      capital_reciepts_cdf_performance,
      federation_revenue_performance,
      igr_performance,
      other_recurrent_expenditure_performance,
      personnel_expenditure_performance,
      recurrent_revenue_performance,
      state,
      total_expenditure_performance,
      total_revenue_performance_excluding_opening_balance,
      year,

      personal_expenditure,
      personal_expenditure_exp,
      // first
      recurrent_revenue,
      federation_revenue,
      satutory_allocation,
      derivation,
      vat,
      other_federation_transfers,
      igr,
      tax_revenue,
      personal_taxes,
      personal_income_tax_paye,
      personal_income_tax_dat,
      other_personal_tax_nec,
      other_taxes,
      sales_taxes,
      lottery_tax,
      property_tax,
      capital_gain_tax,
      withholding_tax,
      other_taxes_nec,
      non_tax_revenue,
      licances_general,
      fees_general,
      fines_general,
      sales_general,
      earnings_general,
      rent_on_government_building_general,
      repayments,
      investment_income,
      interest_earned,
      reimbursement,
      miscellaneous_income,
      aids_grants,
      domestic_aids,
      foreign_aids,
      domestic_grants,
      foreign_grants,
      capital_development_fund,
      sales_of_fixed_assets,
      domestic_loans_from_financial_instituitions,
      domestic_loans_from_other_government_entities,
      domestic_loans_from_other_entities,
      international_loans_from_financial_instituitions,
      internaitional_loans_from_other_government_entities,
      internaitional_loans_from_other_entities,
      foreign_debt_forgiveness,
      domestic_debt_foregiveness,
      gain_on_disposal_asset_ppe,
      gain_on_disposal_asset_investment_property,
      minority_interest_share_of_surplus,
      extraordinary_items,
      unspecified_revenue,
      // second
      recurrent_revenue_exp,
      federation_revenue_exp,
      satutory_allocation_exp,
      derivation_exp,
      vat_exp,
      other_federation_transfers_exp,
      igr_exp,
      tax_revenue_exp,
      personal_taxes_exp,
      personal_income_tax_paye_exp,
      personal_income_tax_dat_exp,
      other_personal_tax_nec_exp,
      other_taxes_exp,
      sales_taxes_exp,
      lottery_tax_exp,
      property_tax_exp,
      capital_gain_tax_exp,
      withholding_tax_exp,
      other_taxes_nec_exp,
      non_tax_revenue_exp,
      licances_general_exp,
      fees_general_exp,
      fines_general_exp,
      sales_general_exp,
      earnings_general_exp,
      rent_on_government_building_general_exp,
      repayments_exp,
      investment_income_exp,
      interest_earned_exp,
      reimbursement_exp,
      miscellaneous_income_exp,
      aids_grants_exp,
      domestic_aids_exp,
      foreign_aids_exp,
      domestic_grants_exp,
      foreign_grants_exp,
      capital_development_fund_exp,
      sales_of_fixed_assets_exp,
      domestic_loans_from_financial_instituitions_exp,
      domestic_loans_from_other_government_entities_exp,
      domestic_loans_from_other_entities_exp,
      international_loans_from_financial_instituitions_exp,
      internaitional_loans_from_other_government_entities_exp,
      internaitional_loans_from_other_entities_exp,
      foreign_debt_forgiveness_exp,
      domestic_debt_foregiveness_exp,
      gain_on_disposal_asset_ppe_exp,
      gain_on_disposal_asset_investment_property_exp,
      minority_interest_share_of_surplus_exp,
      extraordinary_items_exp,
      unspecified_revenue_exp,
      // third
      personal_cost,
      salaries_wages,
      salaries,
      ovetimes_payments,
      consolidated_revenue_charges,
      allowances,
      social_contribution,
      nhis_contribution,
      contributory_pension,
      group_life_insurance,
      employee_comepensation_fund,
      housing_fund_contribution,
      social_benefit,
      gratuity,
      pension,
      death_benefit,
      payment_benefit_to_past_governors_deputies,
      other_recurrent_expenditure,
      // forth
      personal_cost_exp,
      salaries_wages_exp,
      salaries_exp,
      ovetimes_payments_exp,
      consolidated_revenue_charges_exp,
      allowances_exp,
      social_contribution_exp,
      nhis_contribution_exp,
      contributory_pension_exp,
      group_life_insurance_exp,
      employee_comepensation_fund_exp,
      housing_fund_contribution_exp,
      social_benefit_exp,
      gratuity_exp,
      pension_exp,
      death_benefit_exp,
      payment_benefit_to_past_governors_deputies_exp,
      other_recurrent_expenditure_exp,

      // fifth/sixth
      total_expenditure,
      recurrent_expenditure,
      capital_expenditure,
      recurrent_expenditure_total_expenditure,
      capital_expenditure_total_expenditure,
      recurrent_expenditure_total_revenue,
      capital_expenditure_total_revenue,
      recurrent_revenue_total_expenditure,
      //
      governors_office,
      secretary_to_state_gov,
      admin_sector,
      state_assembly,
      ministry_of_info,
      ministry_of_internal_security_home_affairs,
      office_head_of_service,
      office_auditor_general_state,
      office_auditor_general_lga,
      lga_service_commission,
      state_inec,
      economic_sector,
      ministry_of_agric_natural_resources,
      ministry_finance,
      ministry_commerce_trade_business_industry_investment,
      ministry_labour_productivity,
      ministry_science_tech,
      ministry_transport,
      ministry_energy,
      ministry_works,
      ministry_culture_tourism,
      state_planning_commission,
      fiscal_responsibility_commission,
      ministry_water_resources,
      ministry_housing_urban_development,
      ministry_land_survey,
      law_justuce_sector,
      judiciary,
      ministry_justice,
      regional_sector,
      oil_producing_communities_dev_agency,
      state_capital_dev_agency,
      social_sector,
      ministry_youth_development,
      ministry_women_affairs,
      ministry_education,
      ministry_health,
      ministry_environment,
      ministry_sports,
      minsitry_lga,
      // //////
      governors_office_rev,
      secretary_to_state_gov_rev,
      admin_sector_rev,
      state_assembly_rev,
      ministry_of_info_rev,
      ministry_of_internal_security_home_affairs_rev,
      office_head_of_service_rev,
      office_auditor_general_state_rev,
      office_auditor_general_lga_rev,
      lga_service_commission_rev,
      state_inec_rev,
      economic_sector_rev,
      ministry_of_agric_natural_resources_rev,
      ministry_finance_rev,
      ministry_commerce_trade_business_industry_investment_rev,
      ministry_labour_productivity_rev,
      ministry_science_tech_rev,
      ministry_transport_rev,
      ministry_energy_rev,
      ministry_works_rev,
      ministry_culture_tourism_rev,
      state_planning_commission_rev,
      fiscal_responsibility_commission_rev,
      ministry_water_resources_rev,
      ministry_housing_urban_development_rev,
      ministry_land_survey_rev,
      law_justuce_sector_rev,
      judiciary_rev,
      ministry_justice_rev,
      regional_sector_rev,
      oil_producing_communities_dev_agency_rev,
      state_capital_dev_agency_rev,
      social_sector_rev,
      ministry_youth_development_rev,
      ministry_women_affairs_rev,
      ministry_education_rev,
      ministry_health_rev,
      ministry_environment_rev,
      ministry_sports_rev,
      minsitry_lga_rev,
      //
      governors_office_exp,
      secretary_to_state_gov_exp,
      admin_sector_exp,
      state_assembly_exp,
      ministry_of_info_exp,
      ministry_of_internal_security_home_affairs_exp,
      office_head_of_service_exp,
      office_auditor_general_state_exp,
      office_auditor_general_lga_exp,
      lga_service_commission_exp,
      state_inec_exp,
      economic_sector_exp,
      ministry_of_agric_natural_resources_exp,
      ministry_finance_exp,
      ministry_commerce_trade_business_industry_investment_exp,
      ministry_labour_productivity_exp,
      ministry_science_tech_exp,
      ministry_transport_exp,
      ministry_energy_exp,
      ministry_works_exp,
      ministry_culture_tourism_exp,
      state_planning_commission_exp,
      fiscal_responsibility_commission_exp,
      ministry_water_resources_exp,
      ministry_housing_urban_development_exp,
      ministry_land_survey_exp,
      law_justuce_sector_exp,
      judiciary_exp,
      ministry_justice_exp,
      regional_sector_exp,
      oil_producing_communities_dev_agency_exp,
      state_capital_dev_agency_exp,
      social_sector_exp,
      ministry_youth_development_exp,
      ministry_women_affairs_exp,
      ministry_education_exp,
      ministry_health_exp,
      ministry_environment_exp,
      ministry_sports_exp,
      minsitry_lga_exp,
      // ////////////////
      admin_sector_total_expenditure,
      economic_sector_total_expenditure,
      law_justice_sector_total_expenditure,
      regional_sector_total_expenditure,
      social_sector_sector_total_expenditure,
      admin_sector_total_expenditure_exp,
      economic_sector_total_expenditure_exp,
      law_justice_sector_total_expenditure_exp,
      regional_sector_total_expenditure_exp,
      social_sector_sector_total_expenditure_exp,
      admin_sector_total_expenditure_rev,
      economic_sector_total_expenditure_rev,
      law_justice_sector_total_expenditure_rev,
      regional_sector_total_expenditure_rev,
      social_sector_sector_total_expenditure_rev,
      // /////////////////////
      general_public_service_total_expenditure,
      public_order_safety_total_expenditure,
      economic_affairs_total_expenditure,
      environment_protection_total_expenditure,
      housing_community_affairs_total_expenditure,
      health_total_expenditure,
      recreation_total_expenditure,
      education_total_expenditure,
      social_protection_total_expenditure,

      general_public_service_total_expenditure_exp,
      public_order_safety_total_expenditure_exp,
      economic_affairs_total_expenditure_exp,
      environment_protection_total_expenditure_exp,
      housing_community_affairs_total_expenditure_exp,
      health_total_expenditure_exp,
      recreation_total_expenditure_exp,
      education_total_expenditure_exp,
      social_protection_total_expenditure_exp,

      general_public_service_total_expenditure_rev,
      public_order_safety_total_expenditure_rev,
      economic_affairs_total_expenditure_rev,
      environment_protection_total_expenditure_rev,
      housing_community_affairs_total_expenditure_rev,
      health_total_expenditure_rev,
      recreation_total_expenditure_rev,
      education_total_expenditure_rev,
      social_protection_total_expenditure_rev,
    };
  });

  const returnRankPiSingleData = rankDataFormatPI
    ?.map((item) => ({
      state: item?.state.split("_").join(" ").toLowerCase(),
      type: "pi",
      amount: item[`${selectedCategory}`],
      category: selectedCategory,
      year: item?.year,
    }))
    ?.sort((a, b) =>
      sortBy === "ascending" ? b?.amount - a?.amount : a.amount - b.amount
    );

  const fetchBudgets = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(budgetUrl);

      if (data) {
        setIsLoading(false);
        const result = data?.data?.result?.filter((item) => item?.length > 0);
        if (result.length <= 0) {
          setSnakOpen(true);
          setSeverity("error");
          setMessage("No results found: search for something else");
        }
        let newData = result?.map((item) => {
          const asArray = Object.entries(item[0]);
          const filtered = asArray.filter(([key, value]) =>
            uniqueCategories?.includes(key)
          );
          const justStrings = Object.fromEntries(filtered);
          return justStrings;
        });
        if (result.length > 1) {
          setBudgets(newData?.map((item) => [item]));
          setData(result);
        } else if (result?.length === 1) {
          setBudgets([newData]);
          // setGeneralData(result);
        }
      }
    } catch (error) {
      if (error?.response) {
        setIsLoading(false);
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      }
    }
  };

  const fetchPiBudgets = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(piBudgetUrl);
      if (data) {
        setIsLoading(false);
        const result = data?.data?.result?.filter(
          (item) => item?.indicators?.length > 0
        );
        if (result?.length <= 0) {
          setSnakOpen(true);
          setSeverity("error");
          setMessage("No results found: search for something else");
        } else {
          let newData = result?.map((item) => {
            const asArray = Object.entries(item);
            const filtered = asArray.filter(([key, value]) =>
              [...uniqueCategories, "indicators"]?.includes(key)
            );

            const justStrings = Object.fromEntries(filtered);
            return justStrings;
          });
          setBudgets(newData);
          setGeneralData(data?.data?.result);
        }
      }
    } catch (error) {
      if (error?.response) {
        setIsLoading(false);
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      }
    }
  };

  const rankBudgets = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(budgetUrl);
      if (data) {
        setIsLoading(false);
        const result = data?.data?.result?.filter((item) => item?.length > 0);

        if (result.length <= 0) {
          setSnakOpen(true);
          setSeverity("error");
          setMessage("No results found: search for something else");
        }
        if (result.length > 1) {
          setRankData(result);
        } else if (result?.length === 1) {
          setRankData(result);
        }
      }
    } catch (error) {
      if (error?.response) {
        setIsLoading(false);
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      }
    }
  };

  const fetchBudgetsIndicators = async () => {
    try {
      const { data } = await axios.get(indicatorsUrl);
      if (data) {
        const result = data?.data?.result?.filter((item) => item?.length > 0);

        if (result.length > 1) {
          setPI(result);
        } else {
          setPI(result);
        }
      }
    } catch (error) {
      if (error?.response) {
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      }
    }
  };

  const downloadCsv = async () => {
    try {
      const { data } = await axios.get(csv_url);
      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Nigeria Governors' Forum.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnakOpen(true);
      setSeverity("error");
      setMessage(
        toString.call(error?.response?.data?.message) === "[object Array]"
          ? error?.response?.data?.message.slice(0, 3)
          : error?.response?.data?.message?.message
          ? error?.response?.data?.message?.message
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data
          ? error?.response?.data
          : "An error occured, please try again later"
      );
    }
  };

  const downloadExcel = () => {
    axios({
      url: pdf_url,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Nigeria Governors' Forum.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      });
  };
  const downloadPiCsv = async () => {
    const route = `${piBudgetUrl}&download=csv`;
    try {
      const { data } = await axios.get(route);
      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Nigeria Governors' Forum.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error) {
      setSnakOpen(true);
      setSeverity("error");
      setMessage(
        toString.call(error?.response?.data?.message) === "[object Array]"
          ? error?.response?.data?.message.slice(0, 3)
          : error?.response?.data?.message?.message
          ? error?.response?.data?.message?.message
          : error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data
          ? error?.response?.data
          : "An error occured, please try again later"
      );
    }
  };

  async function downloadFile(json) {
    const { data } = await axios.get(
      `https://www.pfmapi.ngf.org.ng/api/v1/budget/download`,
      { data: json }
    );
  }

  const downloadPiExcel = () => {
    const route = `${piBudgetUrl}&download=xlsx`;
    axios({
      url: route,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Nigeria Governors' Forum.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setSnakOpen(true);
        setSeverity("error");
        setMessage(
          toString.call(error?.response?.data?.message) === "[object Array]"
            ? error?.response?.data?.message.slice(0, 3)
            : error?.response?.data?.message?.message
            ? error?.response?.data?.message?.message
            : error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data
            ? error?.response?.data
            : "An error occured, please try again later"
        );
      });
  };

  useEffect(() => {}, [rankData]);

  return {
    downloadFile,
    downloadCsv,
    downloadExcel,
    downloadPiCsv,
    downloadPiExcel,
    fetchData,
    fetchedData,
    setRankData,
    budgets,
    setBudgets,
    fetchPiBudgets,
    fetchBudgetsIndicators,
    PI,
    selectedCategories,
    setSelectedCategories,
    selectBudgetType,
    setSelectBudgetType,
    selectedYear,
    setSelectedYear,
    selectedState,
    setSelectedStates,
    isLoading,
    sortBy,
    setSortBy,
    fetchBudgets,
    generalData,
    message,
    severity,
    snakOpen,
    setSnakOpen,
    popItems,
    formatData,
    rankBudgets,
    returnRankPiSingleData,
    setGeneralData,
  };
};

export default useFetch;
